class BricksSold {
    items;
    pagination;
    count;
    constructor(data) {
        this.items = data.soldBricks;
        this.pagination = data.pagination;
        this.count = data.count;
    }
    getNumberOfItems() {
        return this.count;
    }
}
export default BricksSold;
