import Pagination from '@api/entities/Pagination';
import { SecondFactorToken } from '@api/entities';
import HundredBricksAPI from '../HundredBricksAPI';
import { APIError } from '../../api';
import Payment from './paymentPlan/Payment';
import { InvalidInvestorId, PaymentPlan, PaymentPlanError, PaymentPlansInvalidQueryParam, PaymentPlanIsNotRegistered, InvalidPaymentPlanId } from './paymentPlan';
import { CannotResendToken, ChannelIsNotSupported, InvestorHasAuthenticationFactorLocked, PaymentPlanTwoFactorAuthenticationError, W8ContractNotSigned } from './paymentPlanTwoFactorAuthentication';
import { ApprovePaymentPlanError, CodeValidationLimitReached, InsufficientBricks, InsufficientFunds, InvalidPaymentPlanStatus, InvalidTwoFactorCode } from './approvePaymentPlan';
import { RejectPaymentPlanError, InvalidPaymentPlanId as InvalidRejectPaymentPlanId, PaymentPlanIsNotRegistered as RejectPaymentPlanIsNotRegistered } from './rejectPaymentPlan';
import { InsufficientFundsToPay, InvalidPaymentPlanId as InvalidPayPaymentPlanId, PayPaymentPlanError, PaymentPlanIsNotRegistered as PayPaymentPlanIsNotRegistered } from './payPaymentPlan';
const BASE_URL = 'v1/paymentPlans';
class PaymentPlansAPI extends HundredBricksAPI {
    async approvePaymentPlan(paymentPlanId, code) {
        try {
            const { data } = await this.client.post(`${BASE_URL}/${paymentPlanId}/approve`, { code });
            return this.buildPaymentPlan(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_PAYMENT_PLAN_ID':
                        throw new InvalidPaymentPlanId(paymentPlanId, { cause: error });
                    case 'INVALID_PAYMENT_PLAN_STATUS':
                        throw new InvalidPaymentPlanStatus({ cause: error });
                    case 'PAYMENT_PLAN_NOT_FOUND':
                        throw new PaymentPlanIsNotRegistered(paymentPlanId, { cause: error });
                    case 'NOT_ENOUGH_BRICKS':
                        throw new InsufficientBricks({ cause: error });
                    case 'NOT_ENOUGH_FUNDS':
                        throw new InsufficientFunds({ cause: error });
                    case 'INVALID_CODE':
                        throw new InvalidTwoFactorCode(code, { cause: error });
                    case 'CODE_VALIDATION_LIMIT_REACHED':
                        throw new CodeValidationLimitReached(code, { cause: error });
                    default:
                        throw new ApprovePaymentPlanError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new ApprovePaymentPlanError('Unexpected error when approving the investor payment plan', { cause: error });
            }
            else {
                throw new ApprovePaymentPlanError(`Unexpected error when approving the investor payment plan: '${error}'`);
            }
        }
    }
    async payPaymentPlan(paymentPlanId) {
        try {
            const { data } = await this.client.post(`${BASE_URL}/${paymentPlanId}/pay`);
            return this.buildPaymentPlan(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_PAYMENT_PLAN_ID':
                        throw new InvalidPayPaymentPlanId(paymentPlanId, { cause: error });
                    case 'PAYMENT_PLAN_NOT_FOUND':
                        throw new PayPaymentPlanIsNotRegistered(paymentPlanId, { cause: error });
                    case 'NOT_ENOUGH_FUNDS':
                        throw new InsufficientFundsToPay({ cause: error });
                    default:
                        throw new PayPaymentPlanError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new PayPaymentPlanError('Unexpected error when paying the investor payment plan', { cause: error });
            }
            else {
                throw new PayPaymentPlanError(`Unexpected error when paying the investor payment plan: '${error}'`);
            }
        }
    }
    async rejectPaymentPlan(paymentPlanId) {
        try {
            const { data } = await this.client.post(`${BASE_URL}/${paymentPlanId}/reject`);
            return this.buildPaymentPlan(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_PAYMENT_PLAN_ID':
                        throw new InvalidRejectPaymentPlanId(paymentPlanId, { cause: error });
                    case 'PAYMENT_PLAN_NOT_FOUND':
                        throw new RejectPaymentPlanIsNotRegistered(paymentPlanId, { cause: error });
                    default:
                        throw new RejectPaymentPlanError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new RejectPaymentPlanError('Unexpected error when rejecting the investor payment plan', { cause: error });
            }
            else {
                throw new RejectPaymentPlanError(`Unexpected error when rejecting the investor payment plan: '${error}'`);
            }
        }
    }
    async requestSecondAuthenticationFactor(channel, paymentPlanId) {
        try {
            const { data } = await this.client.post(`${BASE_URL}/${paymentPlanId}/sendApproveCode`, { channel });
            return new SecondFactorToken(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'CHANNEL_NOT_SUPPORTED':
                        throw new ChannelIsNotSupported(channel, { cause: error });
                    case 'CANNOT_RESEND_TOKEN':
                        throw new CannotResendToken({ cause: error });
                    case 'INVESTOR_HAS_AUTHENTICATION_FACTOR_LOCKED':
                        throw new InvestorHasAuthenticationFactorLocked({ cause: error });
                    case 'W8_CONTRACT_NOT_SIGNED':
                        throw new W8ContractNotSigned({ cause: error });
                    case 'NOT_ENOUGH_FUNDS':
                        throw new InsufficientFundsToPay({ cause: error });
                    default:
                        throw new PaymentPlanTwoFactorAuthenticationError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new PaymentPlanTwoFactorAuthenticationError('Unexpected error when requesting second authentication factor', { cause: error });
            }
            else {
                throw new PaymentPlanTwoFactorAuthenticationError(`Unexpected error when requesting second authentication factor: '${error}'`);
            }
        }
    }
    async getPaymentPlans(params) {
        try {
            const { data } = await this.client.get(BASE_URL, { params });
            const pagination = new Pagination(data.pagination);
            const buildPaymentPlans = data.items.map(paymentPlan => (this.buildPaymentPlan(paymentPlan)));
            return {
                items: buildPaymentPlans,
                pagination,
                count: data.count
            };
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new PaymentPlansInvalidQueryParam('page', params.page, { cause: error });
                    case 'PER_PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new PaymentPlansInvalidQueryParam('perPage', params.perPage, { cause: error });
                    case 'INVALID_PAGE':
                        throw new PaymentPlansInvalidQueryParam('page', params.page, { cause: error });
                    case 'INVALID_PER_PAGE':
                        throw new PaymentPlansInvalidQueryParam('perPage', params.perPage, { cause: error });
                    case 'INVALID_INVESTOR_ID':
                        throw new InvalidInvestorId({ cause: error });
                    default:
                        throw new PaymentPlanError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new PaymentPlanError('Unexpected error when obtaining investor payment plans', { cause: error });
            }
            else {
                throw new PaymentPlanError(`Unexpected error when obtaining investor payment plans: '${error}'`);
            }
        }
    }
    async getPaymentPlan(paymentPlanId) {
        try {
            const { data } = await this.client.get(`${BASE_URL}/${paymentPlanId}`);
            const buildPayments = data?.payments.map(payment => new Payment(payment));
            return new PaymentPlan({ ...data, payments: buildPayments });
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_PAYMENT_PLAN_ID':
                        throw new InvalidPaymentPlanId(paymentPlanId, { cause: error });
                    case 'PAYMENT_PLAN_NOT_FOUND':
                        throw new PaymentPlanIsNotRegistered(paymentPlanId, { cause: error });
                    default:
                        throw new PaymentPlanError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new PaymentPlanError('Unexpected error when obtaining investor payment plan', { cause: error });
            }
            else {
                throw new PaymentPlanError(`Unexpected error when obtaining investor payment plan: '${error}'`);
            }
        }
    }
    buildPaymentPlan(paymentPlan) {
        const payments = paymentPlan?.payments.map(payment => new Payment(payment));
        return new PaymentPlan({ ...paymentPlan, payments });
    }
}
export default PaymentPlansAPI;
