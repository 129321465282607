import styled from 'styled-components';

const MainLayoutWrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.background.hbBackgroundA};
  display: flex;
  grid-area: content;
  height: 100%;
  justify-content: center;
`;

const ChildrenWrapper = styled.section`
  margin: auto;
  min-height: min-content;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(2)}`};
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    max-width: ${({ theme }) => `${theme.spacing(42)}`};
    padding-left: 0;
    padding-right: 0;
  }
`;

export { MainLayoutWrapper, ChildrenWrapper };
