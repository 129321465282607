import styled from 'styled-components';

import Card from '../../../Card';
import IconButton from '../../../IconButton';
import { flexAsColumn } from '../../../Styled/mixins';

const NotificationPanelCard = styled(Card)`
  position: fixed;
  height: calc(100vh - ${({ theme }) => theme.spacing(9)});
  padding: ${({ theme }) => theme.spacing(2)};
  overflow-y: auto;
  top: ${({ theme }) => theme.spacing(9)};
  left: 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    left: unset;
    right: ${({ theme }) => theme.spacing(6)};
    width: ${({ theme }) => theme.spacing(52)};
    height: unset;
    min-height: ${({ theme }) => theme.spacing(20)};
    max-height: calc(100vh - ${({ theme }) => theme.spacing(10)});
    box-shadow: 0 0 ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.palette.grayC};
  }

  h3 {
    margin-bottom: 0;
  }

  > div {
    > div:last-of-type {
      > div:last-of-type {
        border-bottom: 0;
      }
    }
  }
`;

const InvisibleWall = styled.button`
  all: unset;
  position: fixed;
  background-color: transparent;
  width: 100svw;
  height: 100svh;
  top: 0;
  left: 0;
`;

const NotificationContainer = styled.div`
  ${flexAsColumn(2)};

  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(6)}`};
  height: 100%;
`;

const CloseButton = styled(IconButton)`
  position: fixed;
  right: ${({ theme }) => theme.spacing(2)};
  top: ${({ theme }) => theme.spacing(10)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    display: none;
  }
`;

const Notification = styled.div`
  ${flexAsColumn(2)};

  padding:  ${({ theme }) => theme.spacing(2)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grayC};

  > p:last-of-type {
    text-transform: capitalize;
  }
`;

const NotificationSkeleton = styled.div`
  ${flexAsColumn(2)};

  // something is messing with the top padding of this element.
  padding:  ${({ theme }) => theme.spacing(3)} 0 !important;

  > span {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.grayC};
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.palette.grayC} 33%,
      ${({ theme }) => theme.palette.grayB} 50%,
      ${({ theme }) => theme.palette.grayC} 66%
    );
    background-size: 400%;
    animation: shimmer 2s ease infinite;

    @keyframes shimmer {
      0% {
        background-position: 100% 100%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

  > span:nth-of-type(1) {
    width: 50%;
    height: ${({ theme }) => theme.spacing(3)};
  }

  > span:nth-of-type(2) {
    width: 100%;
    height: ${({ theme }) => theme.spacing(8)};
  }

  > span:nth-of-type(3) {
    width: 40%;
    height: ${({ theme }) => theme.spacing(3)};
  }
`;

const NumberOfNewNotifications = styled.span`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => `${theme.toRem(2)} ${theme.toRem(6)}`};
  color: ${({ theme }) => theme.palette.white};
  top: 0;
  left: ${({ theme }) => theme.toRem(22)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  font-size: ${({ theme }) => theme.toRem(14)};
`;

const TimedWindow = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};

  > div:first-of-type {
    padding-top: ${({ theme }) => theme.spacing(1)};
  }
`;

const TimeChip = styled.div`
  width: fit-content;
  padding: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.info.lightest};
  border-radius: ${({ theme }) => theme.borderRadius()};
`;

export {
  NotificationPanelCard,
  InvisibleWall,
  CloseButton,
  NotificationContainer,
  Notification,
  NumberOfNewNotifications,
  NotificationSkeleton,
  TimedWindow,
  TimeChip
};
