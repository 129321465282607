import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useProfile from '../lib/profile/useProfile';
import useInsightsService from '../lib/insightsService/useInsightsService';
import RenderIf from '../components/RenderIf';

const withUpgradeAccountButton = UpgradeComponent => {
  const UpgradeAccountComponent = props => {
    const { profile } = useProfile();
    const location = useLocation();
    const navigate = useNavigate();
    const [tittle, setTittle] = useState('');
    const [navigateRoute, setNavigateRoute] = useState('');
    const { upgradeAccountEvent, reportInsight } = useInsightsService();
    const { accountLevel, status } = profile;

    const sendUpgradeAccountButtonEvent = () => {
      reportInsight(
        upgradeAccountEvent.clickNavbarButton,
        tittle,
        location.pathname
      );
    };

    const handleClick = () => {
      sendUpgradeAccountButtonEvent();
      navigate(navigateRoute);
    };

    useEffect(() => {
      if (status.isRestricted()) {
        setTittle('Mejorar mi cuenta');
        setNavigateRoute('/upgrade-account/benefits');

        return;
      }

      if (status.isUpgradingAccount()) {
        setTittle('Continuar mejorando mi cuenta');
        setNavigateRoute('/upgrade-account');

        return;
      }

      if (status.isCorrectingData()) {
        setTittle('Ir a verificar mis datos');
        setNavigateRoute('/confirm-account-data');
      }
    }, [status]);

    return (
      <RenderIf condition={!!accountLevel.isBasic() && !!navigateRoute}>
        <UpgradeComponent
          {...props}
          name="upgradeAccountButton"
          onClick={handleClick}
        >
          {tittle}
        </UpgradeComponent>
      </RenderIf>
    );
  };

  return UpgradeAccountComponent;
};

export default withUpgradeAccountButton;
