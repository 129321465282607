import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const BasicCard = ({ title, children }) => (
  <div styleName="basic-card">
    {title && (<span styleName="title">{title}</span>)}
    <div styleName="content">
      {children}
    </div>
  </div>
);

BasicCard.defaultProps = {
  title: '',
};

BasicCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BasicCard;
