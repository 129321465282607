import styled, { css } from 'styled-components';

const mediaQueryForButtonsInResolutionGreaterThanMedium = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    > button {
      height: ${({ theme }) => theme.spacing(40)};
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    > button {
      height: ${({ theme }) => theme.spacing(50)};
    }
  }
`;

const mediaQueryForGridInResolutionGreaterThanMedium = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-auto-rows: ${({ theme }) => theme.spacing(40)};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-auto-rows: ${({ theme }) => theme.spacing(50)};
  }
`;

const oneImageLayout = css`
  > button {
    width: 100%;
    height: ${({ theme }) => theme.spacing(26)};
  }

  ${mediaQueryForButtonsInResolutionGreaterThanMedium};
`;

const twoImagesLayout = css`
  > button {
    width: 50%;
    height: ${({ theme }) => theme.spacing(26)};
  }

  ${mediaQueryForButtonsInResolutionGreaterThanMedium};
`;

const threeImagesLayout = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: ${({ theme }) => theme.spacing(20)};

  > button:nth-child(0) {
    grid-column: 1;
    grid-row: 1;
  }

  > button:nth-child(1) {
    grid-column: 1;
    grid-row: 2 / 3;
  }

  > button:nth-child(2) {
    grid-column: 2;
    grid-row: 1 / 3;
  }

  ${mediaQueryForGridInResolutionGreaterThanMedium};
`;

const fourImagesLayout = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: ${({ theme }) => theme.spacing(20)};

  > button:nth-child(0) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  > button:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 2 / 4;
  }

  > button:nth-child(2) {
    grid-column: 2;
    grid-row: 1 / 3;
  }

  > button:nth-child(3) {
    grid-column: 2;
    grid-row: 3 / 4;
  }

  ${mediaQueryForGridInResolutionGreaterThanMedium};
`;

const GalleryWrapper = styled.div`
  width: 100%;
  height: 100%;

  &[data-images="1"] {
    ${oneImageLayout};
  }
  &[data-images="2"] {
    ${twoImagesLayout};
  }
  &[data-images="3"] {
    ${threeImagesLayout};
  }
  &[data-images="4"] {
    ${fourImagesLayout};
  }
`;

const ImageContainer = styled.button`
  cursor: pointer;
  border-style: none;
  background: none;
  padding: ${({ theme }) => theme.spacing(0.5)};

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.spacing(0.5)};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;

export { GalleryWrapper, ImageContainer };
