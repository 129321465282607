class AutomaticInvoiceValidationResult {
    investorId;
    taxId;
    fiscalZipCode;
    fiscalRegime;
    result;
    constructor(automaticInvoiceValidation) {
        this.investorId = automaticInvoiceValidation.investorId;
        this.taxId = automaticInvoiceValidation.taxId;
        this.fiscalZipCode = automaticInvoiceValidation.fiscalZipCode;
        this.fiscalRegime = automaticInvoiceValidation.fiscalRegime;
        this.result = automaticInvoiceValidation.result;
    }
    getMatchedData() {
        return this.result.matchedData;
    }
    getMismatchedData() {
        return this.result.mismatchedData;
    }
}
export default AutomaticInvoiceValidationResult;
