import styled from 'styled-components';

import { flexAsColumn, flexAsRow, grid } from '@components/Styled/mixins';

const AmenitiesWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacing(3)} 0`};
`;

const Sections = styled.div`
  ${grid()};

  gap: ${({ theme }) => `${theme.spacing(4)} 0`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(10)}`};
  }
`;

const SectionItem = styled.div`
  ${flexAsColumn(1)};

  > p:nth-child(0) {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

const TitleSection = styled.div`
  ${flexAsRow(1)};

  align-items: center;

  > svg {
    width: ${({ theme }) => theme.spacing(3)};
    height: ${({ theme }) => theme.spacing(3)};
  }
`;

const AmenityItem = styled.div`
  ${flexAsRow(1)};

  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.spacing(2)} 0 ${theme.spacing(4)}`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    padding: ${({ theme }) => `0 ${theme.spacing(12)} 0 ${theme.spacing(4)}`};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: ${({ theme }) => `0 ${theme.spacing(2)} 0 ${theme.spacing(4)}`};
  }
`;

export {
  AmenitiesWrapper,
  Sections,
  SectionItem,
  TitleSection,
  AmenityItem
};
