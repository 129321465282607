import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import {
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';

import withRouter from '../../hoc/withRouter';
import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import UnusualAccessLock from '../UnusualAccessLock';
import UnusualAccessUnblock from '../UnusualAccessUnblock';

import AuthenticatedOutlet from './AuthenticatedOutlet';

const CreateAccount = lazy(() => import('../Signup'));
const VerifyNewEmailRouter = lazy(() => import('../Profile/UpdateEmail/VerifyNewEmailRouter'));

const PublicRoutes = props => {
  const location = useLocation();
  const { isAuthenticated, hadActiveSession } = props;

  const setRedirectionOnLogin = () => {
    if (hadActiveSession) {
      return { from: null };
    }

    return { from: location };
  };

  return (
    <Routes>
      <Route element={<AuthenticatedOutlet />}>
        <Route path="/signup" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword {...props} />} />
      <Route path="/unusual-access-unblock" element={<UnusualAccessUnblock {...props} />} />
      <Route path="/unusual-access-lock" element={<UnusualAccessLock {...props} />} />
      <Route path="/update-email-process/*" element={<VerifyNewEmailRouter />} />

      {!isAuthenticated && (<Route
        path="*"
        element={<Navigate to="/login" state={setRedirectionOnLogin()} />}
      />)}
    </Routes>
  );
};

PublicRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  hadActiveSession: PropTypes.bool.isRequired
};

export default withRouter(PublicRoutes);
