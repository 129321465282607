/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const FilePicker = ({
  accept, id, label, className, onChange,
}) => (
  <div styleName="file-picker" className={className}>
    <label htmlFor={id}>{label}</label>
    <input
      type="file"
      name="myfile"
      accept={accept}
      id={id}
      onChange={onChange}
    />
  </div>
);

FilePicker.defaultProps = {
  label: 'Subir archivo',
  className: '',
};

FilePicker.propTypes = {
  label: PropTypes.string,
  accept: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default FilePicker;
