import HundredBricksAPI from '@lib/hundredBricksAPI/HundredBricksAPI';
import AccountValueError from './AccountValueError';
import AccountValue from './AccountValue';
class AccountValueAPI extends HundredBricksAPI {
    async getSummary() {
        try {
            const { data } = await this.client.get('/v2/accountValue');
            return new AccountValue(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new AccountValueError('Cannot obtain account value', { cause: error });
            }
            else {
                throw new AccountValueError(`Unexpected error occured while obtaining account value error: '${error}'`);
            }
        }
    }
}
export default AccountValueAPI;
