import { useState, useEffect } from 'react';

import placeholderPhoto from '@assets/images/property-photo-placeholder.png';

const usePropertyCardPhoto = photos => {
  const hasPhotos = photos && photos.length > 0;
  const firstPhoto = hasPhotos && photos.find(photo => photo.index === 0).url;
  const [propertyPhoto, setPropertyPhoto] = useState(placeholderPhoto);

  const handleError = () => setPropertyPhoto(placeholderPhoto);

  useEffect(() => {
    if (firstPhoto) {
      setPropertyPhoto(firstPhoto);
    }
  }, [firstPhoto]);

  return [propertyPhoto, handleError];
};

export default usePropertyCardPhoto;
