import styled from 'styled-components';

const InfoWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-items: center;

  > * {
    margin: 0;
    word-wrap: break-word;
    max-width: ${({ theme }) => theme.spacing(40)};
    text-align: center;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export { ActionsWrapper, InfoWrapper };
