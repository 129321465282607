const enrolledNavItems = [
  {
    id: 'first-steps',
    icon: 'icFirstSteps',
    activeIcon: 'icFirstSteps',
    route: '/onboarding',
    childrenRoutes: ['/onboarding/first-steps'],
    description: 'Primeros pasos'
  },
  {
    id: 'my-account',
    icon: 'icMyAccount',
    activeIcon: 'icMyAccountActive',
    route: '/my-account',
    childrenRoutes: ['/my-account/no-investments', '/my-account/dashboard'],
    description: 'Mi patrimonio'
  },
  {
    id: 'marketplace',
    icon: 'icBuy',
    activeIcon: 'icBuyActive',
    route: '/marketplace/presale',
    description: 'Comprar'
  },
  {
    id: 'sell',
    icon: 'icSell',
    activeIcon: 'icSellActive',
    route: '/my-account/my-bricks',
    description: 'Vender'
  },
  {
    id: 'add-funds',
    icon: 'icAddFunds',
    activeIcon: 'icFundsActive',
    route: '/movements/add-funds',
    description: 'Agregar fondos'
  },
  {
    id: 'withdraw',
    icon: 'icWithdraw',
    activeIcon: 'icWithdrawActive',
    route: '/movements/withdraw',
    description: 'Retirar fondos'
  },
  {
    id: 'movements',
    icon: 'icMyMovements',
    activeIcon: 'icMyMovementsActive',
    route: '/movements',
    description: 'Mis movimientos'
  },
  {
    id: 'profile',
    icon: 'icProfile',
    activeIcon: 'icMyProfileActive',
    route: '/perfil',
    description: 'Mi perfil'
  },
  {
    id: 'cupones',
    icon: 'icCouponWithDolarIcon',
    activeIcon: 'icCouponWithDolarIconActive',
    route: '/perfil/cupones',
    description: 'Cupones'
  },
  {
    id: 'paymentPlans',
    icon: 'icMyPaymentPlans',
    activeIcon: 'icMyPaymentPlansActive',
    route: '/my-payment-plans',
    description: 'Mis planes de pago'
  },
  {
    id: 'help',
    icon: 'icHelp',
    iconDimensions: {
      width: 32,
      height: 25
    },
    route: 'https://100ladrillos.zendesk.com/hc/es',
    external: true,
    description: 'Ayuda'
  }
];

const profileIncompletedNavItems = [
  {
    id: 'first-steps',
    icon: 'icFirstSteps',
    activeIcon: 'icFirstSteps',
    route: '/onboarding',
    childrenRoutes: ['/onboarding/first-steps'],
    description: 'Primeros pasos'
  },
  {
    id: 'enrollment',
    icon: 'icEnrollment',
    activeIcon: 'icEnrollment',
    route: '/enrollment',
    description: 'Completar perfil'
  },
  {
    id: 'properties',
    icon: 'icProperties',
    activeIcon: 'icPropertiesActive',
    route: '/marketplace/presale',
    description: 'Ver propiedades'
  },
  {
    id: 'my-account',
    icon: 'icMyAccount',
    activeIcon: 'icMyAccountActive',
    route: '/my-account',
    childrenRoutes: ['/my-account/no-investments', '/my-account/dashboard'],
    description: 'Mi patrimonio'
  },
  {
    id: 'help',
    icon: 'icHelp',
    iconDimensions: {
      width: 32,
      height: 25
    },
    route: 'https://100ladrillos.zendesk.com/hc/es',
    external: true,
    description: 'Ayuda'
  }
];

const profileCompletedNavItems = [
  {
    id: 'first-steps',
    icon: 'icFirstSteps',
    activeIcon: 'icFirstSteps',
    route: '/onboarding',
    childrenRoutes: ['/onboarding/first-steps'],
    description: 'Primeros pasos'
  },
  {
    id: 'properties',
    icon: 'icProperties',
    activeIcon: 'icPropertiesActive',
    route: '/marketplace/presale',
    description: 'Ver propiedades'
  },
  {
    id: 'my-account',
    icon: 'icMyAccount',
    activeIcon: 'icMyAccountActive',
    route: '/my-account',
    childrenRoutes: ['/my-account/no-investments', '/my-account/dashboard'],
    description: 'Mi patrimonio'
  },
  {
    id: 'profile',
    icon: 'icProfile',
    activeIcon: 'icMyProfileActive',
    route: '/perfil',
    description: 'Mi perfil'
  },
  {
    id: 'help',
    icon: 'icHelp',
    iconDimensions: {
      width: 32,
      height: 25
    },
    route: 'https://100ladrillos.zendesk.com/hc/es',
    external: true,
    description: 'Ayuda'
  }
];

const readyToSignRiskFormNavItems = [
  {
    id: 'first-steps',
    icon: 'icFirstSteps',
    activeIcon: 'icFirstSteps',
    route: '/onboarding',
    childrenRoutes: ['/onboarding/first-steps'],
    description: 'Primeros pasos'
  },
  {
    id: 'my-account',
    icon: 'icMyAccount',
    activeIcon: 'icMyAccountActive',
    route: '/my-account',
    childrenRoutes: ['/my-account/no-investments', '/my-account/dashboard'],
    description: 'Mi patrimonio'
  },
  {
    id: 'marketplace',
    icon: 'icBuy',
    activeIcon: 'icBuyActive',
    route: '/marketplace/presale',
    description: 'Comprar'
  },
  {
    id: 'add-funds',
    icon: 'icAddFunds',
    activeIcon: 'icFundsActive',
    route: '/movements/add-funds',
    description: 'Agregar fondos'
  },
  {
    id: 'profile',
    icon: 'icProfile',
    activeIcon: 'icMyProfileActive',
    route: '/perfil',
    description: 'Mi perfil'
  },
  {
    id: 'help',
    icon: 'icHelp',
    iconDimensions: {
      width: 32,
      height: 25
    },
    route: 'https://100ladrillos.zendesk.com/hc/es',
    external: true,
    description: 'Ayuda'
  }
];

export {
  profileIncompletedNavItems,
  profileCompletedNavItems,
  enrolledNavItems,
  readyToSignRiskFormNavItems
};
