const production = {
  baseUrl: 'https://api.100ladrillos.com/marketplace',
  port: 0,
  mapsApiKey: 'AIzaSyBAYHbxvJi_rIeEP1kQ_hvKjFiehiCAuwo'
};

const staging = {
  baseUrl: 'https://api-staging.100ladrillos.com/marketplace',
  port: 0,
  mapsApiKey: 'AIzaSyBAYHbxvJi_rIeEP1kQ_hvKjFiehiCAuwo'
};

const development = {
  baseUrl: 'https://api-dev.100ladrillos.com/marketplace',
  port: 0,
  mapsApiKey: 'AIzaSyBAYHbxvJi_rIeEP1kQ_hvKjFiehiCAuwo'
};

const local = {
  baseUrl: 'http://localhost',
  port: 9000,
  mapsApiKey: ''
};

const test = {
  baseUrl: 'http://localhost',
  port: 9000,
  mapsApiKey: ''
};

const config = {
  development,
  production,
  staging,
  test,
  local
};

export default config;
