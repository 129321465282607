import styled from 'styled-components';

export const RedirectToLoginButtonwrapper = styled.div`
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.spacing(3)};
  text-align: center;
  width: 50%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }

  p {
    margin: 0%;
  }
`;
