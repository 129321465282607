import { Pagination } from '@api/entities';
import { AmenitiesError, Amenity } from '../amenities';
import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import { BrickSummaryError, BricksSummary } from './bricksSummary';
import { IncomePeriod, IncomeHistoryError, IncomePeriodsHistory } from './incomeHistory';
class OnSalePropertyAPI extends HundredBricksAPI {
    async getIncomeHistory(propertyId) {
        try {
            const { data } = await this.client.get(`v2/properties/${propertyId}/dispersions?sortedBy=-createdAt&status=success`);
            return new IncomePeriodsHistory({
                incomeDispersions: data.items.map(incomePeriod => new IncomePeriod(incomePeriod)),
                totals: data.totals,
                pagination: new Pagination(data.pagination),
                count: data.count
            });
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new IncomeHistoryError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new IncomeHistoryError('Unexpected income history error', { cause: error });
            }
            else {
                throw new IncomeHistoryError(`Unexpected income history error: '${error}'`);
            }
        }
    }
    async getAmenities(propertyId) {
        try {
            const { data } = await this.client.get(`v2/properties/${propertyId}/amenities`);
            return data.items.map(amenity => new Amenity(amenity));
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new AmenitiesError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new AmenitiesError('Unexpected on sale property amenities error', { cause: error });
            }
            else {
                throw new AmenitiesError(`Unexpected on sale property amenities error: '${error}'`);
            }
        }
    }
    async getBricksSummary(propertyId, payload) {
        try {
            const { data } = await this.client.get(`v1/properties/${propertyId}/bricks/onSaleSummary`, payload);
            return new BricksSummary(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    default:
                        throw new BrickSummaryError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new BrickSummaryError('Unexpected brick summary error', { cause: error });
            }
            else {
                throw new BrickSummaryError(`Unexpected brick summary error: '${error}'`);
            }
        }
    }
}
export default OnSalePropertyAPI;
