import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { useNavigate } from 'react-router-dom';

import { renderSomethingWentWrong, renderSuccessToast } from '@utils';
import { useAPI, useRequest } from '@hooks';
import { useErrorReporter } from '@lib/errorReporter';
import {
  Button,
  Icon,
  Icons,
  LegacyLink,
  PasswordField,
  PublicLayout,
  TextField,
  OneTimePassword
} from '@components';

import VerificationWrapper from './ForgotPassword.style';

import './style.scss';

const { MailIcon } = Icons;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { authentication } = useAPI();
  const errorReporter = useErrorReporter();

  const [
    recoverPasswordResponse,
    isSendingRecoverPasswordEmail, ,
    sendRecoverPasswordEmail
  ] = useRequest(authentication.sendRecoverPasswordEmail);

  const [
    updatePasswordResponse,
    isUpdatingPassword,
    updatePasswordError,
    updateForgottenPassword
  ] = useRequest(authentication.updateForgottenPassword);

  const [email, setEmail] = useState('');
  const [isFirstStepDone, setIsFirstStepDone] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState();
  const [errors, setErrors] = useState({
    passwordDoesNotMatch: false,
    invalidPassword: false
  });
  const [errorMessage, setErrorMessage] = useState('');

  const codeButtonDisabled = email.length === 0 || isFirstStepDone;
  const { passwordDoesNotMatch, invalidPassword } = errors;

  const goToLogin = () => navigate('/login');

  const resetErrors = () => {
    setErrors({
      passwordDoesNotMatch: false,
      invalidPassword: false
    });
    setErrorMessage('');
  };

  const canSubmit = () => newPassword.length > 0
    && confirmPassword.length > 0
    && verificationCode?.length === 6
    && [...verificationCode].every(digit => digit !== '');

  const handleSendEmailVerificationCode = () => {
    const payload = { email, sendingChannel: 'email' };

    sendRecoverPasswordEmail(payload);
    setIsFirstStepDone(true);
  };

  const handleChangePassword = async event => {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrors({ ...errors, passwordDoesNotMatch: true });
      setErrorMessage('Las contraseñas no coinciden');
      return;
    }

    await updateForgottenPassword({
      email,
      password: newPassword,
      code: verificationCode
    });
  };

  const handleVerificationErrors = error => {
    switch (error?.code) {
      case 'INVALID_PASSWORD':
        setErrors({ ...errors, invalidPassword: true });
        setErrorMessage('Min 6 caracteres, 1 mayúscula, 1 número y 1 carácter especial');
        break;
      case 'INVALID_CODE':
        setErrorMessage('Código inválido');
        break;
      default:
        errorReporter.critical(error);
        renderSomethingWentWrong();
        break;
    }
  };

  useEffect(() => {
    resetErrors();
  }, [verificationCode, newPassword, confirmPassword]);

  useEffect(() => {
    if (updatePasswordResponse && !updatePasswordError) {
      renderSuccessToast('Contraseña actualizada');
      goToLogin();
    } else if (updatePasswordError) {
      handleVerificationErrors(updatePasswordError);
    }
  }, [updatePasswordResponse, updatePasswordError]);

  return (
    <PublicLayout title="¿Olvidaste tu contraseña?">
      <button
        id="go-back"
        styleName="go-back"
        onClick={goToLogin}
        type="button"
      >
        <Icon name="icBackDark" alt="Regresar" />

        <p>Regresar</p>
      </button>

      <p styleName="text">
        Si olvidaste tú contraseña, ingresa tú correo electrónico.
        <br />
        Te enviaremos un código único de cambio de contraseña de 6 dígitos.
      </p>

      <VerificationWrapper>
        <TextField
          type="email"
          id="fp-email"
          placeholder="micorreo@gmail.com"
          disabled={isFirstStepDone}
          label="Email:"
          onChange={event => setEmail(event.target.value.toLowerCase())}
          value={email}
          inputProps={{ autoComplete: 'off' }}
          InputLabelProps={{ requiredIndicator: 'asterisk' }}
          startAdornment={<MailIcon />}
          required
        />

        <Button
          id="forgot-password-code-button"
          disabled={codeButtonDisabled}
          isLoading={isSendingRecoverPasswordEmail}
          onClick={handleSendEmailVerificationCode}
        >
          Enviar código
        </Button>

        <OneTimePassword
          error={{ message: errorMessage }}
          disabled={!verificationCode && isEmpty(recoverPasswordResponse)}
          resendOptions={['email']}
          onChange={code => setVerificationCode(code)}
          onSendCode={handleSendEmailVerificationCode}
        />
      </VerificationWrapper>

      <form onSubmit={handleChangePassword}>
        <PasswordField
          id="fp-new-password"
          placeholder="contraseña"
          label="Nueva Contraseña:"
          required
          disabled={!isFirstStepDone}
          onChange={event => setNewPassword(event.target.value)}
          value={newPassword}
          className="margin-t-7"
          styleName="input"
          error={invalidPassword}
          helperText={invalidPassword ? errorMessage : ''}
          inputProps={{ autoComplete: 'off' }}
          InputLabelProps={{ requiredIndicator: 'asterisk' }}
        />
        <PasswordField
          id="fp-confirm-password"
          placeholder="contraseña"
          label="Confirmar Contraseña:"
          required
          disabled={!isFirstStepDone}
          onChange={event => setConfirmPassword(event.target.value)}
          value={confirmPassword}
          className="margin-t-7"
          styleName="input"
          error={passwordDoesNotMatch}
          helperText={passwordDoesNotMatch ? errorMessage : ''}
          inputProps={{ autoComplete: 'off' }}
          InputLabelProps={{ requiredIndicator: 'asterisk' }}
        />
        <Button
          id="forgot-password-submit-button"
          className="margin-t-7"
          styleName="button"
          type="submit"
          disabled={!canSubmit()}
          isLoading={isUpdatingPassword}
        >
          Restablecer contraseña
        </Button>
      </form>

      <p styleName="go-back-login">
        ¿Ya tienes cuenta?
        {' '}
        <LegacyLink to="/login">Inicia sesión</LegacyLink>
      </p>
    </PublicLayout>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    replace: PropTypes.func,
    push: PropTypes.func
  }).isRequired
};

export default ForgotPassword;
