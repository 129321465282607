import React from 'react';
import PropTypes from 'prop-types';

import { Percentage } from '@types';
import { Paragraph, Subtitle } from '@components/Styled';

import Counter from '../../../Counter';

import './style.scss';

const Discounts = props => {
  const {
    presaleDiscount,
    maximumDiscount,
    presaleExpirationDate
  } = props;
  const areDiscountsEmpty = !presaleDiscount && !maximumDiscount;

  if (areDiscountsEmpty) {
    return (
      <Paragraph styleName="empty-discounts" tid="empty-discounts">
        Esta propiedad no cuenta con descuentos
      </Paragraph>
    );
  }

  const formattedPresaleDiscount = `${new Percentage(presaleDiscount).format()}%`;
  const formattedMaximumDiscount = `${new Percentage(maximumDiscount).format()}%`;
  const isPresaleDiscountVisible = Boolean(presaleDiscount);
  const isMaximumDiscountVisible = Boolean(maximumDiscount);
  const maximumCaptionText = isPresaleDiscountVisible ? '+ volumen' : 'Por volumen';
  const isEmptyPresaleDiscountVisible = !isPresaleDiscountVisible && isMaximumDiscountVisible;
  const isEmptyMaximumDiscountVisible = !isMaximumDiscountVisible && isPresaleDiscountVisible;

  return (
    <div styleName="discounts">
      <Paragraph>Descuentos</Paragraph>
      <div styleName="discounts-section">
        <div>
          {isPresaleDiscountVisible && (
            <div styleName="presale-discount" tid="presale-discount">
              {isMaximumDiscountVisible && (
                <Paragraph tid="presale-discount-percent-caption">
                  desde
                </Paragraph>
              )}
              <Subtitle level="2" tid="presale-discount-percent" color="primary-main">
                {formattedPresaleDiscount}
              </Subtitle>
              <Paragraph styleName="presale-caption" tid="presale-discount-caption">
                Por preventa
              </Paragraph>
            </div>
          )}
          {isEmptyPresaleDiscountVisible && (
            <div styleName="empty-presale-discount" tid="empty-presale-discount">
              Sin descuento de preventa
            </div>
          )}
        </div>
        <div>
          {isMaximumDiscountVisible && (
            <div styleName="maximum-discount" tid="maximum-discount">
              <Paragraph tid="maximum-discount-percent-caption">
                hasta
              </Paragraph>
              <Subtitle level="2" color="primary-main" tid="maximum-discount-percent">
                {formattedMaximumDiscount}
              </Subtitle>
              <div>
                {isPresaleDiscountVisible && (
                  <Paragraph styleName="maximum-caption-presale" tid="maximum-caption-presale">
                    Por preventa
                  </Paragraph>
                )}
                <Paragraph tid="maximum-caption-text">{maximumCaptionText}</Paragraph>
              </div>
            </div>
          )}
          {isEmptyMaximumDiscountVisible && (
            <div styleName="empty-maximum-discount" tid="empty-maximum-discount">
              Sin descuentos de volumen
            </div>
          )}
        </div>
      </div>
      {isPresaleDiscountVisible && (
        <Counter timestamp={presaleExpirationDate} row styleName="discounts-counter" />
      )}
    </div>
  );
};

Discounts.defaultProps = {
  presaleDiscount: 0,
  maximumDiscount: 0,
  presaleExpirationDate: ''
};

Discounts.propTypes = {
  presaleDiscount: PropTypes.number,
  maximumDiscount: PropTypes.number,
  presaleExpirationDate: PropTypes.string
};

export default Discounts;
