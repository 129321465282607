import React from 'react';
import PropTypes from 'prop-types';

import { Money, Percentage } from '@types';

import './style.scss';

const generateQuotationDetails = props => {
  const {
    retailPricePerBrick = 0,
    quantity = 0,
    totalRetailPrice = 0,
    presaleDiscountPercentagePerBrick = 0,
    presaleDiscountTotalSavings = 0,
    volumeDiscountPercentagePerBrick = 0,
    volumeDiscountTotalSavings = 0,
    totalPaid = 0,
  } = props;
  const formattedPricePerBrick = new Money(retailPricePerBrick).toString();
  const formattedTotalRetailPrice = new Money(totalRetailPrice).toString();
  const formattedPresaleDiscount = new Percentage(presaleDiscountPercentagePerBrick).format();
  const formattedPresaleDiscountAmount = new Money(presaleDiscountTotalSavings).toString();
  const formattedVolumeDiscount = new Percentage(volumeDiscountPercentagePerBrick).format();
  const formattedVolumeDiscountAmount = new Money(volumeDiscountTotalSavings).toString();
  const formattedTotalToPayAmount = new Money(totalPaid).toString();

  return (
    <table tid="quotation-details" styleName="quotation-details">
      <tbody>
        <tr>
          <td styleName="cell">
            <span styleName="label" tid="price-per-brick-label">
              <span>Precio ladrillo</span>
              <span>
                {`(${formattedPricePerBrick} x ${quantity}):`}
              </span>
            </span>
          </td>
          <td styleName="cell">
            <span tid="total-retail-price">
              {formattedTotalRetailPrice}
            </span>
          </td>
        </tr>
        <tr>
          <td styleName="cell">
            <span styleName="label">
              <span tid="quotation-presale-discount">
                {`Desc preventa (${formattedPresaleDiscount}%):`}
              </span>
            </span>
          </td>
          <td styleName="cell">
            <span tid="presale-discount-amount" styleName="discount-amount">
              {`- ${formattedPresaleDiscountAmount}`}
            </span>
          </td>
        </tr>
        <tr>
          <td styleName="cell">
            <span styleName="label">
              <span tid="quotation-volume-discount">
                {`Desc volumen (${formattedVolumeDiscount}%):`}
              </span>
            </span>
          </td>
          <td styleName="cell">
            <span tid="volume-discount-amount" styleName="discount-amount">
              {`- ${formattedVolumeDiscountAmount}`}
            </span>
          </td>
        </tr>
        <tr styleName="total-row">
          <td styleName="cell">
            <span>
              Total inversión:
            </span>
          </td>
          <td styleName="cell">
            <span tid="total-to-pay-amount">
              {formattedTotalToPayAmount}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

generateQuotationDetails.propTypes = {
  retailPricePerBrick: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  totalRetailPrice: PropTypes.number.isRequired,
  presaleDiscountPercentagePerBrick: PropTypes.number.isRequired,
  presaleDiscountTotalSavings: PropTypes.number.isRequired,
  volumeDiscountPercentagePerBrick: PropTypes.number.isRequired,
  volumeDiscountTotalSavings: PropTypes.number.isRequired,
  totalPaid: PropTypes.number.isRequired,
};

export default generateQuotationDetails;
