import EnrollmentInvestor from '../EnrollmentInvestor';
class AutomaticInvoicingInvestor extends EnrollmentInvestor {
    hasAutomaticInvoicing;
    constructor(automaticInvoicingInvestorData) {
        const { automaticInvoicing, ...enrollmentInvestor } = automaticInvoicingInvestorData;
        super(enrollmentInvestor);
        this.hasAutomaticInvoicing = automaticInvoicing;
    }
}
export default AutomaticInvoicingInvestor;
