/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import { AccountLockCodes } from '@types';
import { Button, TextField } from '@components';
import { Paragraph } from '@components/Styled';
import { CompleteProfile } from '@views/Marketplace/components';

import QuotationDetails from '../QuotationDetails';

import errorMap from './errorMap';

import './style.scss';

const Quotation = props => {
  /** The dependency below is causing dependency cycles, for this reason the dependency is loaded
    * via require. Please, always avoid the use of require() calls in any other place under this
    * codebase unless you have a good reason to do so.
    *
    * We need to move the api module to the @lib module in order to remove the dependency cycle
    */
  const { useProfile } = require('@lib/profile');

  const {
    availableBricks,
    bricksQuantity,
    handleChangeBricksQuantity,
    handleClickAcceptButton,
    isLegacyAndLegalTrader,
    isAcceptButtonDisabled,
    isLoadingAcceptButton,
    maximumBricksToPurchase = 0,
    profileCompleted,
    quotation = {},
    quotationErrorCode = '',
    trader
  } = props;
  const {
    pricePerBrick = 0,
    revolvingFund = 0,
    subtotal = 0,
    totalAmount = 0,
    totalRevolvingFund = 0
  } = quotation;

  const { profile } = useProfile();

  const canBuy = profile.canBuy();
  const hasValuesQuotation = Object.keys(quotation).length > 0;
  const areQuotationNotesVisible = !hasValuesQuotation;
  const isVisibleProfileCompleted = profileCompleted
    && !canBuy
    && !profile.status.isReadyToSignRiskForm();
  const isIncompleteProfileVisible = !profileCompleted
    && !isLegacyAndLegalTrader
    && !canBuy
    && !profile.status.isReadyToSignRiskForm();
  const isAcceptButtonVisible = hasValuesQuotation
    && ((!isIncompleteProfileVisible
    && !isVisibleProfileCompleted)
    || profile.status.isReadyToSignRiskForm());
  const errorProps = {};

  const isBricksQuantityFieldDisabled = () => {
    const { lockCode = '' } = trader;
    const { isSoftLockCode, isShadowLockCode } = new AccountLockCodes();

    return !maximumBricksToPurchase
      || isSoftLockCode(lockCode)
      || isShadowLockCode(lockCode);
  };

  if (quotationErrorCode) {
    errorProps.maximumBricksToBuy = maximumBricksToPurchase;
  }

  return (
    <div styleName="quotation" tid="quotation">
      <div tid="bricks-quantity-field" styleName="bricks-quantity-field">
        <TextField
          id="bricks-quantity"
          tid="bricks-quantity"
          styleName="input"
          label="# Ladrillos"
          type="text"
          value={String(bricksQuantity) || ''}
          onChange={handleChangeBricksQuantity}
          disabled={isBricksQuantityFieldDisabled()}
          placeholder={String(availableBricks) || ''}
          helperText={quotationErrorCode && errorMap(quotationErrorCode, errorProps)}
          error={Boolean(quotationErrorCode)}
          required
          inputProps={{
            name: 'bricks-quantity',
            autoComplete: 'off',
            'aria-label': 'Número de Ladrillos'
          }}
        />
      </div>
      {hasValuesQuotation && (
        <QuotationDetails
          pricePerBrick={pricePerBrick}
          bricksQuantity={bricksQuantity}
          revolvingFund={revolvingFund}
          subtotal={subtotal}
          totalAmount={totalAmount}
          totalRevolvingFund={totalRevolvingFund}
        />
      )}
      {isAcceptButtonVisible && (
        <Button
          id="accept-button"
          tid="accept-button"
          rounded
          styleName="accept-button"
          isLoading={isLoadingAcceptButton}
          disabled={isAcceptButtonDisabled || isLoadingAcceptButton}
          onClick={handleClickAcceptButton}
        >
          Comprar
        </Button>
      )}
      {isVisibleProfileCompleted && (
        <div styleName="info" tid="profile-completed">
          <Paragraph>Ya casi puedes comprar, estamos procesando tu información</Paragraph>
        </div>
      )}
      {areQuotationNotesVisible && (
        <Paragraph tid="quotation-notes" styleName="quotation-notes">
          <Paragraph tid="maximum-bricks-to-purchase-note">
            {`* Tu compra máxima de Ladrillos: ${maximumBricksToPurchase}`}
          </Paragraph>
        </Paragraph>
      )}
      {isIncompleteProfileVisible && (
        <div tid="incomplete-profile">
          <CompleteProfile />
        </div>
      )}
    </div>
  );
};

Quotation.defaultProps = {
  bricksQuantity: 0,
  isAcceptButtonDisabled: false,
  isLoadingAcceptButton: false,
  quotation: {},
  maximumBricksToPurchase: 0,
  quotationErrorCode: ''
};

Quotation.propTypes = {
  availableBricks: PropTypes.number.isRequired,
  bricksQuantity: PropTypes.number,
  handleChangeBricksQuantity: PropTypes.func.isRequired,
  handleClickAcceptButton: PropTypes.func.isRequired,
  isAcceptButtonDisabled: PropTypes.bool,
  isLegacyAndLegalTrader: PropTypes.bool.isRequired,
  isLoadingAcceptButton: PropTypes.bool,
  profileCompleted: PropTypes.bool.isRequired,
  quotation: PropTypes.shape({
    averagePrice: PropTypes.number,
    revolvingFund: PropTypes.number,
    subtotal: PropTypes.number,
    totalAmount: PropTypes.number,
    totalRevolvingFund: PropTypes.number
  }),
  trader: PropTypes.shape({
    lockCode: PropTypes.string
  }).isRequired,
  maximumBricksToPurchase: PropTypes.number,
  quotationErrorCode: PropTypes.string
};

export default Quotation;
