import APIError from '../APIError';

const AUTHENTICATION_ATTEMPS_LIMIT_REACHED = 'AUTHENTICATION_ATTEMPS_LIMIT_REACHED';
const INCORRECT_PASSWORD = 'INCORRECT_PASSWORD';
const INVALID_USER_CONNECTION = 'INVALID_USER_CONNECTION';
const TRADER_ALREADY_HAS_ACTIVE_SESSION = 'TRADER_ALREADY_HAS_ACTIVE_SESSION';
const UNUSUAL_ACCESS = 'UNUSUAL_ACCESS';
const TRADER_NOT_FOUND = 'TRADER_NOT_FOUND';

class AuthenticationError extends APIError {
  constructor(code, details = null) {
    super(code, 'AuthenticationError', details);
  }

  hasActiveSession() {
    return this._code === TRADER_ALREADY_HAS_ACTIVE_SESSION;
  }

  hasActiveVPN() {
    return this._code === INVALID_USER_CONNECTION;
  }

  hasFailedAttemptsLock() {
    return this._code === AUTHENTICATION_ATTEMPS_LIMIT_REACHED;
  }

  isIncorrectPassword() {
    return this._code === INCORRECT_PASSWORD;
  }

  isTraderNotFound() {
    return this._code === TRADER_NOT_FOUND;
  }

  isUnusualAccess() {
    return this._code === UNUSUAL_ACCESS;
  }
}

export default AuthenticationError;
