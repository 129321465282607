import HundredBricksAPI from '@lib/hundredBricksAPI/HundredBricksAPI';
import CapitalGainRecord from './CapitalGainRecord';
import CapitalGainSummary from './CapitalGainSummary';
import LiquidityCapitalGainError from './LiquidityCapitalGainError';
import RealEstateCapitalGainError from './RealEstateCapitalGainError';
import StandardCapitalGainError from './StandardCapitalGainError';
class CapitalGainAPI extends HundredBricksAPI {
    async getStandardCapitalGain() {
        try {
            const { data } = await this.client.get('/v2/capitalGain/standard');
            return new CapitalGainRecord(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new StandardCapitalGainError('Cannot obtain standard capital gain', { cause: error });
            }
            else {
                throw new StandardCapitalGainError(`Unexpected error occured while obtaining standard capital gain: '${error}'`);
            }
        }
    }
    async getLiquidityCapitalGain() {
        try {
            const { data } = await this.client.get('/v2/capitalGain/liquidity');
            return new CapitalGainRecord(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new LiquidityCapitalGainError('Cannot obtain liquidity capital gain', { cause: error });
            }
            else {
                throw new LiquidityCapitalGainError(`Unexpected error occured while obtaining liquidity capital gain: '${error}'`);
            }
        }
    }
    async getRealEstateCapitalGain() {
        try {
            const { data } = await this.client.get('/v2/capitalGain/realEstate');
            return new CapitalGainRecord(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new RealEstateCapitalGainError('Cannot obtain real estate capital gain', { cause: error });
            }
            else {
                throw new RealEstateCapitalGainError(`Unexpected error occured while obtaining real estate capital gain: '${error}'`);
            }
        }
    }
    async getStandardCapitalGainDetails() {
        try {
            const { data } = await this.client.get('/v2/capitalGain/standard/detail');
            return new CapitalGainSummary(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new StandardCapitalGainError('Cannot obtain detailed standard capital gain', { cause: error });
            }
            else {
                throw new StandardCapitalGainError(`Unexpected error occured while obtaining detailed standard capital gain: '${error}'`);
            }
        }
    }
    async getLiquidityCapitalGainDetails() {
        try {
            const { data } = await this.client.get('/v2/capitalGain/liquidity/detail');
            return new CapitalGainSummary(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new LiquidityCapitalGainError('Cannot obtain detailed liquidity capital gain', { cause: error });
            }
            else {
                throw new LiquidityCapitalGainError(`Unexpected error occured while obtaining detailed liquidity capital gain: '${error}'`);
            }
        }
    }
    async getRealEstateCapitalGainDetails() {
        try {
            const { data } = await this.client.get('/v2/capitalGain/realEstate/detail');
            return new CapitalGainSummary(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new RealEstateCapitalGainError('Cannot obtain detailed real estate capital gain', { cause: error });
            }
            else {
                throw new RealEstateCapitalGainError(`Unexpected error occured while obtaining detailed real estate capital gain: '${error}'`);
            }
        }
    }
}
export default CapitalGainAPI;
