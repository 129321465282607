import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

import cienLadrillosLogo from '@assets/images/100-ladrillos-logo.svg';
import { useProfile } from '@lib/profile';
import { usePaymentPlans } from '@lib/paymentPlans';
import {
  Icon,
  Image,
  RenderIf,
  UpgradeAccountButton
} from '@components';

import withUpgradeAccountButton from '../../../../hoc/withUpgradeAccountButton';
import Money from '../../../../types/money';
import {
  profileIncompletedNavItems,
  profileCompletedNavItems,
  enrolledNavItems,
  readyToSignRiskFormNavItems
} from '../../navItems';
import NavList from '../NavList';

import {
  AccountInfo,
  AccountValue,
  AviableAmount,
  MobileMenuWrapper,
  MobileMenuUpgradeAccountButtonWrapper
} from './MobileMenu.style';

const formatToMoney = value => new Money(value || 0).toString();

const MobileMenu = props => {
  const {
    activeTab,
    onOutsideClick,
    onNavClick,
    isOpen,
    isLegacyAndLegalTrader,
    isEmptyTrader,
    showUpgradeAccountButton
  } = props;

  const { hasPlans } = usePaymentPlans();
  const { profile, investorBalance } = useProfile();
  const [navItems, setNavItems] = useState([]);

  const onOutsideClickFunction = isOpen ? onOutsideClick : () => { };
  const UpgradeButton = withUpgradeAccountButton(UpgradeAccountButton);

  useEffect(() => {
    if (profile.canBuy()) {
      setNavItems(enrolledNavItems);

      if (investorBalance?.hasInvested()) {
        setNavItems(prev => prev.filter(item => item.id !== 'first-steps'));
      }

      if (!hasPlans) {
        setNavItems(prev => prev.filter(item => item.id !== 'paymentPlans'));
      }
    } else if (profile.status.isReadyToSignRiskForm()) {
      setNavItems(readyToSignRiskFormNavItems);
    } else if (profile.status.isEnrolling()) {
      setNavItems(profileIncompletedNavItems);
    } else {
      setNavItems(profileCompletedNavItems);
    }
  }, [profile, hasPlans, investorBalance]);

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClickFunction}>
      <MobileMenuWrapper
        open={isOpen}
        noAccountInfo={!profile.canBuy()}
        enrolled={profile.canBuy()}
      >
        <header>
          <a href="https://www.100ladrillos.com">
            <Image src={cienLadrillosLogo} alt="Logo de 100 Ladrillos" />
          </a>
        </header>

        <RenderIf condition={profile.canBuy()}>
          <AccountInfo>
            <li>
              <span>Valor de la cuenta</span>
              <AccountValue>{formatToMoney(investorBalance?.amount)}</AccountValue>
            </li>
            <li>
              <span>Saldo disponible</span>
              <AviableAmount>{formatToMoney(investorBalance?.available)}</AviableAmount>
            </li>
          </AccountInfo>
        </RenderIf>

        <RenderIf condition={showUpgradeAccountButton}>
          <MobileMenuUpgradeAccountButtonWrapper>
            <Icon
              name="icUpgradeAccountModal"
              alt="Imagen conviértete en un usuario sin límites"
              height={32}
              width={32}
            />

            <UpgradeButton variant="text" />
          </MobileMenuUpgradeAccountButtonWrapper>
        </RenderIf>

        <NavList
          items={navItems}
          activeTab={activeTab}
          onClick={onNavClick}
          isLegacyAndLegalTrader={isLegacyAndLegalTrader}
          isEmptyTrader={isEmptyTrader}
        />
      </MobileMenuWrapper>
    </OutsideClickHandler>
  );
};

MobileMenu.propTypes = {
  activeTab: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  onNavClick: PropTypes.func.isRequired,
  isLegacyAndLegalTrader: PropTypes.bool.isRequired,
  isEmptyTrader: PropTypes.bool.isRequired,
  showUpgradeAccountButton: PropTypes.bool.isRequired
};

export default MobileMenu;
