import styled from 'styled-components';

export const ActionsWrapper = styled.footer`
  display: grid;
  position: absolute;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(3)};
  left: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(2)};
  bottom: ${({ theme }) => theme.spacing(2)};

  a {
    > * {
      width: 100%;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    position: relative;
    left: 0;
    bottom: 0;
    grid-template-columns: repeat(2, max-content);
    gap: ${({ theme }) => theme.spacing(2)};
    margin-top: ${({ theme }) => theme.spacing(3)};
  }

  @media screen and (orientation:landscape)
  and (max-height: ${({ theme }) => theme.breakpoints.small}px) {
    position: relative;
    padding-top: ${({ theme }) => theme.spacing(3)};
    left: 0;
    bottom: 0;
  }
`;
