import React from 'react';
import PropTypes from 'prop-types';

import coverImage from '@assets/images/new-login-background.png';
import {
  LayoutWrapper,
  MainLayout,
  MediaContainer,
  Navbar
} from '@components';

import loginMenu from './menu';

const LoginLayout = ({ children }) => (
  <LayoutWrapper>
    <Navbar menu={loginMenu} />
    <MediaContainer backgroundImage={coverImage} />
    <MainLayout>
      {children}
    </MainLayout>
  </LayoutWrapper>
);

LoginLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default LoginLayout;
