import React from 'react';
import PropTypes from 'prop-types';

import { Button, LegacyLink } from '@components';

import { Navbar, ListWrapper, ListItem } from './PublicNavbar.style';

const PublicNavbar = ({ items = [], opened = false }) => (
  <Navbar className={opened && 'opened'}>
    <ListWrapper>
      {items.map(item => {
        let propsButton = {};
        let button = null;

        if (item.isCallToAction) {
          propsButton = {
            ...propsButton,
            rounded: true
          };
        }

        if (item.isTextButton) {
          propsButton = {
            ...propsButton,
            variant: 'text'
          };
        }

        if (item.onClick) {
          propsButton = {
            ...propsButton,
            onClick: item.onClick
          };
        }

        if (item.to) {
          button = (
            <LegacyLink to={item.to} external={!!item.external}>
              <Button {...propsButton}>{item.label}</Button>
            </LegacyLink>
          );
        } else {
          button = (<Button {...propsButton}>{item.label}</Button>);
        }

        return (
          <ListItem key={item.label}>
            {button}
          </ListItem>
        );
      })}
    </ListWrapper>
  </Navbar>
);

PublicNavbar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  opened: PropTypes.bool.isRequired
};

export default PublicNavbar;
