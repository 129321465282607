import styled from 'styled-components';

const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  > * {
    margin: 0;
    text-align: center;
  }

  span {
    color: ${({ theme }) => theme.palette.danger.main};
  }
`;

export default InfoWrapper;
