import styled from 'styled-components';

import { flexAsColumn } from '@components/Styled/mixins';

const VerificationWrapper = styled.div`
  ${flexAsColumn(2)}

  align-items: center;

  > div, > form {
    min-width: 30%;
  }

  div {
    width: auto;
  }

  > form {
    align-items: center;
    margin: ${({ theme }) => theme.spacing(2)} 0;

    & > :last-child {
      display: none;
    }
  }
`;

export default VerificationWrapper;
