import React, { lazy, Suspense } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { useAuth } from '@lib/authentication';

import AppLayout from '../../../components/AppLayout';
import { LoadingOverlay } from '../../../components';

import IndependentRoutes from './IndependentRoutes';
import ReadyToBuyOutlet from './ReadyToBuyOutlet';

const Bricks = lazy(() => import('../../Bricks'));
const EnrollmentRouter = lazy(() => import('../../Enrollment/EnrollmentRouter'));
const MarketplaceRouter = lazy(() => import('../../Marketplace/MarketplaceRouter'));
const AccountRouter = lazy(() => import('../../MyAccount/AccountRouter'));
const MyBricks = lazy(() => import('../../Bricks/MyBricks'));
const MyBricksPerProperty = lazy(() => import('../../Bricks/MyBricksPerProperty'));
const MovementsRouter = lazy(() => import('../../Movements/MovementsRouter'));
const OnboardingRouter = lazy(() => import('../../Onboarding/OnboardingRouter'));
const PaymentPlanRouter = lazy(() => import('../../Profile/PaymentPlan/PaymentPlanRouter'));
const MyPaymentPlansRouter = lazy(() => import('../../MyPaymentPlans/MyPaymentPlansRouter'));
const Profile = lazy(() => import('../../Profile'));
const RemoveBricksOnSale = lazy(() => import('../../Bricks/RemoveBricksOnSale'));
const RentsRouter = lazy(() => import('../../Rents/RentsRouter'));
const SellRouter = lazy(() => import('../../Sell/SellRouter'));
const SignDocumentsRouter = lazy(() => import('../../SignDocuments/SignDocumentsRouter'));
const SignW8BENContract = lazy(() => import('../../SignDocuments/W8BENContract'));
const UpdateAddress = lazy(() => import('../../Profile/UpdateAddress'));
const UpdateCommercialContract = lazy(() => import('../../Profile/UpdateCommercialContract'));
const UpdateEmailRouter = lazy(() => import('../../Profile/UpdateEmail/UpdateEmailRouter'));
const UpdatePhoneRouter = lazy(() => import('../../Profile/UpdatePhone/UpdatePhoneRouter'));
const UpgradeAccountRouter = lazy(() => import('../../UpgradeAccount/UpgradeAccountRouter'));
const UpdateIdentificationRouter = lazy(
  () => import('../../Profile/UpdateIdentification/UpdateIdentificationRouter')
);
const ValuationMethodologiesRouter = lazy(
  () => import('../../ValuationMethodologies/ValuationMethodologiesRouter')
);

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Routes>
        <Route path="/enrollment/*" element={<EnrollmentRouter />} />
        <Route path="/sign-documents/*" element={<SignDocumentsRouter />} />
        <Route path="/upgrade-account/*" element={<UpgradeAccountRouter />} />
        <Route path="/valuation-methodologies/*" element={<ValuationMethodologiesRouter />} />

        <Route element={<ReadyToBuyOutlet />}>
          <Route path="/sign-w8-ben-contract" element={<SignW8BENContract />} />
          <Route path="/payment-plan/*" element={<PaymentPlanRouter />} />
        </Route>

        <Route element={<AppLayout />}>
          <Route path="/marketplace/*" element={<MarketplaceRouter />} />
          <Route path="/my-account/*" element={<AccountRouter />} />
          <Route path="/perfil/*" element={<Profile />} />
          <Route path="/profile/rents/*" element={<RentsRouter />} />
          <Route path="/sell/*" element={<SellRouter />} />
          <Route path="/onboarding/*" element={<OnboardingRouter />} />
          <Route path="/update-phone/*" element={<UpdatePhoneRouter />} />
          <Route path="/update-email/*" element={<UpdateEmailRouter />} />
          <Route path="/update-address" element={<UpdateAddress />} />
          <Route path="/update-identification/*" element={<UpdateIdentificationRouter />} />
          <Route path="/my-payment-plans/*" element={<MyPaymentPlansRouter />} />
          <Route
            path="/sign-new-commercial-contract-version"
            element={<UpdateCommercialContract />}
          />

          <Route element={<ReadyToBuyOutlet />}>
            <Route path="/movements/*" element={<MovementsRouter />} />
            <Route path="/my-account/my-bricks" element={<Bricks />}>
              <Route index element={<MyBricks />} />
              <Route path="property/:propertyId" element={<MyBricksPerProperty />} />
              <Route path="remove-sale" element={<RemoveBricksOnSale />} />
            </Route>
          </Route>
        </Route>

        <Route path="/*" element={<IndependentRoutes />} />
      </Routes>
    </Suspense>
  );
};

export default PrivateRoutes;
