import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import cx from 'classnames';

import TableColumn from '../TableColumn';

import './style.scss';

class TableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  handleRowClick(ev) {
    ev.stopPropagation();

    const { clickable } = this.props;

    if (clickable) {
      const { isOpen } = this.state;

      this.setState({ isOpen: !isOpen });
    }
  }

  render() {
    const { isOpen } = this.state;
    const { headers, row, clickable } = this.props;

    const styleName = cx({
      row: true,
      clickable: Boolean(clickable),
      disabled: row.disabled
    });

    const nodes = [
      <tr onClick={this.handleRowClick} styleName={styleName} key="row">
        {headers.map((header, index) => (
          <TableColumn
            {...header}
            key={shortid.generate()}
            id={`${header.id} ${shortid.generate()}`}
            tid={header.id}
          >
            {row[header.id]}
          </TableColumn>
        ))}
      </tr>
    ];

    if (isOpen) {
      return [...nodes, row.extraRow];
    }

    return nodes;
  }
}

TableRow.defaultProps = {
  row: {},
  clickable: false,
  headers: [{}]
};

TableRow.propTypes = {
  row: PropTypes.shape({}),
  clickable: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.object)
};

export default TableRow;
