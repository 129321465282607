import React, { Fragment } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import { Icon, Button } from '@components';

import { TableHeader, TableRow } from './components';

import './style.scss';

const Table = ({
  id,
  rows,
  headers,
  className,
  emptyText,
  emptyIcon,
  buttonText,
  onButtonClick,
  showHeaders,
  clickableRows
}) => (
  <Fragment>
    <table id={id} className={className} styleName="table">
      {showHeaders && (
        <thead>
          <tr>
            {headers.map(header => (
              <TableHeader
                id={`${header.id} ${shortid.generate()}`}
                key={header.id}
                size={header.size}
                type={header.type}
              >
                {header.text}
              </TableHeader>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {rows.length > 0 && (
          rows.map(row => (
            <TableRow
              row={row}
              key={shortid.generate()}
              headers={headers}
              clickable={clickableRows}
              tid="table-row"
            />
          ))
        )}
      </tbody>
    </table>
    {!rows.length && (
      <section id="empty-table-row" tid="empty-table" styleName="empty-table-body">
        <Icon name={emptyIcon} alt="empty-table-icon" />
        <span>{emptyText}</span>
        {buttonText && (
          <Button id={`${id}-empty-btn`} onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </section>
    )}
  </Fragment>
);

Table.defaultProps = {
  id: '',
  rows: [],
  headers: [],
  className: '',
  emptyText: '',
  emptyIcon: '',
  buttonText: '',
  onButtonClick: () => { },
  showHeaders: true,
  clickableRows: false
};

Table.propTypes = {
  id: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  emptyText: PropTypes.string,
  emptyIcon: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  showHeaders: PropTypes.bool,
  clickableRows: PropTypes.bool
};

export default Table;
