import { jsx as _jsx } from "react/jsx-runtime";
import { Icons } from '@components';
const amenitiesCategorizer = (amenitiesData) => {
    if (!amenitiesData)
        return [];
    const accessibilityAndParking = {
        icon: (_jsx(Icons.VehicleIcon, {})),
        sectionName: 'Accesibilidad y estacionamientos',
        amenitiesData: []
    };
    const securityAndTechnology = {
        icon: (_jsx(Icons.SecurityIcon, {})),
        sectionName: 'Seguridad y tecnología',
        amenitiesData: []
    };
    const commonAreas = {
        icon: (_jsx(Icons.ParkIcon, {})),
        sectionName: 'Espacios comunes',
        amenitiesData: []
    };
    const convenienceAndServices = {
        icon: (_jsx(Icons.StoreIcon, {})),
        sectionName: 'Conveniencia y servicios',
        amenitiesData: []
    };
    const leisure = {
        icon: (_jsx(Icons.SwimmingIcon, {})),
        sectionName: 'Recreación',
        amenitiesData: []
    };
    const other = {
        icon: (_jsx(Icons.BuildingIcon, {})),
        sectionName: 'Otras',
        amenitiesData: []
    };
    amenitiesData.forEach(amenity => {
        if (amenity.category === 'accessibility' || amenity.category === 'parking') {
            accessibilityAndParking.amenitiesData.push(amenity);
        }
        else if (amenity.category === 'security' || amenity.category === 'technology') {
            securityAndTechnology.amenitiesData.push(amenity);
        }
        else if (amenity.category === 'convenience' || amenity.category === 'services') {
            commonAreas.amenitiesData.push(amenity);
        }
        else if (amenity.category === 'common_area') {
            convenienceAndServices.amenitiesData.push(amenity);
        }
        else if (amenity.category === 'leisure') {
            leisure.amenitiesData.push(amenity);
        }
        else {
            other.amenitiesData.push(amenity);
        }
    });
    const allSections = [
        accessibilityAndParking,
        securityAndTechnology,
        commonAreas,
        convenienceAndServices,
        leisure,
        other
    ];
    return allSections.filter(section => section.amenitiesData.length > 0);
};
export default amenitiesCategorizer;
