import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useAuth } from '@lib/authentication';
import { useProfile } from '@lib/profile';
import cienLadrillosLogo from '@assets/images/100-ladrillos-logo.svg';

import Icon from '../Icon';
import Text from '../Text';
import RenderIf from '../RenderIf';
import UpgradeAccountButton from '../UpgradeAccountButton';
import withUpgradeAccountButton from '../../hoc/withUpgradeAccountButton';

import { NotificationsButton } from './components';
import {
  BurgerMenuButton,
  MarketplaceHeader,
  IconContainer,
  Logo,
  LogoutButton,
  UpgradeAccountBlinkingLight,
  UserAvatar
} from './Header.style';

const Header = ({ onMenuClick }) => {
  const auth = useAuth();
  const { profile } = useProfile();

  const UpgradeButton = withUpgradeAccountButton(UpgradeAccountButton);

  const handleLogout = () => auth.logout();

  return (
    <MarketplaceHeader>
      <BurgerMenuButton id="mobile-menu" onClick={onMenuClick}>
        <Icon name="icMenu" alt="Menú móvil" />
        <RenderIf
          condition={profile.status.isUpgradingAccount() || profile.status.isRestricted()}
        >
          <UpgradeAccountBlinkingLight />
        </RenderIf>
      </BurgerMenuButton>

      <Link to="/marketplace">
        <Logo src={cienLadrillosLogo} alt="Logo de 100 Ladrillos" />
      </Link>

      <IconContainer>
        <UpgradeButton variant="text" />

        {profile.canBuy() && <NotificationsButton />}

        {profile.isDoneRegistering() && (
          <UserAvatar to="/perfil">
            <Icon name="icAvatar" alt="Avatar" />
            <Text data-heap-redact-text>{profile.fullName || profile.email.address}</Text>
          </UserAvatar>
        )}

        <LogoutButton
          id="header-logout"
          color="none"
          onClick={handleLogout}
        >
          <Icon name="icLogout" alt="Salir" />
        </LogoutButton>
      </IconContainer>
    </MarketplaceHeader>
  );
};

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired
};

Header.propTypes = {
  onMenuClick: PropTypes.func.isRequired
};

export default Header;
