import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

import propertySoldImage from '@assets/images/property-sold.svg';
import { Icon, Image, RenderIf } from '@components';
import { Money, Percentage } from '@types';
import { Text, Headline1 } from '@components/Styled';

import { DiscountBrickPrice, Quoter } from './components';
import {
  AdditionIcon,
  AvailableBricks,
  BrickPrice,
  CompletedPropertyBadge,
  EstimatedDeliveryDate,
  PropertySoldContainer,
  QuoterContainer,
  ShoppingCartWrapper,
  VolumeDiscount
} from './ShoppingCart.style';

const moneyFormatter = amount => new Money(amount).toString();
const percentageFormatter = percentage => (`${new Percentage(percentage).format()}%`);

const ShoppingCart = props => {
  const {
    onChange,
    quote,
    property,
    trader,
    profileCompleted,
    isLegacyAndLegalTrader
  } = props;
  const {
    id,
    bricksAvailable,
    maximumBricksToBuy,
    minimumBricksToBuy,
    numberOfBricks,
    presalePrice,
    retailPrice,
    status,
    volumeDiscounts
  } = property;

  const isCompletedProperty = status === 'FINISHED';
  const maximumVolumeDiscount = `${percentageFormatter(
    volumeDiscounts[volumeDiscounts.length - 1]?.discountPercent
  )}`;

  const presalePriceFormatted = {
    discountPercent: percentageFormatter(presalePrice.discountPercent),
    pricePerBrick: moneyFormatter(presalePrice.pricePerBrick),
    expiresAt: presalePrice.expiresAt
  };

  const estimatedDeliveryDate = () => {
    const deliveryEndingDate = new Date(property.deliveryEndingDate);
    const estimatedMonth = deliveryEndingDate.toLocaleString('es', { month: 'long' });
    const estimatedYear = deliveryEndingDate.getFullYear();

    return `${estimatedMonth.charAt(0).toUpperCase() + estimatedMonth.slice(1)} ${estimatedYear}`;
  };

  return (
    <ShoppingCartWrapper>
      <div>
        <AvailableBricks>
          <span>Ladrillos disponibles</span>

          <span>{bricksAvailable.toLocaleString()}</span>

          <span>{`/${numberOfBricks.toLocaleString()}`}</span>
        </AvailableBricks>

        <BrickPrice>
          {presalePrice.discountPercent > 0 ? (
            <DiscountBrickPrice
              retailPrice={moneyFormatter(retailPrice)}
              presalePrice={presalePriceFormatted}
            />
          ) : (
            <Fragment>
              <Headline1>{moneyFormatter(retailPrice)}</Headline1>

              <Text>por Ladrillo</Text>
            </Fragment>
          )}
        </BrickPrice>

        <VolumeDiscount hasVolumeDiscount={!isEmpty(volumeDiscounts)}>
          <Text fontWeight="medium">Descuento por volumen</Text>

          {!isEmpty(volumeDiscounts) ? (
            <Fragment>
              <AdditionIcon
                name="icAddition"
                alt="Símbolo de suma"
                width={40}
                height={40}
              />

              <Text>hasta:</Text>

              <Headline1 color="danger-main">{maximumVolumeDiscount}</Headline1>

              <Text>por ladrillo</Text>
            </Fragment>
          ) : (
            <Text align="center" width="80%">
              Esta propiedad actualmente no cuenta con descuentos por volumen
            </Text>
          )}
        </VolumeDiscount>

        <QuoterContainer>
          <RenderIf condition={bricksAvailable > 0}>
            <Quoter
              id="presale-quoter"
              minimumBricksToBuy={minimumBricksToBuy}
              maximumBricksToBuy={maximumBricksToBuy}
              quote={quote}
              onChange={onChange}
              propertyId={id}
              trader={trader}
              profileCompleted={profileCompleted}
              isLegacyAndLegalTrader={isLegacyAndLegalTrader}
              availableBricks={bricksAvailable}
            />
          </RenderIf>

          <RenderIf condition={bricksAvailable === 0}>
            <PropertySoldContainer>
              <Image src={propertySoldImage} alt="Propiedad vendida" />

              <Text>Propiedad vendida</Text>
            </PropertySoldContainer>
          </RenderIf>
        </QuoterContainer>
      </div>

      <EstimatedDeliveryDate isCompletedProperty={isCompletedProperty}>
        <RenderIf condition={isCompletedProperty}>
          <CompletedPropertyBadge>
            <Icon
              name="icCompletedProperty"
              alt="Propiedad terminada"
            />

            <Text>Propiedad terminada</Text>
          </CompletedPropertyBadge>
        </RenderIf>

        <Text>
          {'Fecha estimada de entrega: '}
          <strong>{estimatedDeliveryDate()}</strong>
        </Text>
      </EstimatedDeliveryDate>
    </ShoppingCartWrapper>
  );
};

ShoppingCart.propTypes = {
  quote: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  trader: PropTypes.shape({}).isRequired,
  profileCompleted: PropTypes.bool.isRequired,
  isLegacyAndLegalTrader: PropTypes.bool.isRequired,
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    bricksAvailable: PropTypes.number.isRequired,
    deliveryEndingDate: PropTypes.string.isRequired,
    maximumBricksToBuy: PropTypes.number.isRequired,
    minimumBricksToBuy: PropTypes.number.isRequired,
    numberOfBricks: PropTypes.number.isRequired,
    retailPrice: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    volumeDiscounts: PropTypes.arrayOf(PropTypes.shape({
      discountPercent: PropTypes.number
    })),
    presalePrice: PropTypes.shape({
      discountPercent: PropTypes.number,
      expiresAt: PropTypes.string,
      pricePerBrick: PropTypes.number.isRequired
    })
  })
};

ShoppingCart.defaultProps = {
  quote: {},
  property: {
    volumeDiscounts: [],
    presalePrice: {
      discountPercent: 0,
      expiresAt: null
    }
  }
};

export default ShoppingCart;
