import styled from 'styled-components';

const CancelProcessArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(15)};
  width: 100%;

  > * {
    margin: 0;
    position: static;
  }

  > button {
    font-weight: bold;
    width: fit-content;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    align-items: center;
    background:
      linear-gradient(transparent 0%, rgb(255 255 255 / 75%) 35%, white 100%);
    bottom: 0;
    left: 0;
    margin: 0;
    padding: ${({ theme }) => theme.spacing(4)} 0;
    position: fixed;
    width: 40%;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(3)};
  max-width: ${({ theme }) => theme.spacing(54)};

  &, > * {
    text-align: center;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: center;
  width: 100%;

  > button {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    > button {
      width: auto;
    }
  }
`;

export { ActionButtons, CancelProcessArea, ContentWrapper };
