import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import obfuscate from '../../utils/obfuscate';

import './style.scss';

const TIMER_DURATION_MINUTES = 60;

const SendButton = props => {
  const [timer, setTimer] = useState(0);

  const {
    handleSendByEmail,
    handleSendBySms,
    isSendByEmailButtonDisabled,
    isSendBySmsButtonDisabled,
    isSendByEmailOnly,
    isSendBySmsOnly,
    phoneToSendSms
  } = props;
  const isTimerRunning = timer > 0;
  const formattedPhone = phone => obfuscate(phone, 'phone');

  const isSendCodeByEmailButtonVisible = () => !isSendBySmsOnly;

  const isConnectorTextVisible = () => !isSendBySmsOnly
    && !isSendByEmailOnly;

  const isSendCodeBySmsButtonVisible = () => !isSendByEmailOnly && Boolean(phoneToSendSms);

  const initTimer = () => {
    setTimer(TIMER_DURATION_MINUTES);
  };

  const handleSendByEmailClick = () => {
    initTimer();
    handleSendByEmail();
  };

  const handleSendBySmsClick = () => {
    initTimer();
    handleSendBySms();
  };

  const isSendCodeByEmailButtonDisabled = () => isSendByEmailButtonDisabled
    || isTimerRunning;

  const isSendCodeBySmsButtonDisabled = () => isSendBySmsButtonDisabled
    || isTimerRunning;

  useEffect(() => {
    let timeout;

    if (isTimerRunning) {
      timeout = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    timer,
    isTimerRunning
  ]);

  return (
    <div styleName="send-button-container">
      {isSendCodeByEmailButtonVisible() && (
        <Button
          id="send-code-by-email"
          tid="send-code-by-email"
          variant="text"
          onClick={handleSendByEmailClick}
          styleName="send-button"
          disabled={isSendCodeByEmailButtonDisabled()}
        >
          Enviar por correo
        </Button>
      )}
      {isConnectorTextVisible() && (
        <span tid="connector-text">ó</span>
      )}
      {isSendCodeBySmsButtonVisible() && (
        <Fragment>
          <Button
            id="send-code-by-sms"
            tid="send-code-by-sms"
            variant="text"
            onClick={handleSendBySmsClick}
            styleName="send-button"
            disabled={isSendCodeBySmsButtonDisabled()}
          >
            Enviar por SMS
          </Button>
          <span tid="phone-informative-message">{`al celular ${formattedPhone(phoneToSendSms)}`}</span>
        </Fragment>
      )}
      {
        isTimerRunning && (
          <p styleName="timer-message" tid="timer-message">
            {`Podrás reenviar el código en ${timer} segundos`}
          </p>
        )
      }
    </div>
  );
};

SendButton.defaultProps = {
  isSendByEmailButtonDisabled: false,
  isSendBySmsButtonDisabled: false,
  isSendByEmailOnly: false,
  isSendBySmsOnly: false,
  phoneToSendSms: '',
  handleSendByEmail: null,
  handleSendBySms: null
};

SendButton.propTypes = {
  handleSendByEmail: PropTypes.func,
  handleSendBySms: PropTypes.func,
  isSendByEmailButtonDisabled: PropTypes.bool,
  isSendBySmsButtonDisabled: PropTypes.bool,
  isSendByEmailOnly: PropTypes.bool,
  isSendBySmsOnly: PropTypes.bool,
  phoneToSendSms: PropTypes.string
};

export default SendButton;
