import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import Image from '../../../../components/Image';
import { LeaseholdersWrapper } from './LeaseholdersList.style';
const LeaseholdersList = ({ leaseholders, propertyName }) => (_jsx(LeaseholdersWrapper, { id: `propertyDetail-${propertyName}-leaseholders`, children: leaseholders?.map(leaseholder => {
        // This is temporary until backend homologates the data between markets
        const leaseholderPhotoURL = leaseholder?.logoUrl || leaseholder?.photoURL;
        return (_jsx(Fragment, { children: leaseholderPhotoURL && (_jsx(Image, { src: leaseholderPhotoURL, alt: `Arrendatario ${leaseholder.name}` })) }, leaseholder.id));
    }) }));
export default LeaseholdersList;
