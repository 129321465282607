import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import './style.scss';

const Select = ({ id, options = [{ value: 1, text: 1 }] }) => (
  <div styleName="select">
    <div>
      <Icon name="icUser" />
    </div>
    <select name={id} id={id}>
      {options.map(option => (
        <option value={option.value} key={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  </div>
);

Select.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Select;
