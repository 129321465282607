import React, { useMemo, useState, useEffect } from 'react';

import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { useAPI } from '@hooks';
import { useAuth } from '@lib/authentication';

import LocalStoragePlatformID from '../storage';

import LegacyProfile from './LegacyProfile';
import LegacyEnrollmentProfile from './LegacyEnrollmentProfile';
import ProfileContext from './ProfileContext';
import LegacyMoralProfile from './LegacyMoralProfile';
import LegacyEnrollmentMoralProfile from './LegacyEnrollmentMoralProfile';

const INITIAL_PROFILE_DATA = {
  profile: null,
  enrollment: null,
  investorBalance: null,
  legacyProfile: null,
  legacyEnrollment: null,
  error: null,
  isLoading: false
};

/** This file requieres a lot of work and improvements in order to guarantee the right profile
  * fetching and processing:
  *
  *  - There is a useMemo() function that writes data in a outer scope variable
  *  - No idea what is LocalStoragePlatformID about, it looks weird. Maybe the name, maybe its
  *    responsibilities
  *  - There are a lot of 'eslint-disable-next-line react-hooks/exhaustive-deps' that were added
  *    to make the linter pass, but they should be removed
  *  - The component does not defines its prop-types
  *  - There is a high coupled third party dependency that should be avoided, usetiful
  *
  * Any changes required on this file, please contact Abiee. Any change on this file will require
  * to work on the issues above.
  *
  * Please DO NOT "improve", change, or add any new functionality to this code if the issues above
  * are not addressed.
  */

// eslint-disable-next-line react/prop-types
const ProfileProvider = ({ children }) => {
  /** The dependency below is causing dependency cycles, for this reason the dependency is loaded
    * via require. Please, always avoid the use of require() calls in any other place under this
    * codebase unless you have a good reason to do so.
    *
    * We need to use the hook useErrorReporter() instead
    */
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const createErrorReporter = require('../../createErrorReporter').default;

  const api = useAPI();
  const hundredBricksApi = useHundredBricksAPI();
  const { investments } = useHundredBricksAPI();
  const { isAuthenticated } = useAuth();
  const idStorage = new LocalStoragePlatformID();
  const [profileData, setProfileData] = useState(INITIAL_PROFILE_DATA);

  const errorReporter = createErrorReporter();

  const loadInvestorProfile = async () => api.trader.getProfile();
  const loadLegacyInvestorProfile = async () => api.legacy.traders.getProfile();
  const loadInvestorBalance = async () => investments.getAccountValueSummary();
  const loadLegacyEnrollmentStatus = async () => hundredBricksApi.enrollment.getStatus();

  const loadAllProfileData = async () => {
    /**
     * Due the high coupling to legacy data, it is required to have legacy enrollment
     * data and profile available for some legacy components. This dependency should be
     * removed and once removed we can remove those calls to legacy data
    */
    const [profile, legacyProfile, legacyEnrollment] = await Promise.all([
      loadInvestorProfile(),
      loadLegacyInvestorProfile(),
      loadLegacyEnrollmentStatus()
    ]);

    return { profile, legacyProfile, legacyEnrollment };
  };

  const storeProfileIdToLocalStorage = profile => idStorage.set(profile);

  const clearProfileIdFromLocalStorage = () => idStorage.clear();

  const clearProfileData = () => {
    setProfileData(INITIAL_PROFILE_DATA);
  };

  const loadProfileData = async () => {
    setProfileData({ ...profileData, isLoading: true });

    let error = null;
    let profile = null;
    const investorBalance = null;
    let enrollment = null;
    let legacyProfile = null;
    let legacyEnrollment = null;

    try {
      const data = await loadAllProfileData();

      profile = data.profile;
      enrollment = data.profile.status;

      if (profile.isLegalPerson()) {
        legacyProfile = new LegacyMoralProfile(data.profile, data.legacyProfile);
        legacyEnrollment = new LegacyEnrollmentMoralProfile(data.legacyEnrollment, data.profile);
      } else {
        legacyProfile = new LegacyProfile(data.profile, data.legacyProfile);
        legacyEnrollment = new LegacyEnrollmentProfile(data.legacyEnrollment, data.profile);
      }
    } catch (profileLoadError) {
      error = profileLoadError;
      errorReporter.error(profileLoadError);
    } finally {
      setProfileData({
        profile,
        investorBalance,
        enrollment,
        legacyProfile,
        legacyEnrollment,
        error,
        isLoading: false
      });

      storeProfileIdToLocalStorage(profile);
    }
  };

  const reloadBalance = async () => {
    let investorBalance = null;

    try {
      investorBalance = await loadInvestorBalance();
    } catch (error) {
      errorReporter.error(error);
    } finally {
      if (investorBalance) {
        setProfileData({
          ...profileData,
          investorBalance
        });
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      loadProfileData();
    } else {
      clearProfileData();
      clearProfileIdFromLocalStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useMemo(() => {
    const { profile } = profileData;
    let error = null;
    let investorBalance = null;

    const getInvestorBalance = async () => {
      try {
        investorBalance = await loadInvestorBalance();
      } catch (profileLoadError) {
        error = profileLoadError;
        errorReporter.error(profileLoadError);
      } finally {
        setProfileData({
          ...profileData,
          error,
          investorBalance
        });
      }
    };

    if (profile?.accountLevel?.hasLevel()) {
      getInvestorBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData?.profile?.accountLevel]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ProfileContext.Provider value={{
      ...profileData,
      reloadBalance,
      reloadProfileData: loadProfileData
    }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
