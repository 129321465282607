import createAPIClient from '../../createAPIClient';
import createLegacyAPIClient from '../../createLegacyAPIClient';
import sessionStore from '../../sessionStore';
import {
  AccountProfitAPI,
  AddressAPI,
  AuthenticationAPI,
  BricksAPI,
  CrowdfundingInvestmentAPI,
  CrowdfundingPropertyAPI,
  EnrollmentAPI,
  ForeignLeaseIncomesAPI,
  IdentificationAPI,
  InvoicingAPI,
  LegacyEnrollmentAPI,
  LegacyTraderAPI,
  NotificationsAPI,
  OperatorAPI,
  ProfileAPI,
  PropertyManagementAPI,
  RentReleaseSummaryAPI,
  SecondaryMarketAPI,
  SecondaryMarketPropertyAPI,
  SignDocumentsAPI,
  SignupAPI,
  UpdateDataAPI,
  UpdateEmailAPI,
  UpdatePhoneAPI,
  WithdrawalAPI
} from '../../api/v2';

export default function useAPI() {
  const api = createAPIClient(sessionStore.load());
  const legacyAPI = createLegacyAPIClient(sessionStore.load());

  return {
    accountProfit: new AccountProfitAPI(api),
    address: new AddressAPI(api),
    operator: new OperatorAPI(api),
    authentication: new AuthenticationAPI(api, legacyAPI),
    bricks: new BricksAPI(api, legacyAPI),
    crowdfundingInvestment: new CrowdfundingInvestmentAPI(api, legacyAPI),
    enrollment: new EnrollmentAPI(api),
    foreignLeaseIncomes: new ForeignLeaseIncomesAPI(api),
    identification: new IdentificationAPI(api),
    invoicing: new InvoicingAPI(api),
    notifications: new NotificationsAPI(api),
    propertyManagement: new PropertyManagementAPI(api, legacyAPI),
    rentReleaseSummary: new RentReleaseSummaryAPI(legacyAPI),
    secondaryMarket: new SecondaryMarketAPI(api, legacyAPI),
    signDocuments: new SignDocumentsAPI(api),
    signup: new SignupAPI(api),
    trader: new ProfileAPI(api),
    withdrawal: new WithdrawalAPI(api, legacyAPI),
    property: {
      crowdfunding: new CrowdfundingPropertyAPI(api),
      // Remove legacyAPI when the endpoint to obtain the property is migrated to v2
      secondaryMarketProperty: new SecondaryMarketPropertyAPI(api, legacyAPI)
    },
    update: {
      data: new UpdateDataAPI(api),
      phone: new UpdatePhoneAPI(api),
      email: new UpdateEmailAPI(api)
    },
    legacy: {
      crowdfundingInvestment: new CrowdfundingInvestmentAPI(legacyAPI),
      enrollment: new LegacyEnrollmentAPI(legacyAPI),
      invoicing: new InvoicingAPI(legacyAPI),
      traders: new LegacyTraderAPI(legacyAPI)
    }
  };
}
