import styled from 'styled-components';

import { flexAsColumn } from '@components/Styled/mixins';

const PropertyHighlights = styled.div`
  ${flexAsColumn()};

  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    margin-top: 0;
  }
`;

const HighlightList = styled.ul`
  ${flexAsColumn(2)};

  width: 100%;
  margin: ${({ theme }) => `${theme.spacing(1)} 0 0 0`};
`;

export {
  PropertyHighlights,
  HighlightList
};
