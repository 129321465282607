const ENROLLMENT_STATUSES = {
  registering: 'registering',
  incomplete: 'incomplete',
  readyToSign: 'ready_to_sign',
  signingDocuments: 'signing_documents',
  documentsSigned: 'documents_signed',
  validatingDocuments: 'validating_documents',
  correctingData: 'correcting_data',
  upgradingAccount: 'upgrading_account',
  restricted: 'restricted',
  unrestricted: 'unrestricted'
};

export default ENROLLMENT_STATUSES;
