import styled from 'styled-components';

export const TagWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.borderRadius(9)};
  box-shadow: 0px 2px 8px rgba(${({ theme }) => theme.getRgbColor(theme.palette.grayJ)}, 0.2);
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => theme.spacing(9)};
  justify-content: center;
  position: absolute;
  transform-origin: left;
  transform:
    translateX(${({ theme }) => theme.spacing(2)})
    translateY(${({ theme }) => theme.spacing(13)});
  width: ${({ theme }) => theme.spacing(9)};
  z-index: 1;

  > * {
    margin: 0;
  }

  > p {
    color: ${({ theme }) => theme.palette.grayH};
    font-size: ${({ theme }) => theme.spacing(1.5)};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    transform:
      translateX(${({ theme }) => theme.spacing(3)})
      translateY(calc((200px - 72px) - ${({ theme }) => theme.spacing(3)}));
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    transform:
      translateX(calc(280px - (72px / 2)))
      translateY(${({ theme }) => theme.spacing(13)});
  }

  @media screen and (min-width: 1280px) {
    transform:
      translateX(calc(350px - (72px / 2)))
      translateY(${({ theme }) => theme.spacing(13)});
  }
`;

export const PropertyBricksCounter = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.typography.title.h3.fontFamily};
  font-size: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export default TagWrapper;
