import React from 'react';
import PropTypes from 'prop-types';
import { Money, Percentage } from '@types';

import './style.scss';

const QuotingTable = ({ quote }) => {
  const {
    bricks,
    retailPricePerBrick = 0,
    presaleDiscountPercentagePerBrick = 0,
    presaleDiscountTotalSavings = 0,
    volumeDiscountTotalSavings = 0,
    totalRetailPrice = 0,
    totalPaid = 0,
  } = quote || {};

  const formattedPrice = new Money(retailPricePerBrick).toString();
  const formattedTotalPrice = new Money(totalRetailPrice).toString();
  const formattedPresaleDiscount = new Money(presaleDiscountTotalSavings).toString();
  const formattedTotal = new Money(totalPaid).toString();
  const formattedVolumeDiscount = new Money(volumeDiscountTotalSavings).toString();
  const formattedPresaleDiscountPercentage = new Percentage(
    presaleDiscountPercentagePerBrick,
  ).format();

  return (
    <table id="property-bricks-breakdown" styleName="quoting-table">
      <tbody>
        <tr>
          <td>
            Precio (
            {`${formattedPrice} x ${bricks}`}
            ):
          </td>
          <td>{formattedTotalPrice}</td>
        </tr>
        <tr>
          <td>
            {`Desc preventa (${formattedPresaleDiscountPercentage}%):`}
          </td>
          <td id="presale-discount" styleName="presale-discount">
            {`-${formattedPresaleDiscount}`}
          </td>
        </tr>
        <tr>
          <td>
            Desc volumen:
          </td>
          <td id="volume-discount" styleName="volume-discount">
            {`-${formattedVolumeDiscount}`}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            Total:
          </td>
          <td id="total">{formattedTotal}</td>
        </tr>
      </tfoot>
    </table>
  );
};

QuotingTable.defaultProps = {
  quote: {},
};

QuotingTable.propTypes = {
  quote: PropTypes.shape(),
};

export default QuotingTable;
