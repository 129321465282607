import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { useRequest } from '@hooks';
import { RenderIf } from '@components';
import { Subtitle } from '@components/Styled';
import { useErrorReporter } from '@lib/errorReporter';
import { PetitionerError } from '@lib/hundredBricksAPI/property/petitioner';
import Stakeholder from '../Stakeholder';
const AboutPetitioner = ({ petitionerId }) => {
    const { property } = useHundredBricksAPI();
    const errorReporter = useErrorReporter();
    const [propertyPetitionerResponse, isLoadingPropertyPetitioner, propertyPetitionerError, getPropertyPetitioner] = useRequest(property.petitioner.getPetitioner);
    const isPetitionerRequestSuccessful = propertyPetitionerResponse
        && !isLoadingPropertyPetitioner
        && !propertyPetitionerError;
    useEffect(() => {
        getPropertyPetitioner(petitionerId);
    }, []);
    useEffect(() => {
        if (propertyPetitionerError && propertyPetitionerError instanceof PetitionerError) {
            errorReporter.critical(propertyPetitionerError);
        }
    }, [propertyPetitionerError]);
    return (_jsx(RenderIf, { condition: isPetitionerRequestSuccessful, children: _jsxs("div", { children: [_jsx(Subtitle, { align: "left", level: "2", children: "Acerca del Solicitante" }), propertyPetitionerResponse
                    && (_jsx(Stakeholder, { description: propertyPetitionerResponse?.description, name: propertyPetitionerResponse?.name, photoURL: propertyPetitionerResponse?.photoURL, websiteLink: propertyPetitionerResponse?.website, highlights: [] }))] }) }));
};
export default AboutPetitioner;
