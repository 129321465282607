/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
const AuthContext = createContext({
    token: undefined,
    error: undefined,
    isAuthenticated: false,
    isLoading: false,
    clearSession: () => { },
    logout: () => { },
    refresh: () => { },
    updateSessionToken: () => { }
});
export default AuthContext;
