import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import config from '../../config';
import './style.scss';

const currentConfig = config[process.env.TARGET_ENV];
const { mapsApiKey } = currentConfig;

const MapComponent = props => {
  const { coordinates } = props;
  const center = {
    lat: coordinates.latitude,
    lng: coordinates.longitude
  };

  const loadingElement = <div styleName="loadingElement" />;

  return (
    <LoadScript
      googleMapsApiKey={mapsApiKey}
      loadingElement={loadingElement}
    >
      <GoogleMap
        mapContainerClassName="containerElement"
        center={center}
        zoom={17}
      >
        <Marker
          position={center}
        />
      </GoogleMap>
    </LoadScript>
  );
};

MapComponent.propTypes = {
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }).isRequired
};

export default memo(MapComponent);
