import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useProfile } from '@lib/profile';

const ReadyToBuyOutlet = () => {
  const { profile } = useProfile();

  if (profile.canBuy()) {
    <Navigate to="/marketplace/presale" />;
  }

  return <Outlet />;
};

export default ReadyToBuyOutlet;
