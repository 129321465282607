class State {
  constructor() {
    this.store = localStorage;
  }

  set(name, payload) {
    this.store.setItem(name, payload);
  }

  get(name) {
    return this.store.getItem(name);
  }

  remove(name) {
    return this.store.removeItem(name);
  }

  clear() {
    this.store.clear();
  }
}

export default new State();
