import styled from 'styled-components';

import { flexAsRow, grid } from '@components/Styled/mixins';

const BackButton = styled.button`
  ${flexAsRow(1)};

  align-items: center;
  cursor: pointer;
  border-style: none;
  background: none;
  color: ${({ theme }) => theme.palette.grayG};
`;

const NavBarWrapper = styled.div`
  ${grid()};

  justify-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    justify-items: normal;
  }
`;

export { BackButton, NavBarWrapper };
