import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Counter } from '@components';
import { Text, Headline1 } from '@components/Styled';

import { DiscountPercent } from './DiscountBrickPrice.style';

const DiscountBrickPrice = ({ presalePrice, retailPrice }) => (
  <Fragment>
    <Text fontWeight="medium">
      Descuento por preventa
    </Text>

    <div>
      <DiscountPercent>
        <Text color="white">
          {presalePrice.discountPercent}
        </Text>
      </DiscountPercent>

      <Text>{retailPrice}</Text>
    </div>

    <Headline1>
      {presalePrice.pricePerBrick}
    </Headline1>

    <Text>por Ladrillo</Text>

    <Counter timestamp={presalePrice.expiresAt} row />
  </Fragment>
);

DiscountBrickPrice.propTypes = {
  retailPrice: PropTypes.string.isRequired,
  presalePrice: PropTypes.shape({
    discountPercent: PropTypes.string,
    pricePerBrick: PropTypes.string,
    expiresAt: PropTypes.string
  }).isRequired
};

export default DiscountBrickPrice;
