/* eslint-disable indent */
import styled from 'styled-components';

import { gradientBlueAndPurple } from '@components/Styled/mixins';

const MobileMenuWrapper = styled.section`
  height: 100vh;
  width: 80%;
  top: 0;
  bottom: 0;
  left: -100%;
  right: 0;
  position: absolute;
  z-index: 999;
  background-color: #fdfdfd;
  display: block;
  transition: all 0.3s;
  transition-delay: 0.1s;
  overflow: scroll;

  * {
    box-sizing: border-box;
  }

  > header {
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  > nav {
    ul {
      li {
        a, [class*="nav-item-help"] {
          flex-direction: row;
          justify-content: flex-start;
          font-size: 14px;

          img {
            margin: 0 10px 0 20px;
          }
        }
      }
    }

    ${({ noAccountInfo }) => {
    if (noAccountInfo) return 'top: 85px;';

    return '';
  }}
  }

  > footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      margin-right: 20px;
    }

    ${({ enrolled }) => {
    if (enrolled) return 'bottom: -35px;';

    return '';
  }}
  }

  ${({ open }) => {
    if (open) return 'left: -100%;';

    return '';
  }};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    ${({ open }) => {
    if (open) {
      return `
        position: fixed;
        left: 0;
        right: 15%;
        height: 100vh;
        width: 80%;
        top: 0;
        bottom: 0;
        z-index: 999;
        background-color: #fdfdfd;
        display: block;
        transition: all 0.3s;
        transition-delay: 0.1s;
        overflow: scroll;
      `;
    }

    return '';
  }}}
`;

const AccountInfo = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;

  li {
    height: 63px;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:first-child {
      border-top: 1px solid #9b9b9b33;
    }

    &:last-child {
      border-bottom: 1px solid #9b9b9b33;
    }

    span {
      &:first-child {
        color: #9b9b9b;
        font-size: 13px;
      }

      &:last-child {
        font-family: 'Roboto';
      }
    }
  }
`;

const AccountValue = styled.span`
  color: #034D8C;
`;

const AviableAmount = styled.span`
  color: rgb(58, 179, 152);
`;

const MobileMenuUpgradeAccountButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${gradientBlueAndPurple};
  padding: ${({ theme }) => theme.spacing(1.5)};
  position: relative;
  gap: ${({ theme }) => theme.spacing(1.5)};

  > button {
    margin: 0;
    color: ${({ theme }) => theme.palette.grayH};
    padding: 0;
    position: absolute;
    padding-left: ${({ theme }) => theme.spacing(6)};
    text-align: start;
    width: 100%;
    height: 100%;
    display: block;

    &:hover {
      background: none;
    }

    > span {
      position: relative;
      width: min-content;

      &::before, ::after {
        background-color: ${({ theme }) => theme.palette.warning.light};
        border-radius: ${({ theme }) => theme.borderRadius(4)};
        content: '';
        display: block;
        height: ${({ theme }) => theme.spacing(1)};
        position: absolute;
        right: -${({ theme }) => theme.spacing(1.5)};
        top: -${({ theme }) => theme.spacing(1)};
        width: ${({ theme }) => theme.spacing(1)};
      }

      ::after {
        animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
      }

      @keyframes ping {
        75%, 100% {
          opacity: 0;
          transform: scale(2);
        }
      }
    }
  }
`;

export {
  AccountInfo,
  AccountValue,
  AviableAmount,
  MobileMenuUpgradeAccountButtonWrapper,
  MobileMenuWrapper
};
