import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, Outlet } from 'react-router-dom';

import { useProfile } from '@lib/profile';
import { scrollTop } from '@utils';

import CompleteEnrollmentBanner from '../CompleteEnrollmentBanner';
import BlockedAccountBanner from '../BlockedAccountBanner';
import Footer from '../Footer';
import Header from '../Header';
import MobileMenu from '../../views/Marketplace/components/MobileMenu';
import SideNav from '../../views/Marketplace/components/SideNav';
import withRouter from '../../hoc/withRouter';

import {
  AppLayoutWrapper,
  MainContainer,
  MobileOverlay,
  ScrollContent
} from './AppLayout.style';

const AppLayout = () => {
  const {
    profile,
    legacyProfile
  } = useProfile();
  const location = useLocation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { pathname: path } = location;
  const { lockCode = '', isLegacy } = legacyProfile;

  const isEmptyProfile = !(Object.keys(profile).length > 0);

  const handleMenuClick = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const changeActiveTab = () => setIsMobileMenuOpen(false);

  useEffect(() => {
    scrollTop(0);
  }, [location]);

  return (
    <AppLayoutWrapper id="app-container">
      <BlockedAccountBanner lockCode={lockCode} />

      <Header onMenuClick={handleMenuClick} />

      <MainContainer id="main-container">
        <MobileOverlay id="mobile-overlay" isOpen={isMobileMenuOpen} />

        <MobileMenu
          activeTab={path}
          isOpen={isMobileMenuOpen}
          onNavClick={changeActiveTab}
          onOutsideClick={handleMenuClick}
          isLegacyAndLegalTrader={isLegacy}
          isEmptyTrader={isEmptyProfile}
          showUpgradeAccountButton={profile.status.isRestricted()
            || profile.status.isUpgradingAccount()
            || profile.status.isCorrectingData()}
        />

        <SideNav
          activeTab={path}
          isLegacy={legacyProfile.isLegacy}
          isLegacyAndLegalTrader={isLegacy}
          isEmptyTrader={isEmptyProfile}
        />

        <ScrollContent id="scroll-content">
          {!profile.isDoneRegistering() && (
            <CompleteEnrollmentBanner />
          )}

          <div id="page-content"><Outlet /></div>

          <div id="footer-content"><Footer isMarketplace /></div>
        </ScrollContent>
      </MainContainer>
    </AppLayoutWrapper>
  );
};

export default withRouter(AppLayout);
