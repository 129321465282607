import APIError from '../APIError';
const INVESTOR_HAS_NO_PENDING_SIGN_REQUESTS = 'INVESTOR_HAS_NO_PENDING_SIGN_REQUESTS';
const INVESTOR_NOT_FOUND = 'INVESTOR_NOT_FOUND';
const NOT_READY_TO_SIGN_DOCUMENT = 'NOT_READY_TO_SIGN_DOCUMENT';
const COMMERCIAL_CONTRACT_VERSION_NOT_SUPPORTED = 'COMMERCIAL_CONTRACT_VERSION_NOT_SUPPORTED';
const VALIDATION_ERROR = 'VALIDATION_ERROR';
const PRIVACY_AGREEMENT_MUST_BE_ACCEPTED = 'PRIVACY_AGREEMENT_MUST_BE_ACCEPTED';
const TERMS_AND_CONDITIONS_MUST_BE_ACCEPTED = 'TERMS_AND_CONDITIONS_MUST_BE_ACCEPTED';
class SignRequestError extends APIError {
    constructor(code, details = null) {
        super(code, 'SignRequestError', details);
    }
    hasInvalidFormat() {
        return this._code === VALIDATION_ERROR
            || this._code === PRIVACY_AGREEMENT_MUST_BE_ACCEPTED
            || this._code === TERMS_AND_CONDITIONS_MUST_BE_ACCEPTED;
    }
    investorNotFound() {
        return this._code === INVESTOR_NOT_FOUND;
    }
    isNotReadyToSignDocument() {
        return this._code === NOT_READY_TO_SIGN_DOCUMENT;
    }
    isNotSupportedCommercialContractVersion() {
        return this._code === COMMERCIAL_CONTRACT_VERSION_NOT_SUPPORTED;
    }
    hasNoPendingSignRequests() {
        return this._code === INVESTOR_HAS_NO_PENDING_SIGN_REQUESTS;
    }
}
export default SignRequestError;
