import styled, { css } from 'styled-components';

import { flexAsColumn, flexAsRow } from '@components/Styled/mixins';
import { ActionText } from '@components';

const imagesSize = css`
  height: calc(50% - ${({ theme }) => theme.spacing(0.5)});
  width: calc(50% - ${({ theme }) => theme.spacing(0.5)});
`;

const GalleryWrapper = styled.div`
  ${flexAsRow(1)};

  height: ${({ theme }) => theme.spacing(45)};
  background-color: ${({ theme }) => theme.palette.white};
  position: relative;

  > div {
    ${({ numberOfImages }) => {
    if (numberOfImages > 1) {
      return css`
        width: 50%;

        > button > img {
          border-radius: ${({ theme }) => theme.spacing(0.5)};
        }
      `;
    }

    return css`width: 100%;`;
  }}}

  img {
    object-fit: cover;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    > div {
      width: 100%;

      > button > img {
        border-radius: unset;
      }
    }
  }
`;

const RemainingImageContainer = styled.div`
  ${flexAsColumn(1)};

  flex-wrap: wrap;
  position: relative;

  ${({ numberOfRemainingImages }) => {
    if (numberOfRemainingImages === 1) {
      return css`
        > button {
          height: 100%;
          width: 100%;
        }
      `;
    }

    if (numberOfRemainingImages === 2) {
      return css`
        > button {
          height: 100%;
          width: calc(50% - ${({ theme }) => theme.spacing(0.5)});
        }
      `;
    }

    if (numberOfRemainingImages === 3) {
      return css`
        > button {
          ${imagesSize};
        }

        > button:last-of-type {
          height: 100%;
        }
      `;
    }

    return css`
      > button {
        ${imagesSize};
      }
    `;
  }}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const RemainingImage = styled.button`
  padding: 0;
  cursor: pointer;
  border-style: none;
`;

const MainImage = styled(RemainingImage)`
  height: 100%;
  width: 100%;
`;

const ShowRemainingImagesDesktop = styled(ActionText)`
  ${flexAsColumn()};

  height: calc(50% - ${({ theme }) => theme.spacing(0.5)});
  width: calc(50% - ${({ theme }) => theme.spacing(0.5)});
  opacity: 0.8;
  position: absolute;

  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.toRem(36)};
  color: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  top: calc(50% + ${({ theme }) => theme.spacing(0.5)});
  left: calc(50% + ${({ theme }) => theme.spacing(0.5)});
  background-color: ${({ theme }) => theme.palette.grayJ};

  :hover {
    text-decoration: none;
  }
`;

const ShowRemainingImagesTablet = styled(ShowRemainingImagesDesktop)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(1)};
    height: ${({ theme }) => theme.spacing(6)};
    width: ${({ theme }) => theme.spacing(22)};
    left: unset;
    top: 0;
    right: 0;
    font-size: ${({ theme }) => theme.toRem(14)};
    border-radius: ${({ theme }) => `0 0 0 ${theme.spacing(1)}`};
    padding-left: ${({ theme }) => theme.spacing(4)};
  }
`;

export {
  GalleryWrapper,
  MainImage,
  RemainingImageContainer,
  RemainingImage,
  ShowRemainingImagesTablet,
  ShowRemainingImagesDesktop
};
