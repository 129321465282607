import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  Icon,
  Image,
  LegacyLink,
  Link
} from '@components';

import cienLadrillosSelectLogo from '@assets/images/100-ladrillos-select-white.svg';

import { BlinkingLight } from './NavList.style';

import './style.scss';

const NavItem = ({
  description,
  external,
  icon,
  iconDimensions,
  id,
  isActive,
  onClick,
  route
}) => {
  const styleName = cx({
    active: isActive && !external
  });
  const iconName = isActive ? `${icon}Active` : icon;

  if (external) {
    return (
      <li>
        <Link
          href={route}
          styleName="nav-item-help"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Icon
            name={iconName}
            height={iconDimensions.height}
            width={iconDimensions.width}
            alt={iconName}
          />
          <span>{description}</span>
        </Link>
      </li>
    );
  }

  return (
    <li id={id} styleName={styleName} key={id} onClick={onClick}>
      {description === 'Completar perfil' && (
        <BlinkingLight />
      )}
      <LegacyLink to={route}>
        {icon && (
          <Icon
            name={iconName}
            height={iconDimensions.height}
            width={iconDimensions.width}
            alt={iconName}
          />
        )}
        <span>{description}</span>
      </LegacyLink>
    </li>
  );
};

const NavList = ({
  items,
  activeTab,
  className,
  onClick,
  isLegacy,
  isLegacyAndLegalTrader,
  isEmptyTrader
}) => {
  if (isEmptyTrader) {
    const helpItem = items.find(item => item.id === 'help');
    const isActive = helpItem.route === activeTab && activeTab !== '/';

    return (
      <nav styleName="nav-list" className={className}>
        <ul>
          <NavItem {...helpItem} key={helpItem.route} isActive={isActive} onClick={onClick} />
        </ul>
      </nav>
    );
  }

  const isChildRoute = (navItemRoutes, currentRoute = '') => navItemRoutes.some(
    route => currentRoute === route
  );

  return (
    <nav styleName="nav-list" className={className}>
      <ul>
        {items.map(item => {
          const { childrenRoutes = [] } = item;
          const isChild = isChildRoute(childrenRoutes, activeTab);
          const isActive
            = (item.route === activeTab || isChild) && activeTab !== '/';

          if (item.id === 'enrollment' && isLegacyAndLegalTrader) {
            return null;
          }

          return (
            <NavItem {...item} key={item.route} isActive={isActive} onClick={onClick} />
          );
        })}
        {isLegacy && (
          <li id="select-version" styleName="select-version-btn" key="selectVersion">
            <LegacyLink to="https://www.100ladrillos.com/marketplace/sign_in" external>
              <Image src={cienLadrillosSelectLogo} alt="100 Ladrillos select Logo" />
            </LegacyLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

NavItem.defaultProps = {
  external: undefined,
  onClick: undefined,
  description: '',
  iconDimensions: {
    width: 32,
    height: 32
  }
};

NavItem.propTypes = {
  id: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  external: PropTypes.bool,
  iconDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  })
};

NavList.defaultProps = {
  onClick: undefined,
  className: '',
  isLegacy: false,
  isLegacyAndLegalTrader: false
};

NavList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  activeTab: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isLegacy: PropTypes.bool,
  isLegacyAndLegalTrader: PropTypes.bool,
  isEmptyTrader: PropTypes.bool.isRequired
};

export default NavList;
