import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@components';
import icBricksUnderBrick from '@assets/icons/bricks-under-brick.svg';

import { TagWrapper, PropertyBricksCounter } from './InvestorBricksCounter.style';

const InvestorBricksCounter = ({ count }) => (
  <TagWrapper>
    <Text>Tienes</Text>
    <PropertyBricksCounter>{count?.toLocaleString()}</PropertyBricksCounter>
    <img src={icBricksUnderBrick} alt="bricks-icon" />
  </TagWrapper>
);

InvestorBricksCounter.propTypes = {
  count: PropTypes.number.isRequired
};

export default InvestorBricksCounter;
