/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import shortid from 'shortid';
import { useNavigate } from 'react-router-dom';

import CompleteProfile from '@views/Marketplace/components/CompleteProfile';
import {
  Button,
  Icon,
  NotEnoughFundsModal,
  RenderIf,
  TextField
} from '@components';
import { useUpgradingAccount } from '@hooks';
import { AccountLockCodes } from '@types';
import { useProfile } from '@lib/profile';

import QuotingTable from '../../../QuotingTable';

import errorMap from './errorMap';
import './style.scss';

const Quoter = props => {
  const {
    availableBricks,
    isLegacyAndLegalTrader,
    maximumBricksToBuy,
    minimumBricksToBuy,
    onChange,
    profileCompleted,
    propertyId,
    quote,
    trader
  } = props;

  const navigate = useNavigate();
  const { profile, investorBalance } = useProfile();
  const { renderInvestorPlansModal } = useUpgradingAccount();
  const [isInsufficientFundsModalVisible, setIsInsufficientFundsModalVisible] = useState(false);
  const { bricks = '', error } = quote || {};
  const safeBricks = Number(bricks);
  const displayTable = !Number.isNaN(safeBricks) && safeBricks >= minimumBricksToBuy && !error;
  const displayMinimumMessage = safeBricks > 0 && safeBricks < minimumBricksToBuy;
  const isQuotationLoading = quote.loading;
  const isAlmostReadyToBuy = !profile.canBuy()
    && profileCompleted
    && !profile.status.isReadyToSignRiskForm();

  const isBrickQuantityDisabled = () => {
    const { lockCode = '' } = trader;
    const { isSoftLockCode, isShadowLockCode } = new AccountLockCodes();

    return availableBricks < minimumBricksToBuy
      || availableBricks < 1
      || isSoftLockCode(lockCode)
      || isShadowLockCode(lockCode);
  };

  const handleClick = () => {
    if (profile.accountLevel.isFrozen() || profile.pendingSignRequests.hasRequests()) {
      renderInvestorPlansModal();

      return;
    }

    if (quote.totalPaid > investorBalance?.available || profile.status.isReadyToSignRiskForm()) {
      setIsInsufficientFundsModalVisible(true);
      return;
    }

    navigate(`/marketplace/presale/buy?bricks=${bricks}&propertyId=${propertyId}`);
  };

  const handleChange = async event => {
    const { target: { value } } = event;

    onChange(propertyId, value);
  };

  const loadingOverlayStyle = cx({
    'loading-overlay': true,
    active: false
  });

  const textInputStyle = cx({
    'text-input': true,
    column: !displayTable,
    row: displayTable,
    error: Boolean(error || displayMinimumMessage)
  });

  const tableContainerStyle = cx({
    'table-container': true,
    active: displayTable
  });

  const tableContainerWrapper = cx({
    'table-container-wrapper': true,
    active: displayTable
  });

  const notesContainerStyle = cx({
    'notes-container': true,
    active: !displayTable
  });

  const errorMessageStyle = cx({
    'error-msg': true,
    active: Boolean(error)
  });

  const errorProps = {};

  if (error) {
    errorProps.maximumBricksToBuy = maximumBricksToBuy;
    errorProps.minimumBricksToBuy = minimumBricksToBuy;
  }

  return (
    <div styleName="quoter">
      <div styleName={loadingOverlayStyle} />
      <div styleName={textInputStyle}>
        <TextField
          id={`brick-quantity-${shortid.generate()}`}
          tid="brick-quantity"
          type="text"
          label="# Ladrillos"
          aria-label="Número de Ladrillos"
          value={bricks.toString()}
          onChange={handleChange}
          placeholder={maximumBricksToBuy.toString()}
          styleName="input"
          disabled={isBrickQuantityDisabled()}
          required
          inputProps={{
            autoComplete: 'off',
            pattern: '\d*'
          }}
        />
      </div>
      <p id="error-message" tid="error-message" styleName={errorMessageStyle}>
        {error && errorMap(error, errorProps)}
      </p>
      <div styleName={notesContainerStyle}>
        <small tid="minimum-bricks-to-buy">
          {`* Compra mínima de Ladrillos: ${minimumBricksToBuy}`}
        </small>
        <small tid="maximum-bricks-to-buy">
          {`* Tu compra máxima de Ladrillos: ${maximumBricksToBuy}`}
        </small>
      </div>
      <div styleName={tableContainerWrapper}>
        <div id="table-container" tid="table-container" styleName={tableContainerStyle}>
          <QuotingTable quote={quote} trader={trader} />
          <RenderIf condition={profile.canBuy() || profile.status.isReadyToSignRiskForm()}>
            <Button
              id="button-buy"
              tid="button-buy"
              disabled={isQuotationLoading}
              onClick={handleClick}
              size="small"
            >
              Comprar
            </Button>
          </RenderIf>
          <RenderIf condition={!profile.canBuy() && isLegacyAndLegalTrader}>
            <div styleName="info">
              <div>
                <Icon name="icWarningBlue" alt="warning" />
                <small>Por el momento nuestros Ladrillos no</small>
              </div>
              <small>
                están disponibles para
                {' '}
                <strong>personas morales</strong>
              </small>
            </div>
          </RenderIf>
          <RenderIf condition={isAlmostReadyToBuy}>
            <div styleName="info">
              <small>Ya casi puedes comprar, estamos procesando tu información</small>
            </div>
          </RenderIf>
          <RenderIf condition={!profile.canBuy() && !profileCompleted && !isLegacyAndLegalTrader}>
            <div tid="incomplete-profile">
              <CompleteProfile />
            </div>
          </RenderIf>
        </div>
      </div>

      <NotEnoughFundsModal
        availableAmount={investorBalance?.available}
        handleClose={() => setIsInsufficientFundsModalVisible(false)}
        isOpen={isInsufficientFundsModalVisible}
      />
    </div>
  );
};

Quoter.defaultProps = {
  propertyId: '',
  quote: {},
  trader: {}
};

Quoter.propTypes = {
  availableBricks: PropTypes.number.isRequired,
  isLegacyAndLegalTrader: PropTypes.bool.isRequired,
  maximumBricksToBuy: PropTypes.number.isRequired,
  minimumBricksToBuy: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  profileCompleted: PropTypes.bool.isRequired,
  propertyId: PropTypes.string,
  quote: PropTypes.shape(),
  trader: PropTypes.shape({
    canBuy: PropTypes.bool,
    lockCode: PropTypes.string
  })
};

export default Quoter;
