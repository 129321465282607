import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingOverlay } from '@components';
import { Paragraph } from '@components/Styled';
import { useAPI, useIsMobile, useRequest } from '@hooks';
import { useErrorReporter } from '@lib/errorReporter';
import CategoryNavBar from './CategoryNavBar';
import ImageGallery from './ImageGallery';
import categoryTabs from './categoryTabs';
import { CategorizedGalleryWrapper, Tab, TabWrapper } from './CategorizedPropertyDetailGallery.style';
const DEFAULT_INITIAL_TAB = 'allImages';
const CategorizedPropertyDetailGallery = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(DEFAULT_INITIAL_TAB);
    const [propertyImages, setPropertyImages] = useState();
    const isMediumResolution = useIsMobile();
    const { property } = useAPI();
    const { propertyId, propertyType } = useParams();
    const errorReporter = useErrorReporter();
    const propertyTypeRequest = {
        presale: property.crowdfunding.getCrowdfundingProperty,
        onsale: property.secondaryMarketProperty.getSecondaryMarketProperty
    };
    const propertyRequest = propertyType && propertyTypeRequest[propertyType];
    const [propertyResponse, isLoadingProperty, propertyError, getProperty] = useRequest(propertyRequest);
    const categoryTabsData = categoryTabs(propertyResponse?.photos);
    const handleTab = (tab, images) => {
        setActiveTab(tab);
        setPropertyImages(images);
    };
    const handleGoToPropertyDetail = () => navigate(`/marketplace/${propertyType}/property/${propertyId}`);
    useEffect(() => {
        getProperty(propertyId);
    }, []);
    useEffect(() => {
        if (propertyResponse) {
            setPropertyImages(propertyResponse.photos);
        }
    }, [propertyResponse]);
    useEffect(() => {
        if (propertyError) {
            errorReporter.critical(propertyError);
            handleGoToPropertyDetail();
        }
    }, [propertyError]);
    return (_jsxs(Fragment, { children: [(isLoadingProperty && !isMediumResolution) && (_jsx(LoadingOverlay, {})), !isLoadingProperty && (_jsxs(CategorizedGalleryWrapper, { children: [_jsx(CategoryNavBar, { propertyName: propertyResponse?.name, handleGoToPropertyDetail: handleGoToPropertyDetail }), _jsx(TabWrapper, { children: categoryTabsData.map(({ tab, name, images }) => (_jsx(Tab, { isActive: activeTab === tab, onClick: () => handleTab(tab, images), children: _jsx(Paragraph, { children: name }) }, tab))) }), _jsx(ImageGallery, { propertyImages: propertyImages })] }))] }));
};
export default CategorizedPropertyDetailGallery;
