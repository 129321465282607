import React, { useState } from 'react';

import { useInsightsService } from '@lib/insightsService';
import { RenderIf } from '@components';

import LoginLayout from './components/LoginLayout';
import { StepOne, StepTwo } from './Steps';

const Login = () => {
  const { authenticationEvent, reportInsight, setInvestorSession } = useInsightsService();
  const [step, setStep] = useState('email');
  const [email, setEmail] = useState('');
  const [antiphishingData, setAntiphishingData] = useState({});

  const handleStepOneSuccess = (userEmail, antiphishingResponse) => {
    setStep('password');
    setEmail(userEmail);
    setAntiphishingData(antiphishingResponse);
  };

  const handleSuccessfulLogin = () => {
    setInvestorSession();
    reportInsight(authenticationEvent.successful);
  };

  return (
    <LoginLayout>
      <RenderIf condition={step === 'email'}>
        <StepOne onSuccess={handleStepOneSuccess} />
      </RenderIf>
      <RenderIf condition={step === 'password'}>
        <StepTwo
          email={email}
          antiphishingData={antiphishingData}
          onCancel={() => setStep('email')}
          onSuccess={handleSuccessfulLogin}
        />
      </RenderIf>
    </LoginLayout>
  );
};

export default Login;
