import { BearerToken, APIError } from '../../api';
import HundredBricksAPI from '../HundredBricksAPI';
import Antiphishing from './Antiphishing';
import AntiphishingError from './AntiphishingError';
import AuthenticationPingError from './AuthenticationPingError';
import DistanceExceededFromLastConnection from './DistanceExceededFromLastConnection';
import EmailNotGivenByTheInvestor from './EmailNotGivenByTheInvestor';
import Geolocation from './Geolocation';
import InvalidCredentials from './InvalidCredentials';
import InvestorHasAlreadyAnActiveSession from './InvestorHasAlreadyAnActiveSession';
import InvestorIsNotRegistered from './InvestorIsNotRegistered';
import LoginError from './LoginError';
import LogoutError from './LogoutError';
import ResendUnusualAccessError from './ResendUnusualAccessError';
import SendUnusualAccessError from './SendUnusualAccessError';
import Session from './Session';
import TraderIsNotBlocked from './TraderIsNotBlocked';
class AuthenticationAPI extends HundredBricksAPI {
    async antiphishing(email) {
        try {
            const { data } = await this.client.get('v2/profile/antiphishing', { params: { email } });
            return new Antiphishing(data.fullName, data.image, data.phrase);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'MISSING_EMAIL':
                        throw new EmailNotGivenByTheInvestor({ cause: error });
                    case 'TRADER_NOT_FOUND':
                        throw new InvestorIsNotRegistered(email, { cause: error });
                    default:
                        throw new AntiphishingError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new AntiphishingError('Unexpected antiphishing error', { cause: error });
            }
            else {
                throw new AntiphishingError(`Unexpected antiphishing error: '${error}'`);
            }
        }
    }
    async login(email, password) {
        try {
            const { data } = await this.client.post('/v1/login', { email, password });
            const token = new BearerToken(data.token);
            const geolocation = new Geolocation(data.geolocalization);
            return new Session(token, geolocation);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INCORRECT_PASSWORD':
                        throw new InvalidCredentials({ cause: error });
                    case 'INVALID_USER_CONNECTION':
                        throw new DistanceExceededFromLastConnection({ cause: error });
                    case 'TRADER_ALREADY_HAS_ACTIVE_SESSION':
                        throw new InvestorHasAlreadyAnActiveSession({ cause: error });
                    case 'UNAUTHORIZED':
                        throw new InvestorIsNotRegistered(email, { cause: error });
                    default:
                        throw new LoginError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new LoginError('Unexpected login error', { cause: error });
            }
            else {
                throw new LoginError(`Unexpected login error: '${error}'`);
            }
        }
    }
    async logout() {
        try {
            await this.client.post('/v1/logout');
        }
        catch (error) {
            if (error instanceof APIError) {
                if (error.code === 'UNAUTHORIZED')
                    return;
                throw new LogoutError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new LogoutError('Unexpected logout error', { cause: error });
            }
            else {
                throw new LogoutError(`Unexpected logout error: '${error}'`);
            }
        }
    }
    async ping() {
        try {
            await this.client.get('/v1/traders/me');
        }
        catch (error) {
            if (error instanceof Error) {
                throw new AuthenticationPingError({ cause: error });
            }
            else {
                throw new AuthenticationPingError(`Unexpected ping error: '${error}'`);
            }
        }
    }
    /**
     * Unblock investor when this is blocked 'cause unusual access
     *
     * @param code
     * @param traderId
     * @param password
     *
     * @returns Session
     */
    async unblockInvestor(code, traderId, password) {
        try {
            const { data } = await this.client.post('/v1/unblockTrader', { code, traderId, password });
            const token = new BearerToken(data.token);
            const geolocation = new Geolocation(data.geolocalization);
            return new Session(token, geolocation);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INCORRECT_PASSWORD':
                        throw new InvalidCredentials({ cause: error });
                    case 'TRADER_ALREADY_HAS_ACTIVE_SESSION':
                        throw new InvestorHasAlreadyAnActiveSession({ cause: error });
                    case 'UNAUTHORIZED':
                        throw new InvestorIsNotRegistered(traderId, { cause: error });
                    default:
                        throw new LoginError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new LoginError('Unexpected unblock investor error', { cause: error });
            }
            else {
                // We are conserving the login error because unblock Investor returns a session.
                throw new LoginError(`Unexpected unblock investor error: '${error}'`);
            }
        }
    }
    /**
     * Send investors unlock code to unblock the investor's account
     *
     * @param traderId
     * @param channel
     *
     * @returns empty object {}
     */
    async sendUnusualCodeAccess(traderId, channel) {
        try {
            const { data } = await this.client.post('/v1/unblockTrader/sendUnblockToken', { traderId, channel });
            return data;
        }
        catch (error) {
            if (error instanceof APIError) {
                if (error.code === 'TRADER_NOT_BLOCKED') {
                    throw new TraderIsNotBlocked(traderId, { cause: error });
                }
                else {
                    throw new SendUnusualAccessError({ cause: error });
                }
            }
            if (error instanceof Error) {
                throw new SendUnusualAccessError('Unexpected while sending unusual access code', { cause: error });
            }
            else {
                throw new SendUnusualAccessError(`Unexpected while sending unusual access code: '${error}'`);
            }
        }
    }
    /**
     * Resend investors unlock code to unblock the investor's account
     *
     * @param email
     *
     * @returns empty object {}
     */
    async resendUnusualCodeAccess(email) {
        try {
            const { data } = await this.client.post('/v1/traders/resendUnusualAccessEmail', { email });
            return data;
        }
        catch (error) {
            if (error instanceof APIError) {
                if (error.code === 'TRADER_NOT_BLOCKED') {
                    throw new TraderIsNotBlocked(email, { cause: error });
                }
                throw new ResendUnusualAccessError('Unexpected while resending unusual access code', { cause: error });
            }
            else if (error instanceof Error) {
                throw new ResendUnusualAccessError('Unexpected while resending unusual access code', { cause: error });
            }
            else {
                throw new ResendUnusualAccessError(`Unexpected while resending unusual access code: '${error}'`);
            }
        }
    }
}
export default AuthenticationAPI;
