import HundredBricksAPI from '../HundredBricksAPI';
import CURPAPI from './Documents/CURPAPI';
import DemographicsAPI from './Demographics/DemographicsAPI';
import DocumentsAPI from './Documents/DocumentsAPI';
import EmailAPI from './Email/EmailAPI';
import InvoicingAPI from './Invoicing/InvoicingAPI';
import PhoneAPI from './Phone/PhoneAPI';
import ResourcesAPI from './Resources/ResourcesAPI';
class ProfileAPI extends HundredBricksAPI {
    curpAPI;
    demographicsAPI;
    documentsAPI;
    emailAPI;
    invoicingAPI;
    phoneAPI;
    resourcesAPI;
    constructor(client) {
        super(client);
        this.curpAPI = new CURPAPI(this.client);
        this.demographicsAPI = new DemographicsAPI(this.client);
        this.documentsAPI = new DocumentsAPI(this.client);
        this.emailAPI = new EmailAPI(this.client);
        this.invoicingAPI = new InvoicingAPI(this.client);
        this.phoneAPI = new PhoneAPI(this.client);
        this.resourcesAPI = new ResourcesAPI(this.client);
    }
    async resendEmailVerificationToken() {
        return this.emailAPI.resendToken();
    }
    async verifyEmail(code) {
        return this.emailAPI.verify(code);
    }
    async registerPhone(number, country) {
        return this.phoneAPI.register(number, country);
    }
    async resendPhoneVerificationToken(channel) {
        return this.phoneAPI.sendToken(channel);
    }
    async verifyPhone(code) {
        return this.phoneAPI.verify(code);
    }
    async registerFullname(name, firstLastName, secondLastName) {
        return this.demographicsAPI.registerFullName(name, firstLastName, secondLastName);
    }
    async registerPersonalData(birthCountry, birthdate, birthState, gender, nationality) {
        return this.demographicsAPI.registerPersonalData(birthCountry, birthdate, birthState, gender, nationality);
    }
    async suggestedCURP() {
        return this.curpAPI.suggested();
    }
    async registerCURP(curp) {
        return this.curpAPI.register(curp);
    }
    async updateCURP(curp) {
        return this.curpAPI.update(curp);
    }
    async registerJobTitle(jobTitle) {
        return this.resourcesAPI.registerJobTitle(jobTitle);
    }
    async signRiskForm() {
        return this.documentsAPI.signRiskForm();
    }
    async registerResourcesOwnership(isRealOwner, hasOwnResources, realOwnerName, realOwnerBirthdate) {
        return this.resourcesAPI.registerOwnership(isRealOwner, hasOwnResources, realOwnerName, realOwnerBirthdate);
    }
    async enableAutomaticInvoicing() {
        return this.invoicingAPI.enableAutomaticInvoicing();
    }
    async validateFiscalData() {
        return this.invoicingAPI.validateFiscalData();
    }
}
export default ProfileAPI;
