import styled from 'styled-components';

const BlinkingLight = styled.div`
  &::before, &::after {
    background-color: ${({ theme }) => theme.palette.warning.light};
    border-radius: ${({ theme }) => theme.borderRadius(4)};
    content: '';
    display: block;
    height: ${({ theme }) => theme.spacing(1)};
    position: absolute;
    left: ${({ theme }) => theme.spacing(20)};
    top: ${({ theme }) => theme.spacing(2)};
    width: ${({ theme }) => theme.spacing(1)};

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
      left: unset;
      top: ${({ theme }) => theme.spacing(1)};
      right: ${({ theme }) => theme.spacing(2)};
    }
  }

  &::after {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  @keyframes ping {
    75%, 100% {
      opacity: 0;
      transform: scale(2);
    }
  }
`;

export { BlinkingLight };
