import React from 'react';

import { LegacyLink, Icon } from '@components';

import './style.scss';

const NotFound = () => (
  <div styleName="container">
    <p styleName="title">404</p>
    <p styleName="message">Upss.. PÁGINA NO ENCONTRADA</p>
    <p styleName="text">
      Lo sentimos, no puedes acceder a la página que estás
      buscando.
      <br />
      <LegacyLink to="/marketplace">Ir a inicio</LegacyLink>
    </p>
    <Icon name="icMaclovio" alt="No encontrado" styleName="image" />
  </div>
);

export default NotFound;
