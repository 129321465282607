import { createAPIClient } from '../api';
import AuthenticationAPI from './authentication';
import EnrollmentAPI from './enrollment';
import InvestmentsAPI from './investments/InvestmentsAPI';
import PaymentPlansAPI from './trading';
import PetitionerAPI from './property/petitioner';
import ProfileAPI from './profile/ProfileAPI';
import { LocalStorageTokenStore } from './session';
import { OnSalePropertyAPI, PresalePropertyAPI } from './property';
const useHundredBricksAPI = () => {
    const token = (() => {
        try {
            return LocalStorageTokenStore.find();
        }
        catch (error) {
            return undefined;
        }
    })();
    const marketplaceClient = createAPIClient(`${process.env.MARKETPLACE_BASE_URL}`, token);
    const tradingClient = createAPIClient(`${process.env.TRADING_BASE_URL}`, token);
    return {
        authentication: new AuthenticationAPI(marketplaceClient),
        enrollment: new EnrollmentAPI(marketplaceClient),
        investments: new InvestmentsAPI(marketplaceClient),
        property: {
            petitioner: new PetitionerAPI(marketplaceClient),
            presale: new PresalePropertyAPI(marketplaceClient),
            onSale: new OnSalePropertyAPI(marketplaceClient)
        },
        profile: new ProfileAPI(marketplaceClient),
        trading: {
            paymentPlans: new PaymentPlansAPI(tradingClient)
        }
    };
};
export default useHundredBricksAPI;
