import { createContext } from 'react';
const ProfileContext = createContext({
    profile: undefined,
    investorBalance: undefined,
    legacyProfile: undefined,
    reloadProfileData: async () => {
        throw new Error('reloadProfileData implementation not available');
    }
});
export default ProfileContext;
