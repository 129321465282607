import React from 'react';
import PropTypes from 'prop-types';

import TableColumn from '../TableColumn';

const TableHeader = props => {
  const { children } = props;

  return (
    <TableColumn {...props} isHeaderColumn>
      {children}
    </TableColumn>
  );
};

TableHeader.defaultProps = {
  children: [],
  size: '',
  type: 'text'
};

TableHeader.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  size: PropTypes.string,
  type: PropTypes.string
};

export default TableHeader;
