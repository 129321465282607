import { InvoicingError, NetworkError } from '../errors';
import Profile from '../entities/Profile';

import GatewayAPI from './GatewayAPI';

class InvoicingAPI extends GatewayAPI {
  async handleAutomaticInvoicing(payload) {
    try {
      const { automaticInvoicing } = payload;

      if (automaticInvoicing) {
        return this._enableAutomaticInvoicing();
      }

      return this._disableAutomaticInvoicing();
    } catch (error) {
      return this._handleError(error);
    }
  }

  async _enableAutomaticInvoicing() {
    try {
      const { data } = await this._client.post('/profile/automaticInvoicing/enable');

      return new Profile(data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async _disableAutomaticInvoicing() {
    try {
      const { data } = await this._client.post('/profile/automaticInvoicing/disable');

      return new Profile(data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getFiscalRegime() {
    try {
      const { data } = await this._client.get('/fiscalRegime');

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async registerFiscalRegime(payload) {
    try {
      const { data } = await this._client.post('/fiscalRegime', payload);

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async registerFiscalRegimeAsResico(payload, waitingForProof = false) {
    try {
      if (!waitingForProof) {
        await this.registerFiscalRegime({ name: 'Régimen Simplificado de Confianza' });
      }

      return this._uploadFiscalRegimeProof(payload);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getFiscalZipCode() {
    try {
      const { data } = await this._client.get('/invoicing/fiscalZipCode');

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async registerFiscalZipCode(payload) {
    try {
      const { data } = await this._client.post('/invoicing/fiscalZipCode', payload);

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async updateTaxId(taxId) {
    try {
      const { data } = await this._client.put('/profile/taxId', { taxId });

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async _uploadFiscalRegimeProof(payload) {
    try {
      const { data } = await this._client.post(
        '/fiscalRegime/proofDocument',
        payload,
        { 'Content-Type': 'multipart/form-data' }
      );

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError = error => {
    if (!error.response) {
      throw new NetworkError();
    }

    const errorCode = error.response.data.code;

    throw new InvoicingError(errorCode);
  };
}

export default InvoicingAPI;
