import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { EnrollmentProfile } from '@api/entities/Enrollment';
import { useAuth } from '@lib/authentication';
import { useProfile } from '@lib/profile';
import { useUpgradingAccount } from '@hooks';
const AuthenticatedOutlet = () => {
    const location = useLocation();
    const { isAuthenticated } = useAuth();
    const { profile, investorBalance } = useProfile();
    const { renderInvestorPlansModal } = useUpgradingAccount();
    const navigate = useNavigate();
    const navigateToInvestorMainPage = () => {
        if (location.state?.from?.pathname) {
            navigate(location.state?.from.pathname);
        }
        else if (!investorBalance?.hasInvested()) {
            navigate('/onboarding');
        }
        else {
            navigate('/marketplace');
        }
    };
    const navigateToBasicUserActivity = (investorProfile) => {
        if (investorProfile.status?.isCorrectingData()) {
            navigate('/perfil');
        }
        else if (investorProfile.hasRejectedDocs()) {
            navigate('/perfil/documentos-rechazados');
        }
        else {
            if (investorProfile.accountLevel?.isFrozen()) {
                renderInvestorPlansModal();
            }
            navigateToInvestorMainPage();
        }
    };
    const navigateToLimitlessUserActivity = (investorProfile) => {
        if (investorProfile.pendingCorrectionRequests?.hasW8ContractRequest()) {
            navigate('/perfil/w8-contract');
        }
        else if (!investorProfile.hasTransactionalProfile()) {
            navigate('/update-transactional-profile');
        }
        else {
            navigateToInvestorMainPage();
        }
    };
    const navigateOnLogin = (investorProfile) => {
        if (investorProfile.pendingSignRequests?.hasRequests()) {
            renderInvestorPlansModal();
        }
        if (investorProfile.isLegalPerson()) {
            navigate('/marketplace');
        }
        else if (investorProfile.accountLevel?.isPendingToGetLevel()) {
            navigate('/onboarding');
        }
        else if (investorProfile.annualUpdateLog?.isReadyToUpdate()) {
            navigate('/perfil');
        }
        else if (investorProfile.accountLevel?.isBasic()) {
            navigateToBasicUserActivity(investorProfile);
        }
        else {
            navigateToLimitlessUserActivity(investorProfile);
        }
    };
    useEffect(() => {
        // TODO: should remove unknown type when profile context si merged to typescript
        if (isAuthenticated && profile instanceof EnrollmentProfile) {
            navigateOnLogin(profile);
        }
    }, [isAuthenticated, profile]);
    return _jsx(Outlet, {});
};
export default AuthenticatedOutlet;
