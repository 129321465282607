const calculatePages = totalPages => {
  const pages = [];
  
  for(let page = 1; page <= totalPages; page++) {
    pages.push(page)
  }

  return pages;
}

const setPagesToShow = (currentPage, totalPages = 0) => {
  let pagesToShow = [currentPage];

  const pages = calculatePages(totalPages);
  const firstPage = pages[0];
  const lastPage = pages[pages.length - 1];
  const isLastPage = currentPage === lastPage;
  const isFirstPage = currentPage === firstPage;
  const areTwoPages = pages.length === 2;
  const areMoreThanTwoPages = pages.length > 2;

  if (areTwoPages) {
    if (isFirstPage) {
      pagesToShow = [currentPage, currentPage + 1]
    }

    if (isLastPage) {
      pagesToShow = [currentPage - 1, currentPage]
    };
  }

  if (areMoreThanTwoPages) {
    if (isFirstPage) {
      pagesToShow = [currentPage, currentPage + 1, currentPage + 2]
    }

    if (isLastPage) {
      pagesToShow = [currentPage - 2, currentPage - 1, currentPage]
    }

    if(!isFirstPage && !isLastPage) {
      pagesToShow = [currentPage - 1, currentPage, currentPage + 1]
    }
  }

  return pagesToShow;
};

export default setPagesToShow;
