const errorMap = (code, errorProps) => {
  const { maximumBricksToBuy, minimumBricksToBuy } = errorProps;

  const errors = {
    BRICK_NUMBER_EXCEEDS_AVAILABLE: '¡No tenemos tantos Ladrillos!',
    INVESTMENT_LIMIT_AMOUNT_EXCEEDED: `Tu máximo de compra para esta propiedad es de ${maximumBricksToBuy} Ladrillos.`,
    BRICK_NUMBER_UNDER_MINIMUM: `El mínimo de compra para esta propiedad es de ${minimumBricksToBuy} Ladrillos.`,
    BRICK_NUMBER_EXCEEDS_MAXIMUM: `Tu máximo de compra para esta propiedad es de ${maximumBricksToBuy} Ladrillos.`,
  };

  return errors[code] || 'Error desconocido.';
};

export default errorMap;
