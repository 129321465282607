import { useContext } from 'react';
import PaymentPlansContext from './PaymentPlansContext';
const useProfile = () => {
    const context = useContext(PaymentPlansContext);
    if (!context) {
        throw new Error('usePaymentPlans must be used within a PaymentPlansProvider');
    }
    return context;
};
export default useProfile;
