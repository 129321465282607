import styled from 'styled-components';

const LayoutWrapper = styled.div`
  display: grid;
  min-height: 100vh;

  grid-template-areas: "navbar" "content" "supportContainer";
  grid-template-rows: ${({ theme }) => theme.spacing(6)} 1fr;
  grid-template-columns: 1fr;

  overflow: hidden;
  position: relative;

  background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-template-areas: "navbar navbar" "supportContainer content";
    grid-template-rows: ${({ theme }) => theme.spacing(9)} 1fr;
    grid-template-columns: 2fr 3fr;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: -4rem 4rem;
    background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};

    background-image: url(${({ backgroundImage }) => backgroundImage});
  }

  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

export default LayoutWrapper;
