import React from 'react';

import { Icon } from '@components';

import './style.scss';

const UnexpectedError = () => (
  <div styleName="container">
    <p styleName="title" tid="title">500</p>
    <p styleName="message" tid="message">Upss.. Error inesperado</p>
    <p styleName="text" tid="text">
      Lo sentimos, hubo un error, por favor
      inténtalo de nuevo o repórtalo
      {' '}
      <span>a soporte@100ladrillos.com</span>
    </p>
    <Icon
      name="icUnexpectedError"
      alt="Error inesperado"
      styleName="image"
      tid="image"
    />
  </div>
);

export default UnexpectedError;
