import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import AutomaticInvoiceValidationResult from './AutomaticInvoiceValidationResult';
import AutomaticInvoicingInvestor from './AutomaticInvoicingInvestor';
import EnableAutomaticInvoicingError from './EnableAutomaticInvoicingError';
import FiscalDataAreIncomplete from './FiscalDataAreIncomplete';
import InvestorDoesNotHaveFiscalRegime from './InvestorDoesNotHaveFiscalRegime';
import InvestorDoesNotHaveFiscalZipCode from './InvestorDoesNotHaveFiscalZipCode';
import InvestorDoesNotHaveRFC from './InvestorDoesNotHaveRFC';
import InvestorHasAnInvalidFiscalRegime from './InvestorHasAnInvalidFiscalRegime';
import ValidateFiscalDataError from './ValidateFiscalDataError';
class InvoicingAPI extends HundredBricksAPI {
    /**
     * Returns an instance of Enrollment investor but with automatic invoicing added
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `AutomaticInvoicingInvestor`
     */
    async enableAutomaticInvoicing() {
        try {
            const { data } = await this.client.post('v2/profile/automaticInvoicing/enable');
            return new AutomaticInvoicingInvestor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'TAX_ID_NOT_FOUND':
                        throw new InvestorDoesNotHaveRFC({ cause: error });
                    case 'FISCAL_REGIME_NOT_FOUND':
                        throw new InvestorDoesNotHaveFiscalRegime({ cause: error });
                    case 'FISCAL_ZIP_CODE_NOT_FOUND':
                        throw new InvestorDoesNotHaveFiscalZipCode({ cause: error });
                    case 'INVALID_FISCAL_REGIME':
                        throw new InvestorHasAnInvalidFiscalRegime({ cause: error });
                    default:
                        throw new EnableAutomaticInvoicingError('Unexpected automatic invoicing error', { cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new EnableAutomaticInvoicingError('Unexpected automatic invoicing error', { cause: error });
            }
            else {
                throw new EnableAutomaticInvoicingError(`Unexpected automatic invoicing error: '${error}'`);
            }
        }
    }
    /**
     * Returns an instance of invoice data validation, provide information about if the investor has
     * his documents in order to enable automatic invoice
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `AutomaticInvoiceValidationResult`
     */
    async validateFiscalData() {
        try {
            const { data } = await this.client.get('v2/invoicing/fiscalData/validation');
            return new AutomaticInvoiceValidationResult(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                if (error.code === 'INCOMPLETE_FISCAL_DATA') {
                    throw new FiscalDataAreIncomplete({ cause: error });
                }
                else {
                    throw new ValidateFiscalDataError('An unexpected error occurred while verifying fiscal data', { cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new ValidateFiscalDataError('An unexpected error occurred while verifying fiscal data', { cause: error });
            }
            else {
                throw new ValidateFiscalDataError(`An unexpected error occurred while verifying fiscal data: '${error}'`);
            }
        }
    }
}
export default InvoicingAPI;
