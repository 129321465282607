import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { SuccessPanel } from '@components';

const SuccessfulPurchasePanel = props => {
  const navigate = useNavigate();
  const { purchasedBricks } = props;
  const brickNoun = purchasedBricks > 1 ? 'Ladrillos' : 'Ladrillo';
  const text = `¡Felicidades, has comprado ${purchasedBricks} ${brickNoun}!`;
  const emoji = <span aria-label="Emoji de éxito" role="img">🎉</span>;
  const title = (
    <span>
      {`${text} `}
      {emoji}
    </span>
  );
  const icon = { alternateText: 'Ícono compra de Ladrillos', name: 'icHandshake' };
  const description = 'Disfruta ahora de los beneficios de invertir en inmuebles,'
    + ' ¡Vivir de tus rentas nunca fue tan fácil!.';

  const goToMyAccount = () => navigate('/my-account');

  return (
    <SuccessPanel
      tid="success-panel"
      title={title}
      icon={icon}
      description={description}
      buttonText="Ir a mis Ladrillos"
      onClick={goToMyAccount}
    />
  );
};

SuccessfulPurchasePanel.propTypes = {
  purchasedBricks: PropTypes.number.isRequired
};

export default SuccessfulPurchasePanel;
