import React from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '@lib/authentication';
import {
  Button,
  Icon,
  Modal,
  Text
} from '@components';

import { InfoWrapper, ModalTitle } from './SessionExpiredModal.style';

const SessionExpiredModal = ({ isOpen }) => {
  const { logout } = useAuth();

  return (
    <Modal isOpen={isOpen} preventClose>
      <ModalTitle>¡Tu sesión ha expirado!</ModalTitle>

      <Icon name="icClockExpiring" alt="Sesión expirada" height={84} width={84} />

      <InfoWrapper>
        <Text>Por inactividad, tu sesión expiró.</Text>

        <Text>Por seguridad espera 5 segundos para volver a acceder a tu cuenta.</Text>
      </InfoWrapper>

      <Button id="logout-session-button" onClick={logout}>
        Ir a iniciar sesión
      </Button>
    </Modal>
  );
};

SessionExpiredModal.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default SessionExpiredModal;
