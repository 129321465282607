import React from 'react';
import PropTypes from 'prop-types';

import ButtonViewProperties from '../ButtonViewProperties';
import ScrollView from '../ScrollView';

import { ChildrenWrapper, MainLayoutWrapper } from './MainLayout.style';

const MainLayout = ({ children }) => (
  <MainLayoutWrapper>
    <ScrollView>
      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
      <ButtonViewProperties />
    </ScrollView>
  </MainLayoutWrapper>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default MainLayout;
