import React from 'react';
import PropTypes from 'prop-types';

import iconList from './iconList';

const Icon = ({
  className,
  name,
  height,
  width,
  alt
}) => (
  <img className={className} src={iconList[name]} alt={alt} height={height} width={width} />
);

Icon.defaultProps = {
  height: '20',
  width: '20',
  className: ''
};

Icon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default Icon;
