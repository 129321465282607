import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/require-default-props */
import isEmpty from 'lodash.isempty';
import { ActionText, RenderIf, Icon } from '@components';
import { goToExternalLink } from '@utils';
import { Paragraph, Subtitle } from '@components/Styled';
import StakeholderInformation from './Stakeholder.style';
const Stakeholder = (props) => {
    const { description, highlights, name, photoURL, websiteLink } = props;
    return (_jsxs(StakeholderInformation, { children: [_jsxs("div", { children: [_jsx(RenderIf, { condition: photoURL, children: _jsx("img", { src: photoURL, alt: name }) }), _jsx(RenderIf, { condition: !photoURL, children: _jsx(Icon, { name: "icEmptyLeaseholders", alt: "Imagen de Stakeholder", height: "14", width: "14" }) }), _jsxs("div", { children: [_jsx(Subtitle, { level: "2", align: "left", children: name }), _jsx(RenderIf, { condition: websiteLink, children: _jsx(ActionText, { color: "info", onClick: () => goToExternalLink(websiteLink), children: "Ir a su sitio web" }) })] })] }), _jsx(RenderIf, { condition: description, children: _jsx(Paragraph, { align: "left", children: description }) }), _jsx(RenderIf, { condition: !isEmpty(highlights), children: _jsx("ul", { children: highlights?.map(highlight => (_jsx("li", { children: _jsx(Paragraph, { align: "left", children: highlight }) }, highlight))) }) })] }));
};
export default Stakeholder;
