import React from 'react';

import { Icon, Text } from '@components';

import { NoPropertiesFoundWrapper } from './NoPropertiesFound.style';

const NoPropertiesFound = () => (
  <NoPropertiesFoundWrapper>
    <Icon
      name="icEmptyMyBricks"
      alt="Sin propiedades para mostrar"
      width={176}
      height={176}
    />
    <Text>
      No tenemos propiedades terminadas activas.
      Pronto tendremos propiedades en donde podrás invertir y crecer tú patrimonio.
    </Text>
  </NoPropertiesFoundWrapper>
);

export default NoPropertiesFound;
