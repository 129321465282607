import styled from 'styled-components';

const NoPropertiesFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.spacing(62)};

  > p {
    text-align: center;
    color: ${({ theme }) => theme.palette.grayI};
    font-size: ${({ theme }) => theme.toRem(16)};
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    height: ${({ theme }) => theme.spacing(40)};
  }
`;

export { NoPropertiesFoundWrapper };
