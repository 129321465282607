import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { ActionText, Icon } from '@components';
import { HelperText } from '@components/Styled';
import { CompleteProfileContainer } from './CompleteProfile.style';
const CompleteProfile = () => {
    const navigate = useNavigate();
    const goToEnrollment = (event) => {
        navigate('/enrollment');
        event.stopPropagation();
    };
    return (_jsxs(CompleteProfileContainer, { children: [_jsx(Icon, { alt: "Informaci\u00F3n", name: "icInformation", height: 20, width: 20 }), _jsx("div", { children: _jsxs(HelperText, { color: "warning-dark", children: ['Para poder invertir, necesitas tener tu perfil completo y validado. ', _jsx(ActionText, { color: "info", onClick: goToEnrollment, children: "Completa tu perfil." })] }) })] }));
};
export default CompleteProfile;
