import styled, { css } from 'styled-components';

import { flexAsColumn, flexAsRow, grid } from '@components/Styled/mixins';
import Icon from '@components/Icon';

const ShoppingCartWrapper = styled.div`
  ${flexAsColumn()};

  > div:first-child {
    display: grid;
    grid-template-columns: 1fr .9fr 1fr;
    height: ${({ theme }) => theme.spacing(26)};
    width: 100%;
    position: relative;
    border-bottom: 2px solid ${({ theme }) => theme.palette.grayC};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    > div:first-child {
      ${flexAsColumn(3)};

      height: fit-content;
      border-bottom-left-radius: ${({ theme }) => theme.spacing(0.5)};
    }
  }
`;

const AvailableBricks = styled.div`
  ${flexAsRow()};

  justify-content: flex-end;
  align-content: center;
  align-items: center;
  top: -${({ theme }) => theme.spacing(5.5)};
  right: 0;
  background-color: ${({ theme }) => theme.palette.white};
  height: ${({ theme }) => theme.spacing(5)};
  width: fit-content;
  transition: 0.2s;
  position: absolute;
  margin-right: ${({ theme }) => theme.spacing(11)};

  span {
    &:nth-child(1) {
      font-size: ${({ theme }) => theme.toRem(11)};
      margin: ${({ theme }) => `0 ${theme.spacing(1)} 0 0`};
      max-width: ${({ theme }) => theme.spacing(8)};
      text-align: right;
    }

    &:nth-child(2) {
      color: ${({ theme }) => theme.palette.info.main};
      font-size: ${({ theme }) => theme.toRem(24)};
      margin-top: ${({ theme }) => theme.spacing(0.5)};
    }

    &:nth-child(3) {
      margin: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(1)} 0 ${theme.spacing(0.5)}`};
      font-size: ${({ theme }) => theme.toRem(14)};
      color: ${({ theme }) => theme.palette.grayF};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    top: -${({ theme }) => theme.spacing(1)};
    height: ${({ theme }) => theme.spacing(5)};
    left: 50%;
    transform: translateX(-50%);
    border-radius: ${({ theme }) => theme.spacing(0.5)};
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
    width: fit-content;
    padding: ${({ theme }) => `0 ${theme.spacing(1)}`};

    span {
      &:nth-child(3) {
        margin: ${({ theme }) => `${theme.spacing(1)} 0 0 ${theme.spacing(0.5)}`};
      }
    }
  }
`;

const ShoppingCartItem = styled.div`
  ${flexAsColumn(1)};

  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing(0.5)} 0`};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    margin: 0;
  }
`;

const VolumeDiscount = styled(ShoppingCartItem)`
  text-align: center;
  position: relative;

  ${({ hasVolumeDiscount }) => hasVolumeDiscount && css`
    justify-content: flex-start;

    > p:first-child {
      margin: ${({ theme }) => `calc(4% + ${theme.spacing(2)}) 0 ${theme.spacing(2)}`};
    }
  `};

  > div {
    ${flexAsColumn(1)};

    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding-bottom: ${({ theme }) => theme.spacing(0.5)};

    ${({ hasVolumeDiscount }) => hasVolumeDiscount && css`
      margin-top: ${({ theme }) => theme.spacing(2)};

      > p:first-child {
        margin-top: ${({ theme }) => theme.spacing(4)};
      }
    `};
  }
`;

const QuoterContainer = styled(VolumeDiscount)`
  background-color: ${({ theme }) => theme.palette.grayA};
`;

const BrickPrice = styled(ShoppingCartItem)`
  text-align: center;

  > div {
    ${flexAsRow(1)};
    align-items: center;

    > p {
      text-decoration: line-through;
    }
  }

  > div:last-child {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding: ${({ theme }) => `${theme.spacing(5)} 0 ${theme.spacing(1)}`};
  }
`;

const AdditionIcon = styled(Icon)`
  position: absolute;
  left: -10%;
  top: ${({ theme }) => theme.spacing(10)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    left: unset;
    top: -20%;
  }
`;

const EstimatedDeliveryDate = styled.div`
  ${grid(1)};

  justify-items: center;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing(2)} 0`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    margin: ${({ theme }) => `${theme.spacing(1)} 0`};

    ${({ isCompletedProperty }) => {
    if (isCompletedProperty) {
      return css`
        grid-template-columns: 1fr 2fr;
        justify-items: start;
      `;
    }

    return css`
      grid-template-columns: 1fr;
      justify-items: center;
    `;
  }}}
`;

const CompletedPropertyBadge = styled.div`
  ${flexAsRow(0.5)};

  align-items: center;
`;

const PropertySoldContainer = styled.div`
  ${flexAsColumn(4)};

  align-items: center;
  margin: ${({ theme }) => `${theme.spacing(2)} 0`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    margin: 0;
  }
`;

export {
  ShoppingCartWrapper,
  AvailableBricks,
  BrickPrice,
  AdditionIcon,
  VolumeDiscount,
  QuoterContainer,
  EstimatedDeliveryDate,
  CompletedPropertyBadge,
  PropertySoldContainer
};
