import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import coverImage from '@assets/images/new-login-background.png';
import { Date as DateParser } from '@types';
import { Paragraph, Subtitle } from '@components/Styled';
import { MainContainer } from '@components/AppLayout/AppLayout.style';
import { LegacyMap, toast, LayoutWrapper, Navbar, MediaContainer } from '@components';
import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { useRequest } from '@hooks';
import { MapArea, SubmitButton, UnusualAccessWrapper } from './UnusualAccessLock.styled';
const UnusualAccessLock = (props) => {
    const api = useHundredBricksAPI();
    const [response, , error, request] = useRequest(api.authentication.resendUnusualCodeAccess);
    const { state } = props;
    const [unusualAccessData, setUnusualAccessData] = useState({});
    const loginMenu = [
        {
            to: '/login',
            label: 'Regresar',
            isCallToAction: true
        }
    ];
    const getUnusualAccessData = () => {
        const unusualAccessMetadata = state.get('unusualAccessData') || '{}';
        return JSON.parse(unusualAccessMetadata);
    };
    const clearUnusualAccessDataFromStorage = () => {
        state.remove('unusualAccessData');
    };
    const resendEmailUnusualAccess = async () => {
        request(unusualAccessData.responseEmail);
    };
    const hasCordinates = () => Boolean(unusualAccessData?.geolocalization?.latitude
        && unusualAccessData?.geolocalization?.longitude);
    const getDateFormated = () => {
        const { detectedAt } = unusualAccessData;
        const formattedDetectedDate = detectedAt
            ? DateParser(new Date(detectedAt).getTime())
            : DateParser(new Date().getTime());
        const formattedDetectedAtDate = formattedDetectedDate.format('DD MMMM YYYY');
        const formattedDetectedAtTime = formattedDetectedDate.format('hh:mm a');
        return `Identificamos un inicio de sesión el ${formattedDetectedAtDate}
      a las ${formattedDetectedAtTime},`;
    };
    useEffect(() => {
        const unusualAccessDataFromProps = getUnusualAccessData();
        setUnusualAccessData(unusualAccessDataFromProps);
        return () => clearUnusualAccessDataFromStorage();
    }, []);
    useEffect(() => {
        if (response !== undefined) {
            toast.success('El correo se envió con éxito');
        }
    }, [response]);
    useEffect(() => {
        if (error) {
            toast.danger('Algo salió mal al enviar el correo, inténtalo de nuevo');
        }
    }, [error]);
    return (_jsxs(LayoutWrapper, { title: "Sesi\u00F3n inusual, cuenta bloqueada", children: [_jsx(Navbar, { menu: loginMenu }), _jsx(MediaContainer, { backgroundImage: coverImage }), _jsx(MainContainer, { children: _jsxs(UnusualAccessWrapper, { children: [_jsx(Subtitle, { children: "Sesi\u00F3n inusual, cuenta bloqueada" }), _jsxs(Paragraph, { children: [getDateFormated(), ' ', _jsx("br", {}), ' ', "en una ubicaci\u00F3n distinta a la habitual."] }), hasCordinates() && (_jsx(MapArea, { children: _jsx(LegacyMap, { coordinates: unusualAccessData?.geolocalization || {} }) })), _jsxs(Paragraph, { children: ["Por seguridad hemos bloqueado esta cuenta, para desbloquearla", _jsx("br", {}), ' ', "sigue las instrucciones que hemos enviado a tu correo electr\u00F3nico", ' ', _jsx("br", {}), ' ', "o puedes contactar con soporte a cliente."] }), _jsx(Paragraph, { children: "soporte@100Ladrillos.com" }), _jsx("span", { children: "\u00BFA\u00FAn no llega tu correo? Intenta enviarlo nuevamente" }), _jsx(SubmitButton, { id: "resend-email", onClick: resendEmailUnusualAccess, children: "Enviar" })] }) })] }));
};
export default UnusualAccessLock;
