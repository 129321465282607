import React from 'react';
import PropTypes from 'prop-types';
import {
  MobileStep as BuiMobileStep,
  MobileStepContent as BuiMobileStepContent,
  MobileStepHeader as BuiMobileStepHeader,
  MobileStepper as BuiMobileStepper,
  MobileStepperIndicator as BuiMobileStepperIndicator
} from 'bricks-ui/packages/bricks-ui';

const MobileStepper = props => {
  const {
    ActionsComponent,
    activeStepIndex,
    steps
  } = props;
  const activeStep = steps[activeStepIndex];

  return (
    <BuiMobileStepper steps={steps} activeStepId={activeStep.id}>
      <BuiMobileStep>
        <BuiMobileStepperIndicator
          activeStepIndex={activeStep.index}
          numberOfSteps={steps.length}
        />
        <BuiMobileStepHeader titleText={activeStep.name} />
        <BuiMobileStepContent>
          {activeStep.content}
        </BuiMobileStepContent>
        {ActionsComponent}
      </BuiMobileStep>
    </BuiMobileStepper>
  );
};

MobileStepper.defaultProps = {
  ActionsComponent: null,
  activeStepIndex: 0
};

MobileStepper.propTypes = {
  ActionsComponent: PropTypes.node,
  activeStepIndex: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.node
  })).isRequired
};

export default React.memo(MobileStepper);
