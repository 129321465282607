class Antiphishing {
    fullname;
    imageUrl;
    phrase;
    constructor(fullname, image, phrase) {
        this.fullname = fullname;
        this.imageUrl = image;
        this.phrase = phrase;
    }
}
export default Antiphishing;
