import { jsx as _jsx } from "react/jsx-runtime";
import isEmpty from 'lodash.isempty';
import { useEffect } from 'react';
import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { useErrorReporter } from '@lib/errorReporter';
import { useProfile } from '@lib/profile';
import { useRequest } from '@hooks';
import PaymentPlansContext from './PaymentPlansContext';
const PaymentPlansProvider = ({ children }) => {
    const errorReporter = useErrorReporter();
    const { profile } = useProfile();
    const { trading } = useHundredBricksAPI();
    const [paymentPlans, isLoadingPaymentPlans, paymentPlansError, getPaymentPlans] = useRequest(trading.paymentPlans.getPaymentPlans);
    const loadPaymentPlans = () => getPaymentPlans({ page: 1, perPage: 20 });
    useEffect(() => {
        if (profile?.accountLevel.isLimitless()) {
            loadPaymentPlans();
        }
    }, [profile]);
    useEffect(() => {
        if (paymentPlansError) {
            errorReporter.critical(paymentPlansError);
        }
    }, [paymentPlansError]);
    return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    _jsx(PaymentPlansContext.Provider, { value: {
            plans: paymentPlans,
            reload: loadPaymentPlans,
            isLoading: isLoadingPaymentPlans,
            error: paymentPlansError,
            hasPlans: Boolean(!isLoadingPaymentPlans
                && paymentPlans
                && !isEmpty(paymentPlans?.items))
        }, children: children }));
};
export default PaymentPlansProvider;
