import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { RenderIf } from '@components';
import { useRequest } from '@hooks';
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import amenitiesCategorizer from './amenitiesCategorizer';
import { AmenitiesWrapper, AmenityItem, SectionItem, Sections, TitleSection } from './PropertyAmenities.style';
const PropertyAmenities = (props) => {
    const { propertyId, amenitiesRequest } = props;
    const [amenitiesResponse, isLoadingAmenities, amenitiesError, getAmenities] = useRequest(amenitiesRequest);
    const propertyAmenities = amenitiesCategorizer(amenitiesResponse);
    const isRequestSuccessful = Boolean(amenitiesResponse
        && !isLoadingAmenities
        && !amenitiesError);
    useEffect(() => {
        getAmenities(propertyId);
    }, []);
    return (_jsx(RenderIf, { condition: isRequestSuccessful && propertyAmenities.length > 0, children: _jsxs(AmenitiesWrapper, { children: [_jsx(Subtitle, { level: "2", align: "left", children: "Amenidades del proyecto" }), _jsx(Sections, { children: propertyAmenities.map(section => (_jsxs(SectionItem, { children: [_jsxs(TitleSection, { children: [section.icon, _jsx(Bold, { children: section.sectionName })] }), section.amenitiesData.map(amenity => (_jsxs(AmenityItem, { children: [_jsx(Paragraph, { children: amenity.title }), _jsx(Bold, { children: amenity.quantity })] }, amenity.id)))] }, section.sectionName))) })] }) }));
};
export default PropertyAmenities;
