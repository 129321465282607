import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import cienLadrillosLogo from '@assets/images/100-ladrillos-logo.svg';
import { Image } from '@components';
import { usePaymentPlans } from '@lib/paymentPlans';
import { useProfile } from '@lib/profile';

import Money from '../../../../types/money';
import {
  profileIncompletedNavItems,
  profileCompletedNavItems,
  enrolledNavItems,
  readyToSignRiskFormNavItems
} from '../../navItems';
import NavList from '../NavList';

import './style.scss';

const formatToMoney = value => new Money(value || 0).toString();

const SideNav = props => {
  const {
    activeTab,
    isLegacy,
    isLegacyAndLegalTrader,
    isEmptyTrader
  } = props;
  const { profile, investorBalance } = useProfile();
  const [navItems, setNavItems] = useState([]);

  const { hasPlans } = usePaymentPlans();

  useEffect(() => {
    if (profile.canBuy()) {
      setNavItems(enrolledNavItems.filter(item => item.id !== 'profile'));

      if (!hasPlans) {
        setNavItems(prev => prev.filter(item => item.id !== 'paymentPlans'));
      }

      if (investorBalance?.hasInvested()) {
        setNavItems(prev => prev.filter(item => item.id !== 'first-steps'));
      }
    } else if (profile.status.isReadyToSignRiskForm()) {
      setNavItems(readyToSignRiskFormNavItems);
    } else if (profile.status.isEnrolling()) {
      setNavItems(profileIncompletedNavItems);
    } else {
      setNavItems(profileCompletedNavItems);
    }
  }, [profile, hasPlans, investorBalance]);

  return (
    <nav styleName="sidenav">
      <ul styleName="static-list">
        <li>
          <Link to="/marketplace/presale">
            <Image src={cienLadrillosLogo} alt="100 Ladrillos Logo" styleName="logo" />
          </Link>
        </li>
        {profile.canBuy() && (
          <Fragment>
            <li id="menu-AccountValue">
              <span>Valor de la cuenta</span>
              <span styleName="account-value">{formatToMoney(investorBalance?.amount)}</span>
            </li>
            <li id="menu-availableBalance">
              <span>Saldo disponible</span>
              <span styleName="available-amount">{formatToMoney(investorBalance?.available)}</span>
            </li>
          </Fragment>
        )}
      </ul>
      <NavList
        items={navItems}
        activeTab={activeTab}
        isLegacy={isLegacy}
        isLegacyAndLegalTrader={isLegacyAndLegalTrader}
        isEmptyTrader={isEmptyTrader}
      />
    </nav>
  );
};

SideNav.defaultProps = {
  isLegacy: false
};

SideNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  isLegacy: PropTypes.bool,
  isLegacyAndLegalTrader: PropTypes.bool.isRequired,
  isEmptyTrader: PropTypes.bool.isRequired
};

export default SideNav;
