import styled, { css } from 'styled-components';

const responsiveDisplay = ({ mobile, desktop }) => css`
  display: ${mobile};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: ${desktop};
  }
`;

export const Navbar = styled.nav`
  ${responsiveDisplay({ mobile: 'none', desktop: 'block' })};
  position: absolute;
  left: 0;
  right: 0;
  top: 54px;

  &.opened {
    display: block;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    position: relative;
    top: 0;
  }
`;

export const ListWrapper = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  background: white;
  position: sticky;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    flex-direction: row;
  }
`;

export const ListItem = styled.li`
  margin-right: 0;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grayC};
  height: ${({ theme }) => theme.spacing(6)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    margin-right: ${({ theme }) => theme.spacing(3)};
    border-bottom: unset;

    &:last-child {
      margin-right: 0;
    }
  }
`;
