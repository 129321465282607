import React from 'react';
import styled, { css } from 'styled-components';

import { Text as TextComponent } from '@components';

const AlternativeAction = styled(props => <TextComponent {...props} />)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    margin: 0;
    text-align: center;
    position: absolute;
    top: ${({ theme }) => theme.spacing(12)};
    right: ${({ theme }) => theme.spacing(3)};

    ${({ hideOnDesktop }) => hideOnDesktop && css`display: none;`}
  }
`;

const AntiphishingData = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};

  p:nth-child(2n) {
    text-transform: lowercase;
  }
`;

const AntiphishingField = styled(props => <TextComponent {...props} />)`
  color: ${({ theme }) => theme.palette.grayG};
  font-size: ${({ theme }) => theme.toRem(16)};
`;

const AntiphishingImage = styled.img`
  width: 100px;
  height: 100px;
`;

const AntiphishingWarning = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const ResponsiveLineBreak = styled.br`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: initial;
  }
`;

const Text = styled(props => <TextComponent {...props} />)`
  margin-bottom: 0;
`;

const UnexpectedErrorMessageWrapper = styled.div`
  background: ${({ theme }) => theme.palette.white};
  margin-top: 0;
  margin-bottom: 0;
`;

const NetworkMessageWrapper = styled.span`
  color: ${({ theme }) => theme.palette.danger.main};
  display: block;
  font-family: "RobotoItalic";
`;

const CustomerSupportEmailWrapper = styled.span`
  display: block;
  font-family: "RobotoMedium";
`;

const VPNErrorMessage = styled.p`
  background: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.danger.main};
  font-size: ${({ theme }) => theme.toRem(14)};
  line-height: ${({ theme }) => theme.toRem(20)};
  font-family: ${({ theme }) => theme.typography.font.fontFamily};
  margin: 0;
`;

export {
  AlternativeAction,
  AntiphishingData,
  AntiphishingImage,
  AntiphishingField,
  AntiphishingWarning,
  CustomerSupportEmailWrapper,
  NetworkMessageWrapper,
  ResponsiveLineBreak,
  Text,
  UnexpectedErrorMessageWrapper,
  VPNErrorMessage
};
