import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export default function withRouter(ComponentWrapped) {
  return props => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [redirectTo, setRedirectTo] = useState(null);

    const history = {
      push: (...args) => {
        setRedirectTo(args);
      },
      replace: (...args) => {
        setRedirectTo([...args, { replace: true }]);
      },
      goBack(backTo = -1) {
        setRedirectTo([backTo]);
      }
    };

    useEffect(() => {
      if (redirectTo) {
        const [path, options = null, configuration = {}] = redirectTo;

        if (typeof path === 'object') {
          const { pathname = {}, search = '', state } = path;

          navigate(pathname + search, { state, ...configuration });
        } else if (typeof path === 'string' && options !== null) {
          navigate(path, { state: { ...options }, ...configuration });
        } else {
          navigate(path, { configuration });
        }
      }
    }, [redirectTo]);

    let paramsToProps = params;

    if (props?.match?.params) {
      paramsToProps = { ...params, ...props.match.params };
    }

    const historyToProps = { ...history, ...props.history };
    const locationToProps = { ...location, ...props.location };

    return (
      <ComponentWrapped
        {...props}
        history={historyToProps}
        location={locationToProps}
        match={{ params: paramsToProps }}
      />
    );
  };
}
