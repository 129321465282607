import { Email, Phone } from '../Enrollment';
class Profile {
    id;
    consecutiveNumber;
    fullName;
    email;
    canBuy;
    createdAt;
    updatedAt;
    firstLastName;
    secondLastName;
    curp;
    taxId;
    phone;
    name;
    advertising;
    automaticInvoicing;
    lockCode;
    constructor(data) {
        this.id = data.id;
        this.consecutiveNumber = data.userId;
        this.fullName = data.fullName;
        this.email = new Email({ email: data.email, isEmailVerified: data.isEmailVerified });
        this.canBuy = data.canBuy;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.firstLastName = data.firstLastName;
        this.secondLastName = data.secondLastName;
        this.curp = data.curp;
        this.taxId = data.taxId;
        this.phone = new Phone(data.phone);
        this.name = data.names;
        this.advertising = data.advertising;
        this.automaticInvoicing = data.automaticInvoicing;
        this.lockCode = data.lockCode;
    }
    hasAutomaticInvoicing() {
        return Boolean(this.automaticInvoicing);
    }
}
export default Profile;
