class Money {
  constructor(cents) {
    this._cents = cents || 0;
    this._amount = Number(this._cents / 100);
  }

  getAmount() {
    return this._amount;
  }

  getCents() {
    return this._cents;
  }

  toString() {
    return this.stringFormat();
  }

  formattedString() {
    return this.stringFormat();
  }

  stringFormat() {
    const valueWithTwoDecimals = this._amount.toFixed(2);
    const fourNumbersTogetherRegexp = /(\d)(?=(\d\d\d)+(?!\d))/g;
    const valueSeparatedWithCommas = valueWithTwoDecimals.replace(fourNumbersTogetherRegexp, '$1,');

    let formattedValue = `$${valueSeparatedWithCommas}`;

    if (this._amount < 0) {
      formattedValue = formattedValue.replace('$-', '-$');
    }

    return formattedValue;
  }
}

export default Money;
