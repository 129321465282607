import React from 'react';
import styled, { css } from 'styled-components';

import {
  Card as CardComponent,
  Icon as IconComponent
} from '@components';

const LogCardWrapper = styled(props => <CardComponent {...props} />)`
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: ${({ theme }) => theme.spacing(2)};
  }
`;

const LogCardDateTitle = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    flex-direction: column-reverse;
  }

  > p:last-child {
    text-align: right;
    text-transform: capitalize;
    min-width: ${({ theme }) => theme.spacing(12)};
  }
`;

const TitleDate = styled.p`
  margin: 0;
  text-align: left;
  font-weight: ${({ theme }) => theme.typography.title.h3.fontWeight};
  font-size: ${({ theme }) => theme.toRem(20)};
`;

const LogCardEdit = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};

  > p {
    margin: 0;
  }
`;

const DescriptionContainer = styled.div`
  height: auto;
  max-height: ${({ theme }) => theme.spacing(20)};
  width: 100%;
  overflow: hidden;

  > p {
    text-align: left;
    font-weight: ${({ theme }) => theme.typography.title.h1.fontWeight};
  }

  ${({ isShowDescription }) => {
    if (isShowDescription) {
      return css`
        height: fit-content;
        max-height: 100%;
      `;
    }
  }};
`;

const ImageContainer = styled.div`
  display: grid;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
  grid-template-columns: repeat(auto-fill, ${({ theme }) => theme.spacing(10)});

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-auto-rows: ${({ theme }) => theme.spacing(10)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-columns: repeat(auto-fill,
      minmax(${({ theme }) => theme.spacing(10)}, ${({ theme }) => theme.spacing(50)}));
    gap: ${({ theme }) => theme.spacing(2)};
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
`;

const ImageWrapper = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.palette.alternative5.light};
  margin-top: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(2)};

  :hover {
    cursor: pointer;
  }
`;

const IconArrowWrapper = styled(props => <IconComponent {...props} />)`
  ${({ isShowDescription }) => {
    if (isShowDescription) {
      return css`
        transform: rotate(180deg);
      `;
    }
  }};
`;

export {
  ButtonWrapper,
  DescriptionContainer,
  IconArrowWrapper,
  ImageContainer,
  ImageWrapper,
  LogCardDateTitle,
  LogCardEdit,
  LogCardWrapper,
  TitleDate
};
