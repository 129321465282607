import styled from 'styled-components';

import { flexAsColumn, grid } from '@components/Styled/mixins';

const StakeholderInformation = styled.section`
  ${flexAsColumn(2)};

  margin-bottom: ${({ theme }) => theme.spacing(3)};

  > ul {
    padding-left: ${({ theme }) => theme.spacing(2)};
    margin-top: 0;
  }

  > div {
    ${grid(2)};

    margin-top: ${({ theme }) => theme.spacing(2)};
    grid-template-columns: .2fr 1fr;
    align-items: flex-start;
    justify-items: flex-start;
    text-align: left;

    > img {
      aspect-ratio: 1/1;
      height: auto;
      max-width: ${({ theme }) => theme.spacing(20)};
      object-fit: cover;
      width: 100%;
    }

    h3 {
      margin-top: 0;
    }

    button {
      font-size: ${({ theme }) => `${theme.toRem(16)}`};
      width: fit-content;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
      grid-template-columns: .1fr 1fr;
    }
  }
`;

export default StakeholderInformation;
