import React from 'react';
import PropTypes from 'prop-types';

import { ActionsWrapper } from './Actions.styled';

function Actions({ className, children }) {
  return (
    <ActionsWrapper className={className}>
      {children}
    </ActionsWrapper>
  );
}

Actions.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Actions.defaultProps = {
  className: '',
};

export default Actions;
