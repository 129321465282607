import React, { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Money } from '@types';
import { useUpgradingAccount } from '@hooks';
import { formatNumbers } from '@utils';
import { useInsightsService } from '@lib/insightsService';
import { Paragraph, Subtitle } from '@components/Styled';

import Icon from '../Icon';

import { Discounts, Quotation } from './components';
import './style.scss';
import fetchQuotation from './api';

export const calculateMaximumVolumeDiscount = volumeDiscounts => (
  volumeDiscounts.reduce((previous, current) => (
    previous > current.discountPercentPerBrick ? previous : current
  ), 0)
);

export const calculateTotalVolumeDiscountPercentage = (
  retailPriceInPesos,
  volumeDiscountInPesos
) => {
  const discountInPesos = retailPriceInPesos - volumeDiscountInPesos;
  const totalPercentage = 100;
  const volumeDiscountPercentage = (discountInPesos * totalPercentage) / retailPriceInPesos;

  return volumeDiscountPercentage;
};

const QuoterPresale = props => {
  /** The dependency below is causing dependency cycles, for this reason the dependency is loaded
    * via require. Please, always avoid the use of require() calls in any other place under this
    * codebase unless you have a good reason to do so.
    *
    * We need to move the api module to the @lib module in order to remove the dependency cycle
    */
  const { useProfile } = require('@lib/profile');

  const [bricksQuantity, setBricksQuantity] = useState(0);
  const [quotation, setQuotation] = useState({});
  const [quotationError, setQuotationError] = useState('');
  const [isQuoting, setIsQuoting] = useState(false);

  const {
    availableBricks,
    handleClickAcceptButton,
    isLegacyAndLegalTrader,
    maximumBricksToBuy,
    minimumBricksToBuy,
    presalePrice,
    profileCompleted,
    propertyId,
    retailPrice,
    totalAvailableBricks,
    trader,
    volumeDiscounts
  } = props;
  const {
    expiresAt: presaleExpirationDate,
    discountPercent = 0
  } = presalePrice;

  const { profile } = useProfile();
  const { crowdfundingEvent, reportInsight } = useInsightsService();
  const { renderInvestorPlansModal } = useUpgradingAccount();
  const retailPriceInPesos = new Money(retailPrice).getAmount();
  const formattedRetailPrice = new Money(retailPrice).toString();
  const maximumVolumeDiscount = calculateMaximumVolumeDiscount(volumeDiscounts) || 0;
  const { pricePerBrick } = maximumVolumeDiscount;
  const pricePerBrickInPesos = new Money(pricePerBrick).getAmount();
  const hasValuesToCalculateMaximumDiscount = retailPriceInPesos > 0 && pricePerBrick > 0;

  let maximumDiscount = 0;

  if (hasValuesToCalculateMaximumDiscount) {
    maximumDiscount = calculateTotalVolumeDiscountPercentage(
      retailPriceInPesos,
      pricePerBrickInPesos
    );
    maximumDiscount /= 100;
  }

  const setQuotationData = useCallback(debounce(async bricksQuantity => {
    setIsQuoting(true);

    try {
      const { data } = await fetchQuotation(propertyId, bricksQuantity);
      setQuotationError('');
      setQuotation(data);
    } catch (error) {
      const { response = {} } = error;
      const { data = {} } = response;

      setQuotationError(data.code || 'UNEXPECTED_ERROR');
      setQuotation({});
    } finally {
      setIsQuoting(false);
    }
  }, 300), []);

  const isDisabledQuotationAcceptButton = () => bricksQuantity <= 0;

  const handleChangeBricksQuantity = event => {
    const { target: { value } } = event;
    const formattedBricksQuantity = formatNumbers(value, bricksQuantity);
    const hasBricks = formattedBricksQuantity > 0;

    setBricksQuantity(formattedBricksQuantity);
    setQuotationError('');
    setQuotation({});

    if (hasBricks) {
      setQuotationData(formattedBricksQuantity);
    }
  };

  const sendStartPurchaseEvent = () => {
    const {
      property,
      quantity,
      retailPricePerBrick,
      totalPaid
    } = quotation;
    const purchaseQuotation = { quantity, retailPricePerBrick, totalPaid };

    reportInsight(crowdfundingEvent.startPurchase, property, purchaseQuotation);
  };

  const handleBuyButton = () => {
    if (profile.accountLevel.isFrozen() || profile.pendingSignRequests.hasRequests()) {
      renderInvestorPlansModal();
      return;
    }

    handleClickAcceptButton(propertyId, quotation);
    sendStartPurchaseEvent();
  };

  const hasDiscounts = discountPercent || maximumDiscount;
  const retailPriceStyles = cx({
    'has-discount': hasDiscounts
  });

  return (
    <div styleName="quoter-presale">
      <Subtitle
        level="2"
        color="white"
        align="center"
        backgroundColor="primary-main"
      >
        Cotiza
      </Subtitle>

      <div tid="available-bricks" styleName="available-bricks">
        <div>
          <Icon
            name="icBricksSummary"
            alt="Ladrillos disponibles"
            width="24"
            heigth="24"
          />

          <Paragraph>
            Ladrillos disponibles
          </Paragraph>
        </div>
        <div>
          <Subtitle
            level="2"
            color="info-main"
            tid="available-bricks-number"
          >
            {availableBricks}
          </Subtitle>

          <Paragraph tid="total-available-bricks" color="grayH">
            {` / ${totalAvailableBricks}`}
          </Paragraph>
        </div>
      </div>
      <Paragraph styleName="retail-price">
        <Paragraph>Precio de lista</Paragraph>

        <Paragraph styleName="number">
          <Paragraph styleName={retailPriceStyles} tid="retail-price">

            {formattedRetailPrice}
          </Paragraph>
        </Paragraph>

        <Paragraph styleName="caption">Por Ladrillo</Paragraph>
      </Paragraph>
      <div styleName="quoter-section">
        <Discounts
          maximumDiscount={maximumDiscount}
          presaleDiscount={discountPercent}
          presaleExpirationDate={presaleExpirationDate}
        />
      </div>
      <Quotation
        availableBricks={availableBricks}
        bricksQuantity={bricksQuantity}
        handleChangeBricksQuantity={handleChangeBricksQuantity}
        handleClickAcceptButton={handleBuyButton}
        isDisabledAcceptButton={isDisabledQuotationAcceptButton()}
        isLegacyAndLegalTrader={isLegacyAndLegalTrader}
        isLoadingAcceptButton={isQuoting}
        maximumBricksToBuy={maximumBricksToBuy}
        minimumBricksToBuy={minimumBricksToBuy}
        profileCompleted={profileCompleted}
        quotation={quotation}
        quotationError={quotationError}
        trader={trader}
      />
    </div>
  );
};

QuoterPresale.defaultProps = {
  availableBricks: 0,
  retailPrice: 0,
  totalAvailableBricks: 0,
  presalePrice: {},
  volumeDiscounts: []
};

QuoterPresale.propTypes = {
  availableBricks: PropTypes.number,
  retailPrice: PropTypes.number,
  totalAvailableBricks: PropTypes.number,
  presalePrice: PropTypes.shape({
    discountPercent: PropTypes.number,
    expiresAt: PropTypes.string
  }),
  volumeDiscounts: PropTypes.arrayOf(PropTypes.shape({
    discountPercentPerBrick: PropTypes.number,
    pricePerBrick: PropTypes.number,
    minimumBricks: PropTypes.number,
    maximumBricks: PropTypes.number,
    capRate: PropTypes.number,
    capitalGain: PropTypes.number
  })),
  propertyId: PropTypes.string.isRequired,
  handleClickAcceptButton: PropTypes.func.isRequired,
  trader: PropTypes.shape({}).isRequired,
  isLegacyAndLegalTrader: PropTypes.bool.isRequired,
  profileCompleted: PropTypes.bool.isRequired,
  minimumBricksToBuy: PropTypes.number.isRequired,
  maximumBricksToBuy: PropTypes.number.isRequired
};

export default QuoterPresale;
