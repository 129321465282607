import { SecondaryMarketError, InternationalPurchaseError } from '../errors';
import { InvestmentSummary } from '../entities/Account';
import { SecondFactorToken } from '../entities/Authentication';

import GatewayAPI from './GatewayAPI';

class SecondaryMarketAPI extends GatewayAPI {
  async getBreakdown() {
    try {
      const { data } = await this._client.get('/traders/brickInvestments/summary');

      return new InvestmentSummary(data);
    } catch (error) {
      return this._handleError(error, SecondaryMarketError);
    }
  }

  async getHistoricalCapitalGain() {
    try {
      const { data } = await this._client.get('/traders/brickInvestments/capitalGain');

      return data;
    } catch (error) {
      return this._handleError(error, SecondaryMarketError);
    }
  }

  async getNumberOfBricks() {
    try {
      const { data } = await this._client.get('/traders/brickInvestments/numberOfBricks');

      return data;
    } catch (error) {
      return this._handleError(error, SecondaryMarketError);
    }
  }

  async getPropertiesForSale() {
    try {
      const { data } = await this._legacyClient.get('/properties');

      return data;
    } catch (error) {
      return this._handleError(error, SecondaryMarketError);
    }
  }

  async sendPurchaseToken(propertyId, channel) {
    try {
      const { data } = await this._client.post(
        '/traders/brickInvestments/sendPurchaseToken',
        { propertyId, channel }
      );

      return new SecondFactorToken(data);
    } catch (error) {
      return this._handleError(error, InternationalPurchaseError);
    }
  }

  async purchaseOnSaleBricks(propertyId, payload) {
    try {
      const { data } = await this._legacyClient.post(`/properties/${propertyId}/purchase`, payload);

      return data;
    } catch (error) {
      return this._handleError(error, InternationalPurchaseError);
    }
  }

  async sendSaleToken(channel) {
    try {
      const { data } = await this._client.post(
        '/traders/brickInvestments/sendSaleToken',
        { channel }
      );

      return new SecondFactorToken(data);
    } catch (error) {
      return this._handleError(error, InternationalPurchaseError);
    }
  }

  async putBricksOnSale(propertyId, payload) {
    try {
      const data = await this._legacyClient.post(
        `/properties/${propertyId}/bricks/putOnSale`,
        payload
      );

      return data;
    } catch (error) {
      return this._handleError(error, SecondaryMarketError);
    }
  }

  async sendRemoveBricksOnSaleToken(channel) {
    try {
      const { data } = await this._client.post(
        '/traders/brickInvestments/sendRemoveBricksOnSaleToken',
        { channel }
      );

      return new SecondFactorToken(data);
    } catch (error) {
      return this._handleError(error, SecondaryMarketError);
    }
  }

  async removeBricksOnSale(propertyId, payload) {
    try {
      const { data } = await this._legacyClient.post(
        `/properties/${propertyId}/bricks/removeFromSale`,
        payload
      );

      return data;
    } catch (error) {
      return this._handleError(error, SecondaryMarketError);
    }
  }

  _handleError = (error, APISecondaryMarketError) => {
    if (!error.response) {
      throw APISecondaryMarketError.getNetworkError();
    }

    const errorCode = error.response.data.code;
    const secondaryMarketError = new APISecondaryMarketError(errorCode);

    throw secondaryMarketError;
  };
}

export default SecondaryMarketAPI;
