import { jsx as _jsx } from "react/jsx-runtime";
import NotificationItem from './NotificationItem';
import { AutomaticInvoicingSuccessful, BoughtBricks, CanceledPresaleInvestment, DepositFunds, DirectRentRelease, DispersedRents, DispersedRentsUSA, IncomeReleaseWithoutInvoice, InvoicedIncomeRelease, NewPropertyManagementLog, SalesOrder, SoldBricks } from './templates';
const NotificationFactory = ({ notification }) => {
    const dynamicNotifications = {
        automaticInvoicingSuccessful: 'automaticInvoicingSuccessful',
        boughtBricks: 'buyerPurchase',
        canceledPresaleInvestment: 'investmentCancellation',
        depositFunds: 'depositFunds',
        directRentRelease: 'automaticRentReleaseWithoutInvoiceSuccessful',
        rentReleaseWithoutInvoice: 'rentReleaseWithoutInvoice',
        newCrowdfundingPropertyManagementLog: 'newCrowdfundingPropertyManagementLog',
        newPropertyManagementLog: 'newPropertyManagementLog',
        rentDispersion: 'rentDispersion',
        rentDispersionUSA: 'rentDispersionUSA',
        rentReleaseWithInvoice: 'rentReleaseWithInvoice',
        salesOrder: 'salesOrder',
        soldBricks: 'sellerPurchase'
    };
    switch (notification.type) {
        case dynamicNotifications.automaticInvoicingSuccessful:
            return _jsx(AutomaticInvoicingSuccessful, { notification: notification });
        case dynamicNotifications.boughtBricks:
            return _jsx(BoughtBricks, { notification: notification });
        case dynamicNotifications.canceledPresaleInvestment:
            return _jsx(CanceledPresaleInvestment, { notification: notification });
        case dynamicNotifications.depositFunds:
            return _jsx(DepositFunds, { notification: notification });
        case dynamicNotifications.rentDispersion:
            return _jsx(DispersedRents, { notification: notification });
        case dynamicNotifications.rentDispersionUSA:
            return _jsx(DispersedRentsUSA, { notification: notification });
        case dynamicNotifications.soldBricks:
            return _jsx(SoldBricks, { notification: notification });
        case dynamicNotifications.rentReleaseWithInvoice:
            return _jsx(InvoicedIncomeRelease, { notification: notification });
        case dynamicNotifications.rentReleaseWithoutInvoice:
            return _jsx(IncomeReleaseWithoutInvoice, { notification: notification });
        case dynamicNotifications.newPropertyManagementLog:
        case dynamicNotifications.newCrowdfundingPropertyManagementLog:
            return _jsx(NewPropertyManagementLog, { notification: notification });
        case dynamicNotifications.directRentRelease:
            return _jsx(DirectRentRelease, { notification: notification });
        case dynamicNotifications.salesOrder:
            return _jsx(SalesOrder, { notification: notification });
        default:
            return _jsx(NotificationItem, { notification: notification });
    }
};
export default NotificationFactory;
