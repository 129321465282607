class CompletedSale {
    brickSaleId;
    propertyName;
    invoice;
    totalFeesPaid;
    utilityAmount;
    utilityPercentage;
    numberOfBricks;
    revolvingFund;
    sellingPrice;
    acquisitionPrice;
    totalAmountReceived;
    saleDate;
    constructor(data) {
        this.brickSaleId = data.brickSaleId;
        this.propertyName = data.propertyName;
        this.invoice = data.invoice;
        this.totalFeesPaid = data.totalFeesPaid;
        this.utilityAmount = data.utilityAmount;
        this.utilityPercentage = data.utilityPercentage;
        this.numberOfBricks = data.numberOfBricks;
        this.revolvingFund = data.revolvingFund;
        this.sellingPrice = data.sellingPrice;
        this.acquisitionPrice = data.acquisitionPrice;
        this.totalAmountReceived = data.totalAmountReceived;
        this.saleDate = new Date(data.saleDate);
    }
    get utility() {
        const isResultNaN = Number.isNaN(this.utilityAmount / this.acquisitionPrice);
        const isAcquisitionPriceInvalid = Number(this.acquisitionPrice) <= 0 || Number.isNaN(this.acquisitionPrice);
        const isUtilityAmountInvalid = Number.isNaN(this.utilityAmount);
        if (isResultNaN || isAcquisitionPriceInvalid || isUtilityAmountInvalid) {
            return 0;
        }
        return (this.utilityAmount / this.acquisitionPrice);
    }
}
export default CompletedSale;
