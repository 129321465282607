class Sale {
    areReserved;
    bricks;
    numberOfBricks;
    onSaleAt;
    pricePerBrick;
    property;
    totalAmount;
    constructor(data) {
        this.areReserved = data.areReserved;
        this.bricks = data.bricks;
        this.numberOfBricks = data.numberOfBricks;
        this.onSaleAt = new Date(data.onSaleAt);
        this.pricePerBrick = data.pricePerBrick;
        this.property = data.property;
        this.totalAmount = data.totalAmount;
    }
}
export default Sale;
