import PropertyIsNotRegistered from '../PropertyIsNotRegistered';
import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import { LeaseholdersError } from '../leaseholders';
import { AmenitiesError, Amenity } from '../amenities';
import { PresaleLeaseholder } from './presalelLeaseholders';
import { Progress, ProgressError, PropertyProgressIsNotRegistered } from './progress';
class PresalePropertyAPI extends HundredBricksAPI {
    async getProgress(propertyId) {
        try {
            const { data } = await this.client.get(`v2/crowdfundingProperties/${propertyId}/progress`);
            return new Progress(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'PROGRESS_NOT_FOUND':
                        throw new PropertyProgressIsNotRegistered(propertyId, { cause: error });
                    default:
                        throw new ProgressError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new ProgressError('Unexpected property progress error', { cause: error });
            }
            else {
                throw new ProgressError(`Unexpected property progress error: '${error}'`);
            }
        }
    }
    async getLeaseholders(propertyId) {
        try {
            const { data } = await this.client.get(`v2/crowdfundingProperties/${propertyId}/leaseholders`);
            return data.items.map(leaseholder => new PresaleLeaseholder(leaseholder));
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'CROWDFUNDING_PROPERTY_NOT_FOUND':
                        throw new PropertyIsNotRegistered(propertyId, 'crowdfunding', { cause: error });
                    default:
                        throw new LeaseholdersError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new LeaseholdersError('Unexpected presale property leaseholders error', { cause: error });
            }
            else {
                throw new LeaseholdersError(`Unexpected presale property leaseholders error: '${error}'`);
            }
        }
    }
    async getAmenities(propertyId) {
        try {
            const { data } = await this.client.get(`v2/crowdfundingProperties/${propertyId}/amenities`);
            return data.items.map(amenity => this.buildPropertyAmenity(amenity));
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new AmenitiesError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new AmenitiesError('Unexpected presale property amenities error', { cause: error });
            }
            else {
                throw new AmenitiesError(`Unexpected presale property amenities error: '${error}'`);
            }
        }
    }
    buildPropertyAmenity(amenity) {
        return new Amenity({ ...amenity, property: amenity.crowdfundingProperty });
    }
}
export default PresalePropertyAPI;
