import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import defaultImage from '@assets/images/property-photo-placeholder.png';
import { RenderIf, Icon } from '@components';
import { useIsMobile } from '@hooks';
import { RESOLUTION_BREAKPOINT } from '@constants';
import { GalleryWrapper, MainImage, RemainingImageContainer, RemainingImage, ShowRemainingImagesDesktop, ShowRemainingImagesTablet } from './PropertyDetailGallery.style';
const MAXIMUM_NUMBER_OF_IMAGES_TO_DISPLAY = 4;
const PropertyDetailGallery = (props) => {
    const { propertyType, propertyId, images } = props;
    const navigate = useNavigate();
    const hasMinimumDesktopResolution = useIsMobile(RESOLUTION_BREAKPOINT.large);
    const [mainImage, ...remainingImages] = images;
    const openDetailGallery = () => {
        if (images.length) {
            navigate(`/marketplace/property/${propertyType}/categorized-gallery/${propertyId}`);
        }
    };
    return (_jsxs(GalleryWrapper, { numberOfImages: images.length, children: [_jsxs("div", { children: [_jsx(MainImage, { onClick: openDetailGallery, children: _jsx("img", { src: mainImage?.url ?? defaultImage, alt: "Imagen principal de la propiedad", width: "100%", height: "100%" }) }), _jsx(RenderIf, { condition: hasMinimumDesktopResolution && images.length > 1, children: _jsxs(ShowRemainingImagesTablet, { id: "propertyDetailGallery-showRemainingImagesDesktop", onClick: openDetailGallery, children: [_jsx(Icon, { name: "icGallery", alt: "Galer\u00EDa de propiedad", height: "17", width: "17" }), `Ver galería (${images.length})`] }) })] }), _jsx(RenderIf, { condition: Boolean(remainingImages.length), children: _jsxs(RemainingImageContainer, { numberOfRemainingImages: remainingImages.length, children: [remainingImages.map((image, index) => index < MAXIMUM_NUMBER_OF_IMAGES_TO_DISPLAY && (_jsx(RemainingImage, { onClick: openDetailGallery, children: _jsx("img", { src: image.url, alt: "Imagen de la propiedad", width: "100%", height: "100%" }) }, image.id))), _jsx(RenderIf, { condition: remainingImages.length > MAXIMUM_NUMBER_OF_IMAGES_TO_DISPLAY, children: _jsx(ShowRemainingImagesDesktop, { id: "propertyDetailGallery-showRemainingImagesTablet", onClick: openDetailGallery, children: `+${remainingImages.length - (MAXIMUM_NUMBER_OF_IMAGES_TO_DISPLAY - 1)}` }) })] }) })] }));
};
export default PropertyDetailGallery;
