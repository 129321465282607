import styled from 'styled-components';
import { Link } from 'react-router-dom';

import IconButton from '../IconButton';
import { flexAsRow } from '../Styled/mixins';

const UpgradeAccountBlinkingLight = styled.div`
 ::before, ::after {
      background-color: ${({ theme }) => theme.palette.warning.light};
      border-radius: ${({ theme }) => theme.borderRadius(4)};
      content: '';
      display: block;
      height: ${({ theme }) => theme.spacing(1)};
      position: absolute;
      right: -${({ theme }) => theme.spacing(1)};
      top: -${({ theme }) => theme.spacing(1)};
      width: ${({ theme }) => theme.spacing(1)};
    }

    ::after {
      animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    }

    @keyframes ping {
      75%, 100% {
        opacity: 0;
        transform: scale(2);
      }
    }
`;

const IconContainer = styled.div`
  ${flexAsRow(1.5)};

  position: absolute;
  justify-content: center;
  align-items: center;
  right: ${({ theme }) => theme.spacing(4)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    height: 100%;
    width: auto;
    top: 0;
    bottom: 0;
  }
`;

const LastSessionNotice = styled.small`
  position: fixed;
  width: 100%;
  top: ${({ theme }) => theme.spacing(7)};
  left: 50%;
  font-style: italic;
  text-align: center;
  color:  ${({ theme }) => theme.palette.alternative5.light};
  transform: translate(-50%, 0);
  z-index: 10000;

  > span {
    font-family: ${({ theme }) => theme.typography.title.h3.fontFamily};
    font-style: normal;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    transform: unset;
    left: unset;
    width: unset;
    right: ${({ theme }) => theme.spacing(4)};
  }
`;

const UserAvatar = styled(Link)`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    all: unset;

    ${flexAsRow(1)};

    cursor: pointer;
    justify-content: center;
    align-items: center;

    > img {
      width: ${({ theme }) => theme.spacing(4)};
      height: ${({ theme }) => theme.spacing(4)};
    }

    > p {
      margin: 0;
    }
  }
`;

const MarketplaceHeader = styled.header`
  ${flexAsRow()};

  position: fixed;
  background-color: ${({ theme }) => theme.palette.white};
  width: 100%;
  height: ${({ theme }) => theme.spacing(9.5)};
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 ${({ theme }) => theme.spacing(1)};
  z-index: 100;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grayC};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    justify-content: start;
  }

  > a {
    ${flexAsRow()}

    justify-content: center;
    width: ${({ theme }) => theme.spacing(18)};
  }
`;

const BurgerMenuButton = styled(IconButton)`
  display: block;
  position: absolute;
  left: ${({ theme }) => theme.spacing(4)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const Logo = styled.img`
  /* width: ${({ theme }) => theme.spacing(12.5)}; */
  height: 100%;

`;

const LogoutButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
`;

export {
  MarketplaceHeader,
  IconContainer,
  LastSessionNotice,
  BurgerMenuButton,
  UserAvatar,
  Logo,
  LogoutButton,
  UpgradeAccountBlinkingLight
};
