import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { toast } from '@components';
import { useProfile } from '@lib/profile';

const PendingDocumentsToast = () => {
  const { profile } = useProfile();
  const location = useLocation();

  const isReadyToSign = useMemo(
    () => (profile.status.isReadyToSignDocuments()
    || profile.status.isSigningDocuments())
    && !profile.hasRejectedDocs()
    && !profile.status.isReadyToSignRiskForm(),
    [profile]
  );

  const [showPendingDocumentsToast, setShowPendingDocumentsToast] = useState(isReadyToSign);

  if (location.pathname.includes('marketplace') && showPendingDocumentsToast) {
    toast.warning(
      'Tienes documentos pendientes por firmar'
    );

    setShowPendingDocumentsToast(false);
  }
};

export default PendingDocumentsToast;
