import styled from 'styled-components';

import { grid } from '@components/Styled/mixins';

const LeaseholdersWrapper = styled.div`
  ${grid(2)};

  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => `${theme.spacing(3)} 0 ${theme.spacing(2)}`};

  > img {
    width: 100%;
    object-fit: contain;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-columns: repeat(5, 1fr);
    gap: ${({ theme }) => theme.spacing(3)};
  }
`;

export { LeaseholdersWrapper };
