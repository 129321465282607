import React, {
  Fragment,
  useEffect,
  useMemo,
  useState
} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'bricks-ui/packages/bricks-ui';

import { PaymentPlansProvider } from '@lib/paymentPlans';
import { renderSomethingWentWrong } from '@utils';
import { LoadingOverlay, RenderIf } from '@components';
import { AuthProvider, useAuth } from '@lib/authentication';
import { ErrorReporter, useErrorReporter } from '@lib/errorReporter';
import { ProfileProvider, useProfile } from '@lib/profile';
import { investorSegmenter, Segmenter } from '@lib/segmentation';

import api from '../../api';
import state from '../../state';
import Notifications from '../Notifications';

import GeneralRoutes from './GeneralRoutes';

const props = { state, api };

const AppRoutes = () => {
  const [isInitializing, setIsInitializing] = useState(true);
  const errorReporter = useErrorReporter();
  const {
    token,
    isAuthenticated,
    isLoading: isLoadingAuthentication,
    error: loadingAuthenticationError
  } = useAuth();
  const {
    profile,
    isLoading: isLoadingProfile,
    error: profileError
  } = useProfile();

  const isLoading = isLoadingAuthentication || isLoadingProfile;
  const hasAuthenticatedProfile = isAuthenticated && Boolean(profile);

  useEffect(() => {
    if (!isLoadingAuthentication && !isLoadingProfile && profile) {
      setIsInitializing(false);
    } else if (!isAuthenticated && !token) {
      setIsInitializing(false);
    }
  }, [isLoadingAuthentication, isLoadingProfile, profile]);

  // Check if this is viable as the error from ProfileProvider is being handled here
  useEffect(() => {
    if (profileError) {
      // TODO: This should show a 500 error
      errorReporter.critical(profileError);
      renderSomethingWentWrong();
    }

    if (
      loadingAuthenticationError?.isUnexpectedError
      && loadingAuthenticationError?.isUnexpectedError()
    ) {
      // TODO: This should show a 500 error
      errorReporter.critical(loadingAuthenticationError);
      renderSomethingWentWrong();
    }
  }, [profileError, loadingAuthenticationError]);

  const component = useMemo(() => (
    <Fragment>
      <RenderIf condition={isInitializing}>
        <LoadingOverlay />
      </RenderIf>

      <RenderIf condition={!isInitializing}>
        <RenderIf condition={hasAuthenticatedProfile && !profile.isLegalPerson()}>
          <Notifications />
        </RenderIf>

        <GeneralRoutes hasAuthenticatedProfile={hasAuthenticatedProfile} {...props} />
      </RenderIf>
    </Fragment>
  ), [isAuthenticated, isInitializing, isLoading]);

  return component;
};

const Router = () => (
  <AuthProvider>
    <ProfileProvider>
      <ErrorReporter driver={process.env.ERROR_REPORTER_DRIVER || 'console'}>
        <PaymentPlansProvider>
          <BrowserRouter>
            <ThemeProvider theme="marketplace">
              <AppRoutes />
            </ThemeProvider>
          </BrowserRouter>
        </PaymentPlansProvider>
      </ErrorReporter>

      <Segmenter investorSegmenter={investorSegmenter} />
    </ProfileProvider>
  </AuthProvider>
);

export default Router;
