import styled, { css } from 'styled-components';

import { flexAsColumn, flexAsRow } from '@components/Styled/mixins';

const CategorizedGalleryWrapper = styled.div`
  ${flexAsColumn(1)};

  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.white};
  padding: ${({ theme }) => theme.spacing(2)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;

const TabWrapper = styled.div`
  ${flexAsRow()};

  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  border-bottom: ${({ theme }) => `${theme.toRem(1)} solid ${theme.palette.grayC}`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    justify-content: unset;
    margin-bottom: ${({ theme }) => theme.spacing(3)};
    gap: ${({ theme }) => theme.spacing(6)};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    gap: ${({ theme }) => theme.spacing(10)};
  }
`;

const Tab = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 200ms ease;
  padding: ${({ theme }) => `${theme.spacing(2)} 0`};
  position: relative;

  ${({ isActive, theme }) => isActive && css`
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: ${theme.spacing(5)};
      height: ${theme.toRem(2)};
      background: ${theme.palette.primary.main};
      left: 0;
      bottom: 0;
    }
  `}
`;

export { CategorizedGalleryWrapper, Tab, TabWrapper };
