/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon } from '@components';

import setPagesToShow from './pages';
import './style.scss';

const Pagination = ({
  totalPages = 0,
  currentPage,
  goToNextPage,
  goToPage,
  goToPreviousPage,
  className,
}) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;
  const pagesToShow = setPagesToShow(currentPage, totalPages);

  const paginationStyleName = cx({
    container: true,
    isOnePage: totalPages <= 1,
  });

  const goPreviousStyleName = cx({
    button: true,
    show: !isFirstPage,
  });

  const goNextStyleName = cx({
    button: true,
    show: !isLastPage,
  });

  return (
    <div styleName={paginationStyleName} className={className}>
      <div styleName="pages">
        <div
          role="button"
          onClick={goToPreviousPage}
          styleName={goPreviousStyleName}
          tid="go-previous-page"
        >
          <Icon name="icPreviousPage" alt="go-previous-page" />
        </div>
        {
          pagesToShow.map(page => {
            const isLastPageToShow = page === pagesToShow[pagesToShow.length - 1];
            const pageStyleName = cx({
              page: true,
              isActive: page === currentPage,
              isLastPageToShow,
            });

            return (
              <div
                styleName={pageStyleName}
                key={page}
                onClick={() => goToPage(page)}
                tid="page-item"
                role="button"
              >
                {page}
              </div>
            );
          })
        }
        <div
          role="button"
          onClick={goToNextPage}
          styleName={goNextStyleName}
          tid="go-next-page"
        >
          <Icon name="icNextPage" alt="go-next-page" />
        </div>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  className: '',
};

Pagination.propTypes = {
  className: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
};

export default Pagination;
