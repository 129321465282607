import HundredBricksAPI from '@lib/hundredBricksAPI/HundredBricksAPI';
import CrowdfundingInvestment from '@api/entities/CrowdfundingInvestment';
import { APIError } from '@lib/api';
import { Pagination } from '@api/entities';
import BricksError from './BricksError';
import BricksOnSale from './BricksOnSale';
import BricksSold from './BricksSold';
import CompletedSale from './CompletedSale';
import InvalidInvestorId from './InvalidInvestorId';
import InvestorDoesNotExists from './InvestorDoesNotExists';
import Sale from './Sale';
import BricksInPreSale from './BricksInPreSale';
class BricksAPI extends HundredBricksAPI {
    async getBricksInPreSale() {
        try {
            const { data } = await this.client.get('/v1/traders/me/investments');
            return new BricksInPreSale(data.map(item => new CrowdfundingInvestment(item)));
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'TRADER_DOES_NOT_EXISTS':
                        throw new InvestorDoesNotExists({ cause: error });
                    case 'INVALID_ID':
                        throw new InvalidInvestorId({ cause: error });
                    default:
                        throw new BricksError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new BricksError('Could not obtain bricks in pre-sale', { cause: error });
            }
            else {
                throw new BricksError(`Unexpected error occurred obtaining bricks in pre-sale: '${error}'`);
            }
        }
    }
    async getBricksOnSale(params) {
        try {
            const { data } = await this.client
                .get('/v1/traders/me/bricks/onSale', { params });
            return new BricksOnSale({
                items: data.items.map(brickOnSale => new Sale(brickOnSale)),
                count: data.totalItems,
                pagination: new Pagination(
                // THIS MUST BE UPDATE WHEN "/v1/traders/me/bricks/onSale" IS UPGRADED TO API V2
                {
                    totalPages: data.totalPages,
                    perPage: data.perPage,
                    page: data.page
                })
            });
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_ID':
                        throw new InvalidInvestorId({ cause: error });
                    default:
                        throw new BricksError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new BricksError('Could not obtain bricks on sale', { cause: error });
            }
            else {
                throw new BricksError(`Unexpected error occurred obtaining bricks on sale: '${error}'`);
            }
        }
    }
    async getBricksSold(params) {
        try {
            const { data } = await this.client
                .get('/v1/traders/me/soldBricks', { params });
            return new BricksSold({
                soldBricks: data.soldBricks.map(brickSold => new CompletedSale(brickSold)),
                count: data.total,
                pagination: new Pagination({
                    // This hardcoded values are expecting to be replaced in a new endpoint version,
                    // the current one works with pagination but only returns total
                    totalPages: 0,
                    perPage: 10,
                    page: 1
                })
            });
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_ID':
                        throw new InvalidInvestorId({ cause: error });
                    default:
                        throw new BricksError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new BricksError('Could not obtain bricks sold', { cause: error });
            }
            else {
                throw new BricksError(`Unexpected error occurred obtaining bricks sold: '${error}'`);
            }
        }
    }
}
export default BricksAPI;
