import React from 'react';
import PropTypes from 'prop-types';

import { FormWrapper } from './Form.styled';

const Form = ({ children, onSubmit, className }) => {
  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <FormWrapper className={className} onSubmit={handleSubmit}>
      {children}
    </FormWrapper>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onSubmit: () => {}
};

Form.defaultProps = {
  className: null,
  onSubmit: () => {}
};

export default Form;
