const errorMap = (code, errorProps) => {
  const DEFAULT_ERROR = 'Error desconocido.';
  const { maximumBricksToBuy, minimumBricksToBuy } = errorProps;

  const errors = {
    BRICK_NUMBER_EXCEEDS_AVAILABLE: '¡No tenemos tantos Ladrillos!',
    INVESTMENT_LIMIT_AMOUNT_EXCEEDED: `Tu máximo de compra para esta propiedad es
de ${maximumBricksToBuy} Ladrillos.`,
    BRICK_NUMBER_UNDER_MINIMUM: `El mínimo de compra para esta propiedad es
de ${minimumBricksToBuy} Ladrillos.`,
    BRICK_NUMBER_EXCEEDS_MAXIMUM: `Tu máximo de compra para esta propiedad
es de ${maximumBricksToBuy} Ladrillos.`,
    PROPERTY_HAS_NO_AVAILABLE_BRICKS: 'Esta propiedad no cuenta con Ladrillos disponibles',
  };

  return errors[code] || DEFAULT_ERROR;
};

export default errorMap;
