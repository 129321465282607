const IMAGE_CATEGORIES = {
    exterior: 'exterior',
    interior: 'interior',
    other: 'other'
};
const categoryTabs = (propertyImages) => {
    if (!propertyImages)
        return [];
    const searchImagesByCategory = (category) => propertyImages.filter(image => image.tag === category);
    const exteriorImages = searchImagesByCategory(IMAGE_CATEGORIES.exterior);
    const interiorImages = searchImagesByCategory(IMAGE_CATEGORIES.interior);
    const otherImages = searchImagesByCategory(IMAGE_CATEGORIES.other);
    const buildCategoryTabs = [
        {
            tab: 'allImages',
            name: `Todas (${propertyImages.length})`,
            images: propertyImages
        },
        {
            tab: 'exteriorImages',
            name: `Exterior (${exteriorImages.length})`,
            images: exteriorImages
        },
        {
            tab: 'interiorImages',
            name: `Interior (${interiorImages.length})`,
            images: interiorImages
        },
        {
            tab: 'otherImages',
            name: `Otras (${otherImages.length})`,
            images: otherImages
        }
    ];
    return buildCategoryTabs.filter(tab => tab.images.length);
};
export default categoryTabs;
