import React from 'react';
import styled, { css } from 'styled-components';

import IconButton from '../IconButton';
import LegacyLink from '../LegacyLink';

const responsiveDisplay = ({ mobile, desktop }) => css`
  display: ${mobile};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: ${desktop};
  }
`;

const CoverImageMobile = styled.img`
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const NavbarWrapper = styled.div`
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  grid-area: navbar;
  justify-content: space-between;
  position: sticky;
  z-index: 1;

  ${({ theme }) => css`
    box-shadow: inset 0 -1px 0 0 ${theme.palette.grayC};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};

    @media screen and (min-width: ${theme.breakpoints.large}px) {
      background: ${theme.palette.background.hbBackgroundA};
    }
  `}
`;

const LogoWrapper = styled(props => <LegacyLink {...props} />)`
  ${responsiveDisplay({ mobile: 'none', desktop: 'block' })};

  > img {
    padding-left: ${({ theme }) => theme.spacing(4)};
  }
`;

const MenuButton = styled(IconButton)`
  background-color: transparent;

  ${responsiveDisplay({ mobile: 'block', desktop: 'none' })}

  &:hover {
    background-color: transparent;
  }
`;

const MobileLogoWrapper = styled(props => <LegacyLink {...props} />)`
  z-index: 1;
  margin: 0 auto;
  ${responsiveDisplay({ mobile: 'block', desktop: 'none' })};

  > img {
    width: ${({ theme }) => theme.spacing(4)};
  }
`;

export {
  CoverImageMobile,
  LogoWrapper,
  MenuButton,
  MobileLogoWrapper,
  NavbarWrapper
};
