import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link, RenderIf } from '@components';
import { PropertyLog } from '@api/entities';
import { regularExpressions as regex } from '@utils';
import defaultPhoto from '@assets/images/default-building-image.png';

import {
  ButtonWrapper,
  DescriptionContainer,
  IconArrowWrapper,
  ImageContainer,
  ImageWrapper,
  LogCardDateTitle,
  LogCardWrapper,
  TitleDate
} from './LogCard.style';

const formattedData = date => {
  if (!date) return '';

  const currentDate = new Date(date);

  const day = currentDate.getUTCDate();
  const month = currentDate.toLocaleDateString('es-ES', { month: 'short', timeZone: 'UTC' });
  const year = currentDate.getUTCFullYear();

  return `${day} ${month}. ${year}`;
};

const LogCard = ({ propertyLog }) => {
  const {
    description,
    photos,
    publishedAt,
    title
  } = propertyLog;

  const [showDescription, setShowDescription] = useState(false);
  const [paragraphs, setParagraphs] = useState(null);

  useEffect(() => {
    if (description) {
      const numberOfParagraphs = description.split(regex.allWithoutLineBreak);
      const noEmptyParagraphs = numberOfParagraphs.filter(paragraph => paragraph !== '');

      setParagraphs(noEmptyParagraphs);
    }
  }, []);

  return (
    <LogCardWrapper>
      <LogCardDateTitle>
        <TitleDate>{title ?? '---'}</TitleDate>

        <p>{formattedData(publishedAt)}</p>
      </LogCardDateTitle>

      <RenderIf condition={Boolean(paragraphs)}>
        <DescriptionContainer isShowDescription={showDescription}>
          {paragraphs && paragraphs.map(paragraph => <p key={paragraph}>{paragraph}</p>)}

          <RenderIf condition={Boolean(photos)}>
            <ImageContainer>
              {photos && photos.map(photo => (
                <Link
                  key={photo.url}
                  href={photo.url}
                  target="_blank"
                >
                  <ImageWrapper
                    alt="Foto de la propiedad"
                    src={photo.url ?? defaultPhoto}
                    decoding="async"
                    loading="lazy"
                  />
                </Link>
              ))}
            </ImageContainer>
          </RenderIf>
        </DescriptionContainer>
      </RenderIf>

      <RenderIf condition={Boolean(!paragraphs)}>
        <DescriptionContainer isShowDescription={showDescription}>
          <p>Sin Información</p>
        </DescriptionContainer>
      </RenderIf>

      <ButtonWrapper
        id="show-description"
        aria-label="Mostrar descripción"
        onClick={() => setShowDescription(prevOpenMenu => !prevOpenMenu)}
      >
        {showDescription ? 'Ver menos' : 'Ver más'}
        <IconArrowWrapper
          alt="Ver más"
          isShowDescription={showDescription}
          name="icArrowOutlinedBlue"
          width={12}
        />
      </ButtonWrapper>
    </LogCardWrapper>
  );
};

LogCard.propTypes = {
  propertyLog: PropTypes.instanceOf(PropertyLog).isRequired
};

export default LogCard;
