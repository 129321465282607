import React from 'react';
import PropTypes from 'prop-types';

import BrandLogoWhite from '@assets/images/100-ladrillos-logo-white.svg';
import { Footer } from '@components';

import './style.scss';

const PublicLayout = props => {
  const { children, title } = props;

  return (
    <div styleName="layout-container">
      <section styleName="layout-content">
        <div styleName="left-section">
          <h1 styleName="header-text">
            <span>V</span>ive de tus rentas
          </h1>
          <a href="https://www.100ladrillos.com/" styleName="brand-logo-container">
            <img src={BrandLogoWhite} alt="100 Ladrillos" styleName="brand-logo" />
          </a>
        </div>
        <div styleName="content-container">
          <div styleName="title">
            <h2>{title}</h2>
            <hr />
          </div>
          <div styleName="content-children">
            {children}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

export default PublicLayout;
