import { Antiphishing } from '../entities/Authentication';
import Session, { Geolocation, Token } from '../entities/Session';
import AuthenticationError, { Details } from '../errors/Authentication';
import errorMessages from '../errors/Authentication/errorMessages';

import GatewayAPI from './GatewayAPI';

class AuthenticationAPI extends GatewayAPI {
  async fetchAntiphishing(email) {
    try {
      const { data } = await this._client.get('/profile/antiphishing', { params: { email } });

      return new Antiphishing(data);
    } catch (error) {
      if (!error.response) {
        throw AuthenticationError.getNetworkError();
      }

      const { response } = error;
      const { code, metadata } = response.data;

      const authenticationError = new AuthenticationError(code);

      if (authenticationError.isTraderNotFound()) {
        authenticationError.details = new Details({
          message: errorMessages[code], ...metadata
        });
      }

      throw authenticationError;
    }
  }

  async login(email, password) {
    try {
      const { data } = await this._client.post('/login', { email, password });

      // Expiration date returned by the API is ignored due possible users missconfigurations.
      // In some scenarios, users may have a wrong timezone configures on his/her device, in such
      // cases the application will expire the session inmediatly
      const expirationDate = new Date();

      expirationDate.setMinutes(expirationDate.getMinutes() + 5);

      const token = new Token(data.token, expirationDate);
      const geolocation = new Geolocation(data.geolocalization);

      return new Session(data.traderId, token, geolocation);
    } catch (error) {
      if (!error.response) {
        throw AuthenticationError.getNetworkError();
      }

      const { response } = error;
      const { code, metadata } = response.data;

      const authenticationError = new AuthenticationError(code);

      if (
        authenticationError.isIncorrectPassword()
        || authenticationError.hasFailedAttemptsLock()
      ) {
        authenticationError.details = new Details({
          message: errorMessages[code], ...metadata
        });
      }

      throw authenticationError;
    }
  }

  async unlockUnusualAccess(traderId, password, code) {
    try {
      const { data } = await this._client.post('/unblockTrader', { traderId, password, code });

      const token = new Token(data.token, data.expiresAt);
      const geolocalization = new Geolocation(data.geolocalization);

      return new Session(data.traderId, token, geolocalization);
    } catch (error) {
      if (!error.response) {
        throw AuthenticationError.getNetworkError();
      }

      const { response } = error;
      const { code: codeError } = response.data;

      const authenticationError = new AuthenticationError(codeError);

      throw authenticationError;
    }
  }

  async logout() {
    try {
      await this._client.post('/logout');
    } catch (error) {
      this._handleError(error);
    }
  }

  async refreshToken() {
    try {
      const { data } = await this._client.post('/refreshSession');

      // Expiration date returned by the API is ignored due possible users missconfigurations.
      // In some scenarios, users may have a wrong timezone configures on his/her device, in such
      // cases the application will expire the session inmediatly
      const expirationDate = new Date();

      expirationDate.setMinutes(expirationDate.getMinutes() + 5);

      return new Token(data.token, expirationDate);
    } catch (error) {
      return this._handleError(error);
    }
  }

  // Add ping to new API
  //
  // GET /sessions/ping
  //
  // {
  //   "serverTime": <Server time in ISO8601 in UTC>
  // }
  //
  // For now, we are querying the trader profile just to see if the token is still valid
  async ping() {
    try {
      await this._client.get('/traders/me');
    } catch (error) {
      this._handleError(error);
    }
  }

  async sendRecoverPasswordEmail(payload) {
    try {
      const { data } = await this._legacyClient.post(
        '/forgotPassword/sendForgotPasswordCode',
        payload
      );

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async updateForgottenPassword(payload) {
    try {
      const { data } = await this._legacyClient.post('/forgotPassword', payload);

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError(error) {
    if (!error.response) {
      throw AuthenticationError.getNetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new AuthenticationError(code);
  }
}

export default AuthenticationAPI;
