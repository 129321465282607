import React, { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { useProfile } from '@lib/profile';

import NotFound from '../../NotFound';
import AppLayout from '../../../components/AppLayout';
import UnexpectedError from '../../UnexpectedError';

const ConfirmAccountData = lazy(
  () => import('../../UpgradeAccount/ConfirmAccountData/ConfirmAccountData')
);
const InvestmentCancellation = lazy(() => import('../../InvestmentCancellation'));
const PendingVerifyEmail = lazy(() => import('../../Enrollment/Email/PendingVerifyEmail'));
const SignW8BENContract = lazy(() => import('../../SignDocuments/W8BENContract'));
const TransactionalProfile = lazy(() => import('../../TransactionalProfile'));

const RedirectToHomePage = () => {
  const { profile, enrollment } = useProfile();

  if (profile && enrollment.isEnrolling()) {
    return <Navigate to="/enrollment" />;
  }

  return <Navigate to="/marketplace/presale" />;
};

const IndependentRoutes = () => (
  <Routes>
    <Route element={<RedirectToHomePage />}>
      <Route path="/" />
      <Route path="/signup" />
      <Route path="/unusual-access-unblock" />
    </Route>

    <Route path="/pending-verify-email" element={<PendingVerifyEmail />} />
    <Route path="/confirm-account-data" element={<ConfirmAccountData />} />
    <Route path="/sign-w8-ben-contract" element={<SignW8BENContract />} />
    <Route path="/update-transactional-profile" element={<TransactionalProfile />} />

    <Route element={<AppLayout />}>
      <Route path="/investment-cancellation" element={<InvestmentCancellation />} />
      <Route path="/unexpected-error" element={<UnexpectedError />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export default IndependentRoutes;
