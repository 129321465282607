import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';

const TableColumn = props => {
  const {
    id,
    children,
    size,
    type,
    isHeaderColumn
  } = props;

  const columnStyle = cx({
    'header-column': isHeaderColumn,
    'body-column': !isHeaderColumn,
    'column-xl': size === 'xl',
    'column-lg': size === 'lg',
    'column-md': size === 'md',
    'column-sm': size === 'sm',
    [type]: true
  });

  const getHeaderColumn = () => <th id={id} styleName={columnStyle}>{children}</th>;

  const getBodyColumn = () => <td id={id} styleName={columnStyle}>{children}</td>;

  return isHeaderColumn ? getHeaderColumn() : getBodyColumn();
};

TableColumn.defaultProps = {
  size: '',
  type: 'text',
  isHeaderColumn: false,
  children: []
};

TableColumn.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  isHeaderColumn: PropTypes.bool,
  children: PropTypes.node
};

export default TableColumn;
