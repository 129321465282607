import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LoadingOverlay } from '@components';

import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

const GeneralRoutes = props => {
  const { hasAuthenticatedProfile } = props;
  const [hadActiveSession, setHadActiveSession] = useState(false);

  useEffect(() => {
    if (hasAuthenticatedProfile) setHadActiveSession(true);
  }, [hasAuthenticatedProfile]);

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <PublicRoutes
        hadActiveSession={hadActiveSession}
        isAuthenticated={hasAuthenticatedProfile}
        {...props}
      />

      {hasAuthenticatedProfile && <PrivateRoutes />}
    </Suspense>
  );
};

GeneralRoutes.propTypes = {
  hasAuthenticatedProfile: PropTypes.bool
};

GeneralRoutes.defaultProps = {
  hasAuthenticatedProfile: false
};

export default GeneralRoutes;
