import styled, { css } from 'styled-components';

const Section = styled.section`
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
`;

const AppLayoutWrapper = styled(Section)`
  overflow: hidden;
`;

const MainContainer = styled(Section)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.grayA};
`;

const MobileOverlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  bottom: 0;
  content: '';
  z-index: 3;
  transition: 0.2s;
  background-color: ${({ theme }) => `rgba(${theme.palette.grayI}, 0.9)`};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    ${({ isOpen }) => isOpen && css`
      display: block;
      left: 0;
      right: 0;
    `};
  }
`;

const ScrollContent = styled.div`
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  top: ${({ theme }) => theme.spacing(9)};
  left: ${({ theme }) => theme.spacing(18)};
  background-color: ${({ theme }) => theme.palette.grayA};
  overflow: scroll;
  scroll-behavior: smooth;

  #page-content {
    margin: auto;
    max-width: ${({ theme }) => theme.spacing(175)};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    left: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    > :nth-child(2) {
      position: relative;
    }
  }
`;

export {
  AppLayoutWrapper,
  MainContainer,
  MobileOverlay,
  ScrollContent
};
