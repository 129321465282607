import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from '@lib/authentication';
import SessionExpiredModal from './SessionExpiredModal';
import SessionExpiringSoonModal from './SessionExpiringSoonModal';
const FREE_NAVIGATION_TIME_TO_EXPIRE = 240_000;
const REMAINING_SESSION_TIME_TO_EXPIRE = 60_000;
const SessionTimeManager = () => {
    const { refresh, token } = useAuth();
    const [isDisplaySessionExpiredModal, setIsDisplaySessionExpiredModal] = useState(false);
    const [isDisplaySessionExpiringSoonModal, setIsDisplaySessionExpiringSoonModal] = useState(false);
    const onPrompt = () => {
        setIsDisplaySessionExpiringSoonModal(true);
    };
    const onIdle = () => {
        setIsDisplaySessionExpiringSoonModal(false);
        setIsDisplaySessionExpiredModal(true);
    };
    const idleTimer = useIdleTimer({
        onPrompt,
        onIdle,
        timeout: FREE_NAVIGATION_TIME_TO_EXPIRE,
        promptTimeout: REMAINING_SESSION_TIME_TO_EXPIRE,
        stopOnIdle: true,
        events: [],
        throttle: 500
    });
    const onSessionExpired = () => {
        setIsDisplaySessionExpiringSoonModal(false);
        setIsDisplaySessionExpiredModal(true);
    };
    const onContinueBrowsing = async () => {
        setIsDisplaySessionExpiringSoonModal(false);
        await refresh();
        idleTimer.reset();
    };
    if (token && token?.expirationTimeInMilliseconds > 0 && idleTimer) {
        idleTimer.activate();
    }
    useEffect(() => {
        const interval = setInterval(() => {
            // this part is when the device is sleep or the screen become sleep
            if (idleTimer.getRemainingTime() <= 0 && !isDisplaySessionExpiredModal) {
                setIsDisplaySessionExpiredModal(true);
            }
            else if (idleTimer.getRemainingTime() / 1000 === 60 && !isDisplaySessionExpiringSoonModal) {
                setIsDisplaySessionExpiringSoonModal(true);
            }
        }, 5_000);
        return () => {
            clearInterval(interval);
        };
    }, [idleTimer]);
    return (_jsxs(Fragment, { children: [isDisplaySessionExpiringSoonModal && (_jsx(SessionExpiringSoonModal, { onContinueBrowsing: onContinueBrowsing, onSessionExpired: onSessionExpired })), _jsx(SessionExpiredModal, { isOpen: isDisplaySessionExpiredModal })] }));
};
export default SessionTimeManager;
