import React, { Fragment } from 'react';

import SessionTimeManager from '@lib/SessionTimeManager';
import { LastSessionInformer } from '@components/Header/components';

import Antiphishing from './AntiphishingModal';
import PendingPaymentPlanModal from './PendingPaymentPlanModal';
import PendingDocumentsToast from './PendingDocumentsToast';
import RejectedDocsModal from './RejectedDocsModal';

const Notifications = () => (
  <Fragment>
    <SessionTimeManager />

    <PendingDocumentsToast />

    <Antiphishing />

    <RejectedDocsModal />

    <LastSessionInformer />

    <PendingPaymentPlanModal />
  </Fragment>
);

export default Notifications;
