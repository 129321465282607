import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Icon,
  Modal,
  Text,
  Title
} from '@components';

import { convertNumberToCurrencyString } from '@utils';

import InfoWrapper from './ExpiredCouponModal.style';

const ExpiredCouponModal = ({ handleClose, isOpen, promotionalCoupon }) => {
  const {
    bricksQuantity,
    property,
    unitPrice
  } = promotionalCoupon;

  const totalPrice = unitPrice * bricksQuantity;

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Title>Cupón expiró</Title>

      <InfoWrapper>
        <Icon name="icCouponMoney" alt="Cupón expiró" height={84} width={84} />

        <Text>
          Lamentablemente tu cupón de
          {' '}
          <strong>{bricksQuantity}</strong>
          {' '}
          Ladrillos por el total de
          {' '}
          <strong>{convertNumberToCurrencyString(totalPrice)}</strong>
          {' '}
          en
          {' '}
          <strong>{property?.name}</strong>
          {' '}
          <span>ha expirado</span>
        </Text>
      </InfoWrapper>

      <Button id="continue" onClick={handleClose}>
        Entiendo
      </Button>
    </Modal>
  );
};

ExpiredCouponModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  promotionalCoupon: PropTypes.shape({
    bricksQuantity: PropTypes.number.isRequired,
    unitPrice: PropTypes.number.isRequired,
    property: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default ExpiredCouponModal;
