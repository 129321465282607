import React, { useState } from 'react';
import PropTypes from 'prop-types';

import logo from '@assets/images/100-ladrillos-logo.svg';

import Icons from '../Icons';
import PublicNavbar from '../PublicNavbar';

import {
  LogoWrapper,
  MenuButton,
  MobileLogoWrapper,
  NavbarWrapper
} from './Navbar.style';

const HOME_PAGE = 'https://app.100ladrillos.com/';

const Navbar = ({ menu }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const MenuIcon = openMenu ? Icons.CrossIcon : Icons.HamburgerMenuIcon;

  return (
    <NavbarWrapper>
      <MenuButton
        id="menu-button"
        aria-label="Abrir menú"
        disableFocus
        onClick={() => setOpenMenu(prevOpenMenu => !prevOpenMenu)}
      >
        <MenuIcon color="#fff" />
      </MenuButton>

      <LogoWrapper to={HOME_PAGE} external>
        <img src={logo} alt="100 Ladrillos" />
      </LogoWrapper>
      <MobileLogoWrapper to={HOME_PAGE} external>
        <img src={logo} alt="100 Ladrillos" />
      </MobileLogoWrapper>

      <PublicNavbar items={menu} opened={openMenu} />
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string
  }))
};

Navbar.defaultProps = {
  menu: {}
};

export default Navbar;
