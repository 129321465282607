class Antiphishing {
    fullName;
    imageUrl;
    phrase;
    constructor(data) {
        this.fullName = data.fullName;
        this.imageUrl = data.image;
        this.phrase = data.phrase;
    }
}
export default Antiphishing;
