import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import { Image, Lightbox, RenderIf } from '@components';
import { GalleryWrapper, ImageContainer } from './ImageGallery.style';
const MAXIMUM_NUMBER_OF_ELEMENTS_FOR_REPEATING_GALLERY_LAYOUT = 4;
const ImageGallery = ({ propertyImages }) => {
    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const buildArrayContainer = (images, arraySize) => {
        const imageArrayContainer = [];
        for (let i = 0; i < images.length; i += arraySize) {
            imageArrayContainer.push(images.slice(i, i + arraySize));
        }
        return imageArrayContainer;
    };
    const buildImages = buildArrayContainer(propertyImages || [], MAXIMUM_NUMBER_OF_ELEMENTS_FOR_REPEATING_GALLERY_LAYOUT);
    return (_jsxs(Fragment, { children: [buildImages.map(image => (_jsx(GalleryWrapper, { "data-images": image.length, children: image?.map(({ id, url }) => (_jsx(ImageContainer, { onClick: () => setIsOpenLightbox(true), children: url && _jsx(Image, { src: url, alt: "Imagen de la propiedad" }) }, id))) }, buildImages.indexOf(image)))), _jsx(RenderIf, { condition: propertyImages, children: _jsx(Lightbox, { slides: propertyImages?.map(image => image.url), isOpen: isOpenLightbox, onClose: () => setIsOpenLightbox(false), displayProgress: true }) })] }));
};
export default ImageGallery;
