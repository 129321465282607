/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import { AccountLockCodes } from '@types';
import { HelperText } from '@components/Styled';
import { CompleteProfile } from '@views/Marketplace/components';

import Button from '../../../Button';
import Icon from '../../../Icon';
import TextField from '../../../TextField';

import generateQuotationDetails from './generateQuotationDetails';
import errorMap from './errorMap';
import './style.scss';

const Quotation = props => {
  /** The dependency below is causing dependency cycles, for this reason the dependency is loaded
    * via require. Please, always avoid the use of require() calls in any other place under this
    * codebase unless you have a good reason to do so.
    *
    * We need to move the api module to the @lib module in order to remove the dependency cycle
    */
  const { useProfile } = require('@lib/profile');

  const {
    availableBricks,
    bricksQuantity,
    handleChangeBricksQuantity,
    handleClickAcceptButton,
    isDisabledAcceptButton,
    isLegacyAndLegalTrader,
    isLoadingAcceptButton,
    maximumBricksToBuy = 0,
    minimumBricksToBuy,
    profileCompleted,
    quotation,
    quotationError = '',
    trader
  } = props;
  const {
    retailPricePerBrick,
    quantity,
    totalRetailPrice,
    presaleDiscountPercentagePerBrick,
    presaleDiscountTotalSavings,
    volumeDiscountPercentagePerBrick,
    volumeDiscountTotalSavings,
    totalPaid
  } = quotation;

  const { profile } = useProfile();

  const canBuy = profile.canBuy();
  const hasValuesQuotation = Object.keys(quotation).length > 0;
  const isVisibleLegacyAndLegalTrader = isLegacyAndLegalTrader && !canBuy;
  const isVisibleProfileCompleted = profileCompleted
    && !canBuy
    && !profile.status.isReadyToSignRiskForm();
  const isVisibleIncompleteProfile = !profileCompleted && !isLegacyAndLegalTrader && !canBuy;
  const isLessThanMinimumBricksToBuy = bricksQuantity < minimumBricksToBuy;
  const isGreaterThanMaximumBricksToBuy = bricksQuantity > maximumBricksToBuy;
  const areVisibleNotes = isLessThanMinimumBricksToBuy || isGreaterThanMaximumBricksToBuy;
  const isInTheRange = bricksQuantity >= minimumBricksToBuy && bricksQuantity <= maximumBricksToBuy;
  const isVisibleAcceptButton = isInTheRange
    && !quotationError
    && bricksQuantity !== 0
    && hasValuesQuotation
    && (profile.status.isReadyToSignRiskForm() || canBuy);
  const errorProps = {};

  const isBricksQuantityFieldDisabled = () => {
    const { lockCode = '' } = trader;
    const { isSoftLockCode, isShadowLockCode } = new AccountLockCodes();

    return !availableBricks
      || isSoftLockCode(lockCode)
      || isShadowLockCode(lockCode);
  };

  if (quotationError) {
    errorProps.maximumBricksToBuy = maximumBricksToBuy;
    errorProps.minimumBricksToBuy = minimumBricksToBuy;
  }

  return (
    <div styleName="quotation">
      <div tid="bricks-quantity-field" styleName="bricks-quantity-field">
        <TextField
          id="bricks-quantity"
          tid="bricks-quantity"
          styleName="input"
          type="text"
          label="# Ladrillos"
          value={bricksQuantity ? String(bricksQuantity) : ''}
          onChange={handleChangeBricksQuantity}
          disabled={isBricksQuantityFieldDisabled()}
          placeholder={maximumBricksToBuy.toString()}
          error={Boolean(quotationError)}
          helperText={quotationError && errorMap(quotationError, errorProps)}
          required
          inputProps={{
            name: 'bricks-quantity',
            autoComplete: 'off',
            'aria-label': 'Número de Ladrillos'
          }}
        />
      </div>
      {areVisibleNotes && (
        <div tid="quoter-notes" styleName="quoter-notes">
          <HelperText tid="minimum-bricks-to-buy">
            {`* Compra mínima de Ladrillos: ${minimumBricksToBuy}`}
          </HelperText>
          <HelperText tid="maximum-bricks-to-buy">
            {`* Tu compra máxima de Ladrillos: ${maximumBricksToBuy}`}
          </HelperText>
        </div>
      )}
      {hasValuesQuotation && generateQuotationDetails({
        retailPricePerBrick,
        quantity,
        totalRetailPrice,
        presaleDiscountPercentagePerBrick,
        presaleDiscountTotalSavings,
        volumeDiscountPercentagePerBrick,
        volumeDiscountTotalSavings,
        totalPaid
      })}
      {isVisibleAcceptButton && (
        <Button
          id="accept-button"
          tid="accept-button"
          rounded
          styleName="accept-button"
          isLoading={isLoadingAcceptButton}
          disabled={isDisabledAcceptButton || isLoadingAcceptButton}
          onClick={handleClickAcceptButton}
        >
          Comprar
        </Button>
      )}
      {isVisibleLegacyAndLegalTrader && (
        <div styleName="info" tid="legacy-and-legal-trader">
          <div>
            <Icon name="icWarningBlue" alt="warning" />
            <small>Por el momento nuestros Ladrillos no</small>
          </div>
          <small>
            están disponibles para
            {' '}
            <strong>personas morales</strong>
          </small>
        </div>
      )}
      {isVisibleProfileCompleted && (
        <div styleName="info" tid="profile-completed">
          <small>Ya casi puedes comprar, estamos procesando tu información</small>
        </div>
      )}
      {isVisibleIncompleteProfile && (
        <div tid="incomplete-profile">
          <CompleteProfile />
        </div>
      )}
    </div>
  );
};

Quotation.defaultProps = {
  availableBricks: 0,
  isDisabledAcceptButton: false,
  isLoadingAcceptButton: false,
  quotation: {}
};

Quotation.propTypes = {
  availableBricks: PropTypes.number,
  bricksQuantity: PropTypes.number.isRequired,
  handleChangeBricksQuantity: PropTypes.func.isRequired,
  handleClickAcceptButton: PropTypes.func.isRequired,
  isDisabledAcceptButton: PropTypes.bool,
  isLoadingAcceptButton: PropTypes.bool,
  isLegacyAndLegalTrader: PropTypes.bool.isRequired,
  maximumBricksToBuy: PropTypes.number.isRequired,
  minimumBricksToBuy: PropTypes.number.isRequired,
  profileCompleted: PropTypes.bool.isRequired,
  quotationError: PropTypes.string.isRequired,
  quotation: PropTypes.shape({
    presaleDiscountPercentagePerBrick: PropTypes.number,
    presaleDiscountTotalSavings: PropTypes.number,
    quantity: PropTypes.number,
    retailPricePerBrick: PropTypes.number,
    totalPaid: PropTypes.number,
    totalRetailPrice: PropTypes.number,
    volumeDiscountPercentagePerBrick: PropTypes.number,
    volumeDiscountTotalSavings: PropTypes.number
  }),
  trader: PropTypes.shape({
    canBuy: PropTypes.bool,
    lockCode: PropTypes.string
  }).isRequired
};

export default Quotation;
