import React from 'react';
import PropTypes from 'prop-types';
import {
  Step as BuiStep,
  StepContent as BuiStepContent,
  StepHeader as BuiStepHeader,
  Stepper as BuiStepper
} from 'bricks-ui/packages/bricks-ui';

const Stepper = props => {
  const {
    ActionsComponent,
    activeStepIndex,
    steps
  } = props;

  const activeStep = steps[activeStepIndex];

  return (
    <BuiStepper steps={steps} activeStepId={activeStep.id}>
      <BuiStep>
        <BuiStepHeader titleText={activeStep.name} />
        <BuiStepContent>
          {activeStep.content}
        </BuiStepContent>
        {ActionsComponent}
      </BuiStep>
    </BuiStepper>
  );
};

Stepper.defaultProps = {
  ActionsComponent: null,
  activeStepIndex: 0
};

Stepper.propTypes = {
  ActionsComponent: PropTypes.node,
  activeStepIndex: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.node
  })).isRequired
};

export default React.memo(Stepper);
