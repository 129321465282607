const menu = [
  {
    to: 'https://www.100ladrillos.com/how-it-works',
    label: 'Cómo funciona',
    external: true,
    isTextButton: true
  },
  {
    to: '/signup',
    label: 'Crear cuenta',
    isCallToAction: true
  }
];

export default menu;
