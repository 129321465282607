import styled from 'styled-components';

const ActionButtonWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(8)};
  right: ${({ theme }) => theme.spacing(1)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    top: ${({ theme }) => theme.spacing(10)};
  }
`;

const ButtonAlign = styled.div`
  display: flex;
  align-items: center;

  img {
    padding: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export { ActionButtonWrapper, ButtonAlign };
