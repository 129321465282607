import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { renderSomethingWentWrong } from '@utils';
import { useAuth } from '@lib/authentication';
import { useErrorReporter } from '@lib/errorReporter';
import { useForm } from '@hooks';
import { useInsightsService } from '@lib/insightsService';
import { useProfile } from '@lib/profile';
import {
  AdaptiveArea,
  Button,
  Form,
  Icon,
  LegacyLink,
  PasswordField,
  RenderIf,
  Title
} from '@components';

import state from '../../../state';
import { CurrentSessionModal, FailedAttemptsLock } from '../components';

import {
  AlternativeAction,
  AntiphishingData,
  AntiphishingField,
  AntiphishingImage,
  AntiphishingWarning,
  ResponsiveLineBreak,
  Text,
  VPNErrorMessage
} from './Steps.styles';

const FORGOT_PASSWORD_URL = '/forgot-password';
const UNUSUAL_ACCESS_URL = '/unusual-access-lock';

const ERROR_INCORRECT_PASSWORD = 'La contraseña es invalida.';

const stepTwoSchema = {
  password: {
    required: true
  }
};

const StepTwo = props => {
  const {
    email,
    antiphishingData,
    onCancel,
    onSuccess
  } = props;

  const { fullName, imageUrl, phrase } = antiphishingData;

  const navigate = useNavigate();
  const { profile, isLoading: isLoadingProfile } = useProfile();
  const { authenticationEvent, reportInsight } = useInsightsService();
  const errorReporter = useErrorReporter();
  const form = useForm({ initialValues: { password: '' }, schema: stepTwoSchema });
  const [lockedByFailedAttemptsData, setLockedByFailedAttemptsData] = useState();
  const [showAlreadyActiveSessionModal, setShowAlreadyActiveSessionModal] = useState(false);
  const [showVPNMessage, setShowVPNMessage] = useState(false);

  const {
    login,
    isAuthenticated,
    isLoading: isAuthenticating,
    error: loginError
  } = useAuth();

  const onSubmit = () => {
    setShowVPNMessage(false);
    login(email, form.values.password);
  };

  const handleLoginError = error => {
    if (error.isIncorrectPassword()) {
      form.addError({
        field: 'password',
        message: ERROR_INCORRECT_PASSWORD
      });
    } else if (error.hasFailedAttemptsLock()) {
      // TODO: This should be a different page/route
      setLockedByFailedAttemptsData({
        remainingTimeInMilliseconds: error.details.remainingLockTimeInMilliseconds
      });
    } else if (error.hasActiveSession()) {
      setShowAlreadyActiveSessionModal(true);
    } else if (error.isUnusualAccess()) {
      // TODO: DEPRECATE state object. Those values should be put in the URL
      state.set('unusualAccessData', JSON.stringify({ responseEmail: email, metadata: {} }));
      navigate(UNUSUAL_ACCESS_URL);
    } else if (error.hasActiveVPN()) {
      setShowVPNMessage(true);
    } else {
      errorReporter.critical(error);
      renderSomethingWentWrong();
    }
  };

  useEffect(() => {
    if (isAuthenticated && profile && onSuccess) {
      onSuccess();
    }
  }, [isAuthenticated, profile]);

  useEffect(() => {
    if (loginError) {
      reportInsight(authenticationEvent.logInError, loginError);

      if (loginError.name === 'AuthenticationError') {
        handleLoginError(loginError);
      } else {
        errorReporter.critical(loginError);
        renderSomethingWentWrong();
      }
    }
  }, [loginError]);

  if (lockedByFailedAttemptsData) {
    const timeToUnlock = lockedByFailedAttemptsData.remainingTimeInMilliseconds;

    return (
      <FailedAttemptsLock
        email={email}
        remainingLockTimeInMilliseconds={timeToUnlock}
      />
    );
  }

  return (
    <Fragment>
      <Title>Iniciar sesión</Title>

      <RenderIf condition={!!imageUrl}>
        <AntiphishingImage src={imageUrl} alt="100 ladrillos" />
        <AntiphishingWarning>
          <Icon name="icWarning" alt="warning" />
          <Text>Si no reconoces la imagen, comunícate con nuestro servicio a cliente.</Text>
        </AntiphishingWarning>
      </RenderIf>

      <RenderIf condition={!!phrase}>
        <AntiphishingData>
          <Text>Frase</Text>
          <AntiphishingField>{phrase}</AntiphishingField>
        </AntiphishingData>
      </RenderIf>

      <RenderIf condition={!!fullName}>
        <AntiphishingData>
          <Text>Nombre</Text>
          <AntiphishingField>{fullName}</AntiphishingField>
        </AntiphishingData>
      </RenderIf>

      <RenderIf condition={!fullName}>
        <AntiphishingData>
          <Text>Correo electrónico</Text>
          <AntiphishingField>{email}</AntiphishingField>
        </AntiphishingData>
      </RenderIf>

      <Form className="login-password" onSubmit={form.handleSubmit(onSubmit)}>
        <PasswordField
          id="password"
          focus
          label="¿Cuál es tu contraseña?"
          value={form.values.password}
          {...form.fieldProps('password')}
        />

        <RenderIf condition={showVPNMessage}>
          <VPNErrorMessage>
            Tenemos un inconveniente al tratar de iniciar tu sesión.
            ¿Tienes activa una VPN o un proxy?
            Desactívala y vuelve a intentarlo.
          </VPNErrorMessage>
        </RenderIf>

        <AlternativeAction>
          {'¿Olvidaste tu contraseña? '}
          <ResponsiveLineBreak />
          <LegacyLink to={FORGOT_PASSWORD_URL}>
            Restablece tu contraseña
          </LegacyLink>
          .
        </AlternativeAction>

        <AdaptiveArea>
          <Button
            id="cancel"
            color="secondary"
            onClick={onCancel}
          >
            Anterior
          </Button>
          <Button
            id="login"
            type="submit"
            disabled={!form.isValid()}
            isLoading={isAuthenticating || isLoadingProfile}
          >
            Iniciar sesión
          </Button>
        </AdaptiveArea>
      </Form>

      <CurrentSessionModal
        isOpen={showAlreadyActiveSessionModal}
        onClose={() => setShowAlreadyActiveSessionModal(false)}
      />
    </Fragment>
  );
};

StepTwo.propTypes = {
  email: PropTypes.string.isRequired,
  antiphishingData: PropTypes.shape({
    fullName: PropTypes.string,
    imageUrl: PropTypes.string,
    phrase: PropTypes.string
  }),
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

StepTwo.defaultProps = {
  antiphishingData: {}
};

export default StepTwo;
