import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AdaptiveArea as BricksUIAdaptiveArea } from 'bricks-ui/packages/bricks-ui';

const AdaptiveArea = ({ className, children }) => {
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    const scrollableComponent = document.getElementById('scroll-view');

    const scrollHandler = () => {
      const { clientHeight, scrollHeight, scrollTop } = scrollableComponent;
      const bottomReached = scrollHeight - scrollTop === clientHeight;
      setShowShadow(!bottomReached);
    };

    if (scrollableComponent) {
      scrollHandler();
      scrollableComponent.addEventListener('scroll', scrollHandler);
    }

    return () => {
      if (scrollableComponent) {
        scrollableComponent.removeEventListener('scroll', scrollHandler);
      }
    };
  }, []);

  return (
    <BricksUIAdaptiveArea className={className} shadow={showShadow}>
      {children}
    </BricksUIAdaptiveArea>
  );
};

AdaptiveArea.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

AdaptiveArea.defaultProps = {
  className: ''
};

export default AdaptiveArea;
