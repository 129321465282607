import styled, { css } from 'styled-components';

import { flexAsRow } from '@components/Styled/mixins';

const Badge = styled.div`
  ${flexAsRow()};

  align-items: center;
  width: fit-content;

  ${({ theme, backgroundColor }) => css`
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
    border-radius: ${theme.spacing(0.5)};
    background-color: ${backgroundColor};
  `};
`;

export { Badge };
