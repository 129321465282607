import React from 'react';
import PropTypes from 'prop-types';

import { LegacyLink } from '@components';
import './style.scss';

const TabLink = ({ title, to, ...restProps }) => {
  return (
    <LegacyLink {...restProps} to={to} styleName="tab-link" tid="link">
      {title}
    </LegacyLink>
  );
};

TabLink.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default TabLink;
