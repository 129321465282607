import React, {
  Fragment,
  useState,
  useEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';

import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { Money } from '@types';
import { useRequest, useUpgradingAccount } from '@hooks';
import {
  formatNumbers,
  getCodeFromError,
  renderExceedsAllowedOperationsAmount,
  renderSomethingWentWrong
} from '@utils';
import { useInsightsService } from '@lib/insightsService';
import { Paragraph, Subtitle } from '@components/Styled';

import Icon from '../Icon';
import RFCIdentifierModal from '../RFCIdentifierModal';
import NotEnoughFundsModal from '../NotEnoughFundsModal';

// eslint-disable-next-line import/no-cycle
import {
  ErrorQuoter,
  Quotation,
  ReservedBricksQuoter,
  UnavailableBricksModal
} from './components';
import QuoterOnSalePlaceholder from './placeholders';
import {
  reserveBricks,
  calculateQuotation,
  fetchReservation,
  releaseReservedBricks
} from './api';
import './style.scss';

const INVESTOR_EXCEEDS_OPERATIONS_AMOUNT_ALLOWED = 'INVESTOR_EXCEEDS_OPERATIONS_AMOUNT_ALLOWED';
const QUOTATION_ERRORS = ['BRICK_QUANTITY_EXCEEDS_AVAILABLE'];
const RESERVATION_ERRORS = ['BRICK_QUANTITY_EXCEEDS_AVAILABLE', 'BRICKS_NO_LONGER_AVAILABLE'];
const TRADER_DOES_NOT_HAVE_RESERVED_BRICKS = 'TRADER_DOES_NOT_HAS_RESERVED_BRICKS';
const RFC_WITHOUT_IDENTIFIER_SIZE = 10;

const QuoterOnSale = props => {
  /** The dependency below is causing dependency cycles, for this reason the dependency is loaded
    * via require. Please, always avoid the use of require() calls in any other place under this
    * codebase unless you have a good reason to do so.
    *
    * We need to move the api module to the @lib module in order to remove the dependency cycle
    */
  // eslint-disable-next-line global-require
  const { useProfile } = require('@lib/profile');

  const {
    availableBricks = 0,
    fetchAvailableBricksOnsale,
    isLegacyAndLegalTrader,
    generalAveragePrice = 0,
    history,
    profileCompleted,
    property,
    trader,
    traderRfc = ''
  } = props;

  const { profile, reloadProfileData, investorBalance } = useProfile();
  const { secondaryMarketEvent, reportInsight } = useInsightsService();
  const { renderInvestorPlansModal } = useUpgradingAccount();
  const [bricksQuantity, setBricksQuantity] = useState(0);
  const [isQuoting, setIsQuoting] = useState(false);
  const [quotation, setQuotation] = useState({});
  const [reservation, setReservation] = useState({});
  const [reservationStatus, setReservationStatus] = useState('idle');
  const [releasingBricksStatus, setReleasingBricksStatus] = useState('idle');
  const [errorCode, setErrorCode] = useState('');
  const [isInsufficientFundsModalVisible, setInsufficientFundsModalVisible] = useState(false);
  const [isRfcIdentifierModalVisible, setIsRfcIdentifierModalVisible] = useState(false);

  const { id: propertyId, numberOfMaximumBricksToPurchase = 0 } = property;
  const quotationAveragePrice = quotation.averagePrice || 0;
  const averagePrice = bricksQuantity > 0 ? quotationAveragePrice : generalAveragePrice;
  const formattedGeneralAveragePrice = new Money(averagePrice).toString();
  const hasValuesQuotation = Object.keys(quotation).length > 0;
  const hasReservations = !isEmpty(reservation);

  const handleChangeBricksQuantity = event => {
    const { target: { value } } = event;
    const formattedValue = formatNumbers(value, bricksQuantity);

    setBricksQuantity(formattedValue);
  };

  const isQuotationAcceptButtonDisabled = () => bricksQuantity <= 0;

  const sendStartPurchaseEvent = () => {
    const { bricksIds, pricePerBrick, totalAmount } = quotation;
    const purchaseQuotation = { bricksIds, pricePerBrick, totalAmount };

    reportInsight(secondaryMarketEvent.startPurchase, property, purchaseQuotation);
  };

  const handleNewQuotation = async () => {
    setReleasingBricksStatus('pending');

    try {
      await releaseReservedBricks();
      await fetchAvailableBricksOnsale();

      setReservation({});
      setReleasingBricksStatus('resolved');
    } catch (error) {
      setErrorCode('');
      renderSomethingWentWrong();
      setReleasingBricksStatus('rejected');
    }
  };

  const redirectToBuy = async reservationId => {
    const urlParams = `bricks=${bricksQuantity}&reservationId=`
      + `${reservationId}&propertyId=${property.id}`;

    history.push(`/marketplace/onsale/buy?${urlParams}`);
  };

  const reserveBricksOnSale = async () => {
    const { totalAmount = 0 } = quotation;

    if (Boolean(traderRfc) && traderRfc.length <= RFC_WITHOUT_IDENTIFIER_SIZE) {
      setIsRfcIdentifierModalVisible(true);
      return;
    }

    const available = investorBalance?.available ?? 0;

    if (totalAmount > available) {
      setInsufficientFundsModalVisible(true);
      return;
    }

    setIsQuoting(true);
    const payload = { bricksQuantity: Number(bricksQuantity) };

    try {
      const { data } = await reserveBricks(property.id, payload);

      redirectToBuy(data.id);
    } catch (error) {
      const code = getCodeFromError(error);

      if (RESERVATION_ERRORS.includes(code)) {
        setErrorCode(code);
      } else if (INVESTOR_EXCEEDS_OPERATIONS_AMOUNT_ALLOWED === code) {
        renderExceedsAllowedOperationsAmount();
        reloadProfileData();
      } else {
        renderSomethingWentWrong();
      }
    } finally {
      setIsQuoting(false);
    }
  };

  const handleSendPurchaseToken = async () => {
    sendStartPurchaseEvent();

    if (hasReservations) {
      await handleNewQuotation();
    }

    if (profile.accountLevel.isFrozen() || profile.pendingSignRequests.hasRequests()) {
      renderInvestorPlansModal();
      return;
    }

    reserveBricksOnSale();
  };

  const verifyReservedBricks = async () => {
    setReservationStatus('pending');

    try {
      const { data } = await fetchReservation();

      setReservation(data);
      setReservationStatus('resolved');
    } catch (error) {
      const { response = {} } = error;
      const { data = {} } = response;

      if (data.code === TRADER_DOES_NOT_HAVE_RESERVED_BRICKS) {
        setReservation({});
        setReservationStatus('resolved');
      } else {
        setErrorCode('');
        setReservationStatus('rejected');
      }
    }
  };

  const handleCloseModal = async () => {
    fetchAvailableBricksOnsale();
    setErrorCode('');
  };

  const handleNewQuote = async () => {
    fetchAvailableBricksOnsale();
    setBricksQuantity(0);
    setErrorCode('');
  };

  const getQuotationError = () => {
    if (errorCode) {
      return errorCode;
    }

    if (bricksQuantity > numberOfMaximumBricksToPurchase) {
      return 'BRICK_QUANTITY_EXCEEDS_AVAILABLE';
    }

    return '';
  };

  const closeInsufficientFundsModal = () => setInsufficientFundsModalVisible(false);

  const closeRfcIdentifierModal = () => setIsRfcIdentifierModalVisible(false);

  const setQuotationData = useCallback(debounce(async (propertyBricksQuantity, idProperty) => {
    try {
      const { data } = await calculateQuotation(idProperty, Number(propertyBricksQuantity));

      setQuotation(data);
    } catch (error) {
      const { response = {} } = error;
      const { data = {} } = response;

      if (QUOTATION_ERRORS.includes(data.code)) {
        setErrorCode(data.code);
      } else {
        setErrorCode('');
        renderSomethingWentWrong();
      }
    }
  }, 300), []);

  const isBricksUnavailableModalVisible = () => RESERVATION_ERRORS.includes(errorCode);

  useEffect(() => {
    const isValidQuantity = bricksQuantity > 0
      && bricksQuantity <= numberOfMaximumBricksToPurchase;
    if (isValidQuantity) {
      setQuotationData(bricksQuantity, propertyId);
    }

    if (!isValidQuantity && hasValuesQuotation) {
      setQuotation({});
    }
  }, [
    bricksQuantity,
    propertyId,
    hasValuesQuotation,
    setQuotationData,
    numberOfMaximumBricksToPurchase
  ]);

  useEffect(() => {
    verifyReservedBricks();
  }, []);

  const hasReservedBricks = () => {
    if (!hasReservations) {
      return false;
    }

    const reservations = reservation.bricksOnSale;

    return reservations.some(each => {
      const { brick } = each;

      return Boolean(propertyId === brick?.property?.id);
    });
  };

  const hasQuoterError = reservationStatus === 'rejected'
    || releasingBricksStatus === 'rejected';
  const isPlaceholderVisible = reservationStatus === 'pending';
  const isReservedBricksQuoterVisible = hasReservedBricks()
    && reservationStatus !== 'pending'
    && !hasQuoterError;
  const isRegularQuoterVisible = !isReservedBricksQuoterVisible
    && reservationStatus !== 'pending'
    && !hasQuoterError;

  return (
    <Fragment>
      <div id="secondaryMarket-quoter" styleName="quoter-on-sale">
        <UnavailableBricksModal
          handleClose={handleCloseModal}
          handleConfirm={handleNewQuote}
          isOpen={isBricksUnavailableModalVisible()}
        />

        <Subtitle
          tid="quoter-title"
          backgroundColor="primary-main"
          align="center"
          color="white"
          level="2"
        >
          Cotiza
        </Subtitle>

        {hasQuoterError && (
          <ErrorQuoter
            buttonLabel="Actualizar sección"
            errorQuoterAction={verifyReservedBricks}
          />
        )}

        {isPlaceholderVisible && <QuoterOnSalePlaceholder />}

        {isReservedBricksQuoterVisible && (
          <ReservedBricksQuoter
            reservation={reservation}
            history={history}
            handleNewQuotation={handleNewQuotation}
            isReleasingBricks={releasingBricksStatus === 'pending'}
            propertyId={propertyId}
          />
        )}

        {isRegularQuoterVisible && (
          <div tid="on-sale-quoter-content">
            <div tid="available-bricks" styleName="available-bricks">
              <div>
                <Icon name="icBricksSummary" alt="Ladrillos disponibles" />
                <Paragraph>Ladrillos disponibles</Paragraph>
              </div>

              <Subtitle
                tid="available-bricks-number"
                align="center"
                level="2"
              >
                {availableBricks}
              </Subtitle>
            </div>

            <div tid="general-average-price" styleName="general-average-price">
              <Paragraph>Precio promedio:</Paragraph>

              <Subtitle
                tid="general-average-price-number"
                color="info-main"
                level="2"
              >
                {formattedGeneralAveragePrice}
              </Subtitle>

              <Paragraph styleName="caption">Por ladrillo</Paragraph>
            </div>

            <Quotation
              bricksQuantity={bricksQuantity}
              handleChangeBricksQuantity={handleChangeBricksQuantity}
              isAcceptButtonDisabled={isQuotationAcceptButtonDisabled()}
              quotation={quotation}
              isLoadingAcceptButton={isQuoting}
              availableBricks={availableBricks}
              handleClickAcceptButton={handleSendPurchaseToken}
              maximumBricksToPurchase={numberOfMaximumBricksToPurchase}
              quotationErrorCode={getQuotationError()}
              isLegacyAndLegalTrader={isLegacyAndLegalTrader}
              profileCompleted={profileCompleted}
              trader={trader}
            />
          </div>
        )}
      </div>

      <NotEnoughFundsModal
        availableAmount={investorBalance?.available}
        handleClose={closeInsufficientFundsModal}
        isOpen={isInsufficientFundsModalVisible}
      />

      <RFCIdentifierModal
        handleClose={closeRfcIdentifierModal}
        isOpen={isRfcIdentifierModalVisible}
      />
    </Fragment>
  );
};

QuoterOnSale.propTypes = {
  availableBricks: PropTypes.number,
  fetchAvailableBricksOnsale: PropTypes.func.isRequired,
  generalAveragePrice: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  property: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    numberOfMaximumBricksToPurchase: PropTypes.number
  }).isRequired,
  trader: PropTypes.shape({
    canBuy: PropTypes.bool
  }).isRequired,
  isLegacyAndLegalTrader: PropTypes.bool.isRequired,
  profileCompleted: PropTypes.bool.isRequired,
  traderRfc: PropTypes.string
};

QuoterOnSale.defaultProps = {
  availableBricks: 0,
  generalAveragePrice: 0,
  traderRfc: ''
};

export default QuoterOnSale;
